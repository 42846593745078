import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Grid,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import MROButton from "components/buttons";
import { makeStyles } from "@material-ui/core/styles";
import exclamationIcon from "assets/exclamation.png"
import WarningIcon from "@material-ui/icons/Warning";
import LoadingIndicator from "components/universal/loading_indicator";
import { WorkshopContext } from "../WorkshopContext/WorkshopContext";
import { useCallback } from "react";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        margin: "1% 0",
        display: "flex",
        "& button": {
            marginRight: theme.spacing(2),
            width: "200px",
            "& .MuiButton-label": {
                justifyContent: "space-between",
                width: "150px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
            },
        },
    },
    input: {
        color: "#fff",
    },
    paper: {
        borderRadius: "5px",
        backgroundColor: "#fff",
        padding: theme.spacing(3),
        boxShadow: "0px 1px 4px #0000001A",
    },
    head: {
        marginTop: "20px",
        marginBottom: "10px",
    },
    error: {
        borderColor: "red",
    },
    svg: {
        filter: "invert(1)",
        height: 18,
        marginBottom: 2,
    },
    disableSwitch: {
        pointerEvents: "none",
    },
    disableField: {
        pointerEvents: "none",
        backgroundColor: "#F5F5F5",
    },
    message: {
        display: "flex",
        justifyContent: "flex-start",
        margin: theme.spacing(1)
    },
    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "563px",
        },
    },
}));

function CreateApprovaCaution(props) {
    const classes = useStyles();
    const { open, handleClose, popupValues, setAcceptAll, acceptAll, handleSubmit, onClose } = props


    const handlePopUp = (e) => {
        handleClose()
        handleSubmit(e)
    }
    return (
        <div>
            <Dialog
                maxWidth={"md"}
                className={classes.modal}
                aria-labelledby="commercial"
                onClose={onClose}
                open={open}
            >
                <DialogTitle id="customized-dialog-title">
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <Box display="flex" alignItems="center">
                            <Typography
                                style={{
                                    fontSize: "18px",
                                }}
                                color="primary"
                            >
                                Caution
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center">
                            {" "}
                            <img
                                src={exclamationIcon}
                                alt="Icon"
                            // style={{ height: "28px" }}
                            />{" "}
                        </Box>
                    </Box>
                </DialogTitle>



                <DialogContent style={{ width: '563px' }}>


                    {/* 1content */}
                    <div className={classes.message}>
                        <WarningIcon
                            style={{
                                color: "#FFB300",
                                marginRight: "10px",
                                fontSize: 20,
                            }}
                        />&nbsp;&nbsp;You are about to submit the following figures directly to the customer:
                    </div>

                    <Grid container xs={12} style={{

                    }}>
                        <Grid xs={12} container style={{ marginLeft: "11px", marginBottom: "7px" }}>
                            <Grid xs={3}>
                                <Typography variant="subtitle2">TAT</Typography>
                            </Grid>
                            <Grid >

                                <Typography >{popupValues?.tat_days}</Typography>
                            </Grid>
                        </Grid>
                        <Grid xs={12} container style={{ marginLeft: "11px", marginBottom: "7px" }}>
                            <Grid xs={3}>
                                <Typography variant="subtitle2">Labour</Typography>
                            </Grid>
                            <Grid xs={6}>
                                <Typography >{popupValues?.labour_value}</Typography>

                            </Grid>

                        </Grid>
                        <Grid xs={12} container style={{ marginLeft: "11px", marginBottom: "7px" }}>
                            <Grid xs={3}>
                                <Typography variant="subtitle2">Parts</Typography>
                            </Grid>
                            <Grid xs={6}>
                                <Typography >{popupValues?.parts_value}</Typography>
                            </Grid>

                        </Grid>
                        <Grid xs={12} container style={{ marginLeft: "11px", marginBottom: "7px" }}>
                            <Grid xs={3}>
                                <Typography variant="subtitle2">Services</Typography>
                            </Grid>
                            <Grid xs={6}>
                                <Typography >{popupValues?.service_value}</Typography>
                            </Grid>
                        </Grid>
                        <Grid xs={12} container style={{ marginLeft: "11px", marginBottom: "7px" }}>
                            <Grid xs={3}>
                                <Typography variant="subtitle2">Equipment</Typography>
                            </Grid>
                            <Grid xs={6}>
                                <Typography >{popupValues?.equipment_value}</Typography>
                            </Grid>
                        </Grid>
                        <Grid xs={12} container style={{ marginLeft: "11px", marginBottom: "7px" }}>


                            <Grid xs={3}>
                                <Typography variant="subtitle2">AOG Fee</Typography>
                            </Grid>
                            <Grid xs={6}>
                                <Typography >{popupValues?.aog_fee}</Typography>
                            </Grid>
                        </Grid>
                        <Grid xs={12} container style={{ marginLeft: "11px", marginBottom: "7px" }}>
                            <Grid xs={3}>
                                <Typography variant="subtitle2">Total</Typography>
                            </Grid>
                            <Grid xs={6}>
                                <Typography style={{ textDecoration: 'underline' }}>{popupValues?.total ? parseFloat(popupValues?.total).toFixed(2) : ''}</Typography>
                            </Grid>

                        </Grid>

                    </Grid>




                    <Box pt={3} display="flex" alignItems="center" style={{
                        marginLeft: "11px",
                    }}>
                        <span style={{ textDecoration: "underline" }}>Accept all</span>
                        &nbsp;&nbsp;&nbsp;
                        <input
                            style={{ pointerEvents: popupValues === undefined ? "none" : "" }}
                            type="checkbox"
                            value={acceptAll}
                            onClick={() => {
                                setAcceptAll(!acceptAll);
                            }}
                        />
                    </Box>


                    <Box
                        mt="auto"
                        display="flex"
                        justifyContent="center"
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                        <MROButton
                            style={{ marginRight: "20px" }}
                            type="button"
                            variant={"contained"}
                            onClick={onClose}
                            buttonName="Cancel"
                        >
                            Cancel
                        </MROButton>
                        <MROButton
                            type="button"
                            variant={"contained"}
                            color={"primary"}
                            style={{ backgroundColor: !acceptAll ? "#FFB300" : "", color: acceptAll ? "#ffff" : "#000000" }}
                            onClick={(e) => {
                                acceptAll && handlePopUp(e);

                            }}
                            name="complete1"
                        >
                            Complete
                        </MROButton>
                    </Box>

                </DialogContent>



            </Dialog>


        </div>
    )
}

export default CreateApprovaCaution