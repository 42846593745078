import React, { useContext, useState, useEffect } from "react";
import { makeStyles, Typography, Grid, MenuItem, Box } from "@material-ui/core";
import MROTextField from "components/form_components/TextField";
import QuantityCounter from "components/quantity_counter";
import WarningIcon from "@material-ui/icons/Warning";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import MROButton from "components/buttons";
import { OperationService } from "lib/services/api/operaitons/save_operation";
import { ToastMessageContext } from "lib/contexts/message_context";
import PrimePartsClientServices from "lib/services/api/stores/tooling/parts_client";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-multiline": {
      minHeight: "89px",
    },

    "& textarea::placeholder": {
      // textTransform: "none !important",
    },
  },

  title: {
    fontSize: "20px",
  },
}));

function CreateNew(props) {
  const { getList, onClose, workOrderId, baseStationId, actionDpdOptions, currentSelectedOrder } = props;

  const classes = useStyles();

  const message = useContext(ToastMessageContext);

  const [unitQty, setUnitQty] = useState(1);
  const [requiredQty, setRequiredQty] = useState();
  const [value, setValue] = useState("no");
  const [errors, setErrors] = useState({});
  const [busy, setBusy] = useState(null);
  const [unitOfMeasureItems, setUnitOfMeasureItems] = useState([]);

  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    PrimePartsClientServices.fetchDependencies()
      .then((res) => {
        if (res) {
          setUnitOfMeasureItems(res.units_of_measure);
        }
      })
      .catch((err) => {
        console.log("something weing wrong!", err);
      });
  }, []);

  const unkonwnPartFields = [
    {
      label: "P/N *",
      placeholder: "Part Number",
      type: "text",
      required: true,
      name: "part_number",
    },
    {
      label: "Description *",
      placeholder: "Max 30 characters",
      type: "text",
      required: true,
      name: "description",
    },
    {
      label: "Units of Measure *",
      placeholder: "",
      defaultValue: 1,
      type: "dropdown",
      values: unitOfMeasureItems?.map((item) => ({
        id: item?.units_of_measure_id,
        value: item?.unit_name,
      })),
      required: true,
      name: "units_of_measure_id",
    },
    {
      label: "Units Issued Each QTY *",
      placeholder: "QTY",
      type: "qty",
      required: true,
      name: "units_issued",
      qty: unitQty,
      setQty: setUnitQty,
      shouldNotAcceptZero: true,
    },
    {
      label: "Maint Data Ref *",
      placeholder: "AMM, CMM, IPC etc reference",
      type: "text",
      required: true,

      name: "maint_data_ref",
    },
  ];

  const addPartFields = [
    {
      label: "Select Action *",
      type: "text",
      required: true,
      name: "workshop_action_id",
      type: "dropdown",
      values: actionDpdOptions?.map((item) => ({
        id: item?.workshop_action_id,
        value: item?.action_number,
      })),
    },
    {
      label: "Comments *",
      placeholder: "Optional alternate part comments for nil stock items – Max 150 characters",
      type: "text",
      required: true,
      name: "issued_comment",
      multiline: "multiline"
    },
    {
      label: "Required QTY *",
      placeholder: "QTY",
      type: "qty",
      required: true,
      name: "required_quantity",
      qty: requiredQty,
      setQty: setRequiredQty,
      shouldNotAcceptZero: true,
    },
  ];

  // fields validation
  const validateFields = (formData) => {
    let error = {};
    let valid = true;

    const requiredQty = formData.get("required_quantity");
    const units_issued_qty = formData.get("units_issued");

    for (let pair of formData.entries()) {
      const key = pair[0];
      console.log(pair);
      if (pair[1] === "") {
        error[key] = true;
        valid = false;
      }
    }

    if (+requiredQty === 0 && requiredQty !== "") {
      error["required_quantity_is_zero"] = true;
      error["required_quantity"] = true;
      valid = false;
    }

    if (+units_issued_qty === 0 && units_issued_qty !== "") {
      error["units_issued_qty_is_zero"] = true;
      error["units_issued"] = true;
      valid = false;
    }

    if (!valid) {
      setErrors(error);
    }
    console.log({ error });
    return valid;
  };

  const createJobReq = async (formData, id) => {
    const part_off = formData.get("part_off");
    const workshop_action_id = formData.get("workshop_action_id");
    const serial_off = formData.get("serial_off");
    const defferal_data = formData.get("add_deferral");
    const issued_comment = formData.get("issued_comment");
    const install_location = formData.get("location");
    const quantity = formData.get("required_quantity");

    const params = {
      primary_part_id: id,
      reportable_type: "WorkshopOrder",
      reportable_id: workOrderId,
      quantity,
      from_base_station_id: baseStationId,
      to_base_station_id: baseStationId,
      install_location,
      rotable_removed: value === "yes" ? true : false,
      part_off,
      serial_off,
      issued_comment,
      line_customer_id: currentSelectedOrder?.line_customer_id,
      // aircraft_registration: "",
      user_id: user.id,
      defferal_data,
      order_number: currentSelectedOrder?.order_no,
      workshop_action_id
    };

    try {
      await OperationService.createJobRequest(params);
      onClose();
      getList()
    } catch (error) {
      message.showToastMessage({
        message: "Something went wrong!",
        variant: "error",
      });
    }
  };

  // form submit handler
  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const part_number = formData.get("part_number").toUpperCase();

    formData.set("part_number", part_number);
    formData.append("user_id", user.id);

    if (!validateFields(formData)) {
      return;
    }

    try {
      setBusy("loading");
      const response = await OperationService.addPart(formData);

      setBusy(null);
      if (response.success) {
        message.showToastMessage({
          message: "Added successfully.",
          variant: "success",
        });
        getList()
        onClose()
        createJobReq(formData, response.id);
      } else {
        const msg =
          response.errors?.length > 0
            ? response.errors.join(", ")
            : "Something went wrong!";
        message.showToastMessage({
          message: msg,
          variant: "error",
        });
      }
    } catch (error) {
      message.showToastMessage({
        message: "Something went wrong!",
        variant: "error",
      });
      setBusy("error");
    }
  };

  const fields = ({
    placeholder,
    label,
    name,
    multiline,
    type,
    qty,
    setQty,
    values,
    required,
    defaultValue,
    shouldNotAcceptZero,
    select
  }) => {
    switch (type) {
      case "text":
        return (
          <MROTextField
            placeholder={placeholder}
            label={label}
            select={select}
            variant="outlined"
            color="primary"
            InputLabelProps={{ shrink: true }}
            style={{ marginRight: "50px" }}
            inputProps={{
              maxLength:
                name === "part_number" ||
                  name === "description" ||
                  name === "maint_data_ref"
                  ? 30
                  : undefined,
              style: { textTransform: name === "part_number" && "uppercase" },
            }}
            name={name}
            multiline={multiline}
            autoFocus={errors[name]}
            error={errors[name]}
            helperText={
              errors[name] &&
              required &&
              `Please select ${label.replace("*", "")}`
            }
            onChange={() =>
              setErrors((prevState) => ({ ...prevState, [name]: false }))
            }
          />
        );

      case "qty":
        const fieldName = label.replace("*", "");
        const errMsg =
          (errors?.required_quantity_is_zero || errors?.units_issued_qty_is_zero) && (name === "required_quantity" || name === "units_issued")
            ? `${fieldName} must be greater than 0`
            : `Please Enter ${fieldName}`;
        return (
          <QuantityCounter
            name={name}
            label={label}
            placeholder={placeholder}
            setQty={setQty}
            qty={qty}
            autoFocus={errors[name]}
            error={errors[name]}
            shouldNotAcceptZero={shouldNotAcceptZero}
            helperText={errors[name] && required && errMsg}
            setError={() =>
              setErrors((prevState) => ({ ...prevState, [name]: false }))
            }
            minQty={1}
          />
        );

      case "dropdown":
        return (
          <MROTextField
            variant="outlined"
            color="primary"
            label={label}
            InputLabelProps={{ shrink: true }}
            style={{ marginRight: "50px" }}
            name={name}
            select
            defaultValue={defaultValue ?? placeholder}
            // value={'each'}
            displayEmpty
            autoFocus={errors[name]}
            error={errors[name]}
            helperText={
              errors[name] &&
              required &&
              `Please select ${label.replace("*", "")}`
            }
            onChange={() =>
              setErrors((prevState) => ({ ...prevState, [name]: false }))
            }
          >
            {placeholder !== "" && (
              <MenuItem value={placeholder}>{placeholder}</MenuItem>
            )}
            {values?.map((item, i) => (
              <MenuItem key={item.id} value={item.id}>
                {item.value}
              </MenuItem>
            ))}
          </MROTextField>
        );
      default:
        break;
    }
  };

  const handleToggle = (value) => {
    if (value) {
      setValue(value);
    }
  };

  return (
    <div className={classes.root}>
      <form noValidate onSubmit={submitHandler}>
        <Typography color="primary" className={classes.title}>
          Unknown Part
        </Typography>
        <Grid container>
          <Grid item container xs={10}>
            {unkonwnPartFields.map((field, i) => (
              <Grid item key={i} xs={6} style={{ paddingRight: "40px" }}>
                {fields(field)}
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Typography
          color="primary"
          className={classes.title}
          style={{ marginTop: "20px", marginBottom: "13px" }}
        >
          Add Part
        </Typography>

        <Grid container>
          <Grid item container xs={5}>
            {addPartFields.map((field, i) => (
              <Grid item key={i} xs={12} style={{ paddingRight: "40px" }}>
                {fields(field)}
                {/* {field.type === "qty" && (
                  <Box display="flex" alignItems="center">
                    <WarningIcon style={{ color: "#FFB300" }} fontSize="20px" />
                    <p style={{ color: "#4D4F5C", marginLeft: "15px" }}>
                      Required QTY exceeds Base Stock QTY! - Please enter a
                      deferred defect number for tracking
                    </p>
                  </Box>
                )} */}
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item container xs={12} justify="flex-end">
          <MROButton
            style={{ marginRight: "20px" }}
            type="button"
            variant={"contained"}
            onClick={onClose}
          >
            Cancel
          </MROButton>
          <MROButton
            type="submit"
            variant={"contained"}
            // style={{ backgroundColor: "#FFB300" }}
            color="primary"
            loading={busy === "loading"}
          >
            Add
          </MROButton>
        </Grid>
      </form>
    </div>
  );
}

export default CreateNew;
