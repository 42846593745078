
import { AppBar, Toolbar, makeStyles, IconButton, Typography, Grid, MenuItem, FormControlLabel, ButtonGroup, InputAdornment, FormControl, Box, Select, InputBase, Menu, Button, Tooltip } from '@material-ui/core'
import MROFullScreenRightDrawer from 'components/fullwidth_dialog'
import React, { useReducer, useState, useEffect, useContext } from 'react'
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import MROButton from 'components/buttons';
import CalendarWeekly from './CalendarWeekly';
import ShiftServices from 'lib/services/api/planning/Shifts/shift';
import { ToastMessageContext } from 'lib/contexts/message_context';
import { getOrganisationId } from 'lib/utils/common_utils';
import { MoreVert } from '@material-ui/icons';
import { ICON_COLOUR } from 'lib/constants/style_constants';
import ApproveOverTime from './ApproveOverTime';
import moment from 'moment';
import PositiveSwitch from 'components/form_components/switch';
import MarkAbsent from '../ShiftPlane/MarkAbsent';
import EditAreas from 'views/Planning/Resource/User Shifts/EditAreas';
import EditZones from 'views/Planning/Resource/User Shifts/EditZones';
import GreenTickIcon from 'assets/green_filled_tick.png'
import RedCrossIcon from 'assets/Red remove icon.png'
import ReplayIcon from '@material-ui/icons/Replay';
import RejectOverTime from './RejectOverTime';
import UsedHours from '../Leave/UsedHours';

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#fff",
        color: "#000000CC",
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    wrapper: {
        margin: "3%",
        // overflowX: "hidden !important"
    },
    title: {
        fontSize: '18px'
    },
    toggle: {
        height: '31px',
        '&.MuiToggleButton-sizeSmall': {
            fontSize: "13px",
            padding: '11px'
        },
        '&.MuiToggleButtonGroup-root': {

            flexWrap: 'wrap',

        }

    },
    actionButtons: {
        marginTop: '15px',
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
    },
    disableField: {
        pointerEvents: "none",
        backgroundColor: "#F5F5F5",
    },
    paper: {
        height: 'max-content',
        '&.MuiAutocomplete-popper': {
            zIndex: '1000000 !important'
        }
    },
    partValue: {
        fontSize: "14px",
        color: "#4D4F5C",
        padding: "2px",
        display: "flex",
        alignItems: "center",
    },
    partKey: {
        "& p": {
            fontSize: "14px",
            padding: "1px",
        },
    },

}));
const GetInfoCard = (props) => {
    const classes = useStyles();
    return (
        <Box display="flex" pl={'5px'}>
            <Box flex={50} className={classes.partKey}>
                <Typography style={{ padding: "5px" }} color="primary">
                    {props?.keyName}
                </Typography>
            </Box>
            <Box flex={50} className={classes.partValue}>
                {props?.value ?? "-"}
            </Box>
        </Box>
    );
};
function OvertimeReview(props) {
    const classes = useStyles()
    const { handleClose, currentSelections, selectedWeek, setSelectedWeek, bases, shiftType, areaData, zoneData, getShiftOvertimeDetails } = props
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [action, setAction] = useState({})
    const [currentSelection, setCurrentSelection] = useState({})
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [filterData, setFilterData] = useState({})
    const message = useContext(ToastMessageContext)
    const ITEM_HEIGHT = 48;
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [date, setDate] = useState({ week: null, year: null })
    const [weekData, setWeekData] = useState([])
    const [selectedDay, setSelectedDay] = useState({ date: "" })
    const [checked, setChecked] = useState(false)
    const [tableLoading, setTableLoading] = useState(false)
    const [shiftData, setShiftData] = useState([])
    const [anchorElList, setAnchorElList] = useState(null);
    const [openList, setOpenList] = useState(false);
    const [headerDetails, setHeaderDetails] = useState({})
    const [hover, setHover] = useState(false)
    useEffect(() => {
        if (selectedWeek !== undefined || null) {
            setDate({
                week: moment(selectedWeek, "YYYY-MM-DD").week(),
                year: moment(selectedWeek, "YYYY-MM-DD").year()
            })
        }
    }, [selectedWeek])


    const handleFilterChange = (keyItem, value) => {

        setFilterData((prevState) => ({ ...prevState, [keyItem]: value }));
    };

    const getWeekViewDetails = async () => {

        const params = {
            week: date.week,
            year: date.year,
            id: currentSelections?.id,
            option: "overtime",
            organisation_id: getOrganisationId(),
            ...filterData
        }
        setLoading(true)
        await ShiftServices.getShiftWeeklyDetails(params).then((res) => {
            setWeekData(res.data.listing)
            setHeaderDetails(res.data.header_details)
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })

    }

    useEffect(() => {
        if (date.week !== null) {
            getWeekViewDetails()
        }
    }, [date, filterData, currentSelections])

    const getListing = () => {

        const params = {
            organisation_id: getOrganisationId(),
            overtime_request_id: currentSelections?.id,
            ...filterData


        }

        setLoading(true)
        ShiftServices.getOvertimeReviewPage(params).then((res) => {
            setData(res.data.overtime_request)

        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })

    }




    useEffect(() => {

        getListing();

    }, [filterData]);



    const handleClick = (event) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setOpen(false);
        setAction(null);
        setCurrentSelection({});
    };

    const handleClickListingTable = (event) => {
        setOpenList(true);
        setAnchorElList(event.currentTarget);
    };
    const handleCloseMenuList = () => {
        setOpenList(false);
        setAction(null);
        setCurrentSelection({});
    }


    const getDayListingDetails = async () => {

        const params = {
            selected_date: selectedDay.date,
            show_absence_leave_training: checked,
            page: page + 1,
            limit: pageSize,
            organisation_id: getOrganisationId(),
            ...filterData
        }
        setTableLoading(true)
        await ShiftServices.getShiftDayListingDetails(params).then((res) => {
            setShiftData(res.data)
            setTotalCount(res.total_count)
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setTableLoading(false)
        })

    }
    useEffect(() => {
        if (selectedDay.date !== '') {
            getDayListingDetails()
        }
    }, [selectedDay, filterData, page, pageSize, checked])



    const handleOvertimeReOpen = async () => {


        const formData = {
            overtime_request_id: currentSelections?.id,
            action: "reopen"
        }
        await ShiftServices.postOverTimeActionRequest(formData).then((res) => {

            message.showToastMessage({
                message: "Reopened Successfully",
                variant: "success",
            });

            handleClose()
            getShiftOvertimeDetails()


        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
            handleClose()
            getShiftOvertimeDetails()
        })






    }







    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
    };
    const columns = [


        {
            title: "Type",
            field: "shift_type",
            render: (rowData) => <>
                <Button variant={rowData?.shift_type_id === 1 ? "outlined" : "contained"} size="small" style={{
                    pointerEvents: "none",
                    borderRadius: '5px', backgroundColor: rowData?.shift_type_id
                        === 2 ? "#4FC605" : rowData?.shift_type_id === 3 ? "#767676" : rowData?.shift_type_id === 4 ? "#FFB300" : rowData?.shift_type_id === 5 ? "#FF0202" : '#FFFFFF', color: rowData?.shift_type_id === 1 ? "#767676" : "#fff", fontWeight: 700, border: rowData?.shift_type_id === 1 ? "2px solid #767676" : ''
                }} >
                    {rowData?.shift_type_id === 1 ? "OFF DAY" : rowData?.shift_type.toString().toUpperCase()}
                </Button>


            </>

        },

        {
            title: "Base",
            field: "base_station_id",
        },

        {
            title: "User",
            field: "user",
            headerStyle: {
                textAlign: 'left',

            },
            cellStyle: {

            }
        },
        {
            title: "Area",
            field: "area",
        },
        {
            title: "Zone",
            field: "zone",
        },
        {
            title: "Shift Hrs",
            field: "shift_hours",
        }, {
            title: "Start",
            field: "start",
        },
        {
            title: "Finish",
            field: "end",
        },
        {
            title: "User Hrs",
            field: "user_hours",
            render: (rowData) => <a style={{ fontWeight: 600, fontSize: '14px', color: rowData?.status === "On Shift" && (parseFloat(rowData?.shift_hours) > parseFloat(rowData?.user_hours)) ? "#FFB300" : rowData?.status === 'Training' || rowData?.status === 'Leave' ? "#FF0202" : rowData?.status === "On Shift" && (parseFloat(rowData?.shift_hours) === parseFloat(rowData?.user_hours)) ? "#4FC605" : rowData?.status === "Overtime" ? "#351BA2" : '#FF0202', textDecoration: 'underline' }} onClick={() => {
                setCurrentSelection(rowData);
                setAction("view_usedhrs")
            }} > {rowData?.user_hours ?? '-'}

            </a >
        },
        {
            title: "Status",
            field: "status",
            render: (rowData) => <Typography style={{
                fontWeight: 600, fontSize: '14px', color: rowData?.status === "On Shift" && (parseFloat(rowData?.shift_hours) > parseFloat(rowData?.user_hours)) ? "#FFB300" : rowData?.status === 'Training' || rowData?.status === 'Leave' ? "#FF0202" : rowData?.status === "On Shift" && (parseFloat(rowData?.shift_hours) === parseFloat(rowData?.user_hours)) ? "#4FC605" : rowData?.status === "Overtime" ? "#351BA2" : '#FF0202'
            }} > {rowData?.status ?? '-'}

            </Typography >

        },
        {
            title: "Action",
            field: "action",
            render: (rowData) => (
                <div id={`div-${rowData.id}`}>
                    <IconButton
                        aria-label="more"
                        aria-controls={`long-menu-${rowData.id}`}
                        aria-haspopup="true"
                        onClick={(e) => {
                            setCurrentSelection(rowData);
                            handleClickListingTable(e);
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                </div>
            ),

        },

    ];


    return (
        <div>
            <MROFullScreenRightDrawer open={true}>

                <AppBar position="static" className={classes.appBar} elevation={0}>
                    <Toolbar>
                        <IconButton
                            onClick={() => {
                                handleClose()
                                getShiftOvertimeDetails()
                            }}
                            edge="start"
                            className={classes.backButton}
                            color="inherit"
                            aria-label="back"
                        >
                            <BackIcon />
                        </IconButton>
                        <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
                            Overtime Review
                        </Typography>
                    </Toolbar>
                </AppBar>


                <div className={classes.wrapper}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant='subtitle1' color='primary' className={classes.title}>Overtime Request</Typography>
                        </Grid>
                        <Grid style={{ paddingRight: '1px' }} >
                            <FormControl size="medium" className={"filter"}>
                                <Select
                                    fullWidth
                                    displayEmpty
                                    onChange={(e) => handleFilterChange("base_station_id", e.target.value)}
                                    id="tags-standard"

                                    name="Base Station"
                                    defaultValue={""}
                                    input={<InputBase />}
                                >
                                    <MenuItem value={""}>Base (All)</MenuItem>
                                    {bases && bases.map((value) => (
                                        <MenuItem value={value.id}>{value.station}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid style={{ paddingRight: '1px' }}> <FormControl size="medium" className={"filter"}>
                            <Select
                                fullWidth
                                displayEmpty
                                onChange={(e) => handleFilterChange("shift_type_id", e.target.value)}
                                id="type-standard"
                                name="Type"
                                defaultValue={""}
                                input={<InputBase />}
                            >
                                <MenuItem value={""}>Type (All)</MenuItem>
                                {shiftType && shiftType.map((value) => (
                                    <MenuItem value={value.id}>{value.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </Grid>
                        <Grid style={{ paddingRight: '1px' }}>
                            <FormControl size="medium" className={"filter"}>
                                <Select
                                    fullWidth
                                    displayEmpty
                                    onChange={(e) => handleFilterChange("shift_area_id", e.target.value)}
                                    id="area-standard"
                                    name="Area"
                                    defaultValue={""}
                                    input={<InputBase />}
                                >
                                    <MenuItem value={""} >Area (All)</MenuItem>
                                    {areaData && areaData.map((value) => (
                                        <MenuItem value={value.id}>{value.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid  >

                            <FormControl size="medium" className={"filter"} >
                                <Select
                                    fullWidth
                                    displayEmpty
                                    onChange={(e) => handleFilterChange("shift_zone_id", e.target.value)}
                                    id="tags-standard"

                                    name="zone"
                                    defaultValue={""}
                                    input={<InputBase />}
                                >
                                    <MenuItem value={""} >Zone (All)</MenuItem>
                                    {zoneData && zoneData.map((value) => (
                                        <MenuItem value={value.id}>{value.name}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>


                        </Grid>

                    </Grid>

                    <br />
                    <div style={{ width: "100%", marginBottom: "20px" }}>
                        <Box
                            display="flex"
                            alignItems="center"
                            // justifyContent={(loading || _.isEmpty(currentSelection)) && "center"}
                            bgcolor="#F5F5F5"
                            className={classes.partCard}
                        >
                            <>
                                <Box flexGrow={1.5} alignSelf="flex-start">
                                    <div style={{ width: "100%" }}>
                                        <GetInfoCard keyName={"User"} value={data?.user} />
                                        <GetInfoCard keyName={"Requested"} value={data?.requested
                                        } />
                                        <GetInfoCard keyName={"Comment"} value={data?.comment ? data?.comment
                                            : ''
                                        } />

                                        <GetInfoCard keyName={" "} value={" "} />

                                    </div>
                                </Box>
                                <Box flexGrow={1.5}>
                                    <div style={{ width: "100%" }}>
                                        <GetInfoCard keyName={"Start Date / Time"} value={data?.start_datetime} />
                                        <GetInfoCard keyName={"End Date / Time"} value={data?.end_datetime
                                        } />
                                        <GetInfoCard keyName={"Type"} value={data?.type} />
                                        <GetInfoCard keyName={"Hours"} value={data?.hours} />

                                    </div>
                                </Box>
                                <Box flexGrow={1.5} alignSelf="flex-start">
                                    <div style={{ width: "100%" }}>
                                        <GetInfoCard keyName={"Status"} value={<Typography style={{ color: currentSelections?.status === 0 ? "#FFB300" : currentSelections?.status === 1 ? "#4FC605" : currentSelections?.status === 2 ? "#FF0202" : currentSelections?.status === 3 ? "#FFB300" : "", fontWeight: 500 }}>{data?.status}</Typography>} />
                                        <GetInfoCard keyName={"Approver"} value={data?.approver} />
                                        <GetInfoCard keyName={"Approval Date"} value={data?.approval_date} />
                                        <GetInfoCard keyName={""} value={" "} />

                                    </div>
                                </Box>
                                <Box flexGrow={0.2} alignSelf="flex-start">
                                    <div style={{ width: "100%" }}>
                                        <GetInfoCard
                                            keyName={""}
                                            value={''}
                                        />
                                        <GetInfoCard
                                            keyName={""}
                                            value={''}
                                        />
                                        <IconButton >
                                            <MoreVert htmlColor={"#000000CC"} style={{ fontSize: '32px' }} onClick={(e) => {
                                                setCurrentSelection(data)
                                                handleClick(e)
                                            }} />
                                        </IconButton>

                                    </div>
                                </Box>
                            </>
                        </Box>
                    </div>
                </div>
                <Grid>
                    <CalendarWeekly data={weekData} week={selectedWeek} setSelectedWeek={setSelectedWeek} setSelectedDay={setSelectedDay} approvedData={data} headerDetails={headerDetails} date={date} />
                </Grid>
                <div className={classes.wrapper}>
                    <div>
                        <i>Select a day to display shift details</i>

                    </div>
                    <Grid container>
                        <Grid xs={8}><Typography color='primary'>{selectedDay.date !== '' ? moment(selectedDay.date).format('dddd Do MMMM YYYY') : " "}</Typography></Grid>
                        <Grid style={{ textAlign: 'end' }} xs={4}>
                            <FormControlLabel

                                control={
                                    <PositiveSwitch
                                        checked={checked}
                                        onChange={(e) => setChecked(e.target.checked)}
                                        name=""
                                    />
                                }
                                label="Show Absence, Leave & Training"
                                labelPlacement="start"
                                name=""
                            /></Grid>
                    </Grid>
                    <MaterialTable
                        style={{ boxShadow: "0px 1px 3px #00000033", paddingRight: "0px" }}
                        icons={tableIcons}
                        title={""}
                        isLoading={tableLoading}
                        data={shiftData || []}
                        columns={columns}
                        options={tableOptions}
                        onChangePage={(page) => {
                            setPage(page);
                        }}
                        onChangeRowsPerPage={(pageSize) => {
                            setPageSize(pageSize);
                        }}

                        totalCount={totalCount}
                        page={page}

                    />




                </div>




                {
                    open && (
                        <Menu
                            id={`long-menu`}
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleCloseMenu}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: "20ch",
                                },
                            }}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }}
                            transformOrigin={{ horizontal: "right" }}
                        >
                            {(currentSelections.status === 0 || currentSelections.status === 3) &&
                                <>
                                    <MenuItem
                                        key={"approve"}
                                        style={{ fontSize: "12px" }}
                                        onClick={() => {
                                            setAction("approve");
                                        }}
                                    >
                                        <img src={GreenTickIcon} height={"18px"} style={{ marginRight: '5px' }} />{" "}    Approve
                                    </MenuItem>


                                    <MenuItem
                                        key={"reject"}
                                        style={{ fontSize: "12px" }}
                                        onClick={() => {
                                            setAction("reject");
                                        }}
                                    >
                                        <img src={RedCrossIcon} height={"18px"} style={{ marginRight: '5px' }} />{" "}   Reject
                                    </MenuItem> </>}
                            {(currentSelections.status === 1 || currentSelections.status === 2) && <MenuItem
                                key={"reopen"}
                                style={{ fontSize: "12px" }}
                                onClick={() => {
                                    handleOvertimeReOpen()
                                }}
                            >
                                <ReplayIcon style={{ marginRight: '1px' }} />  Reopen
                            </MenuItem>}

                        </Menu>
                    )
                }

                {
                    openList && (
                        <Menu
                            id={`long-menu`}
                            anchorEl={anchorElList}
                            keepMounted
                            open={openList}
                            onClose={handleCloseMenuList}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: "20ch",
                                },
                            }}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }}
                            transformOrigin={{ horizontal: "right" }}
                        >

                            <MenuItem
                                // disabled={!props.permission.write}
                                key={"View"}
                                style={{ fontSize: "12px" }}
                                onClick={() => {
                                    setAction("editarea");
                                }}
                            >
                                Edit Area
                            </MenuItem>


                            <MenuItem
                                // disabled={!props.permission.write}
                                key={"Revoke"}
                                style={{ fontSize: "12px" }}
                                onClick={() => {
                                    setAction("editzones");
                                }}
                            >
                                Edit Zone
                            </MenuItem>
                            <MenuItem
                                // disabled={!props.permission.write}
                                key={"Revoke"}
                                style={{ fontSize: "12px" }}
                                onClick={() => {
                                    setAction("markabsent");
                                }}
                            >
                                Mark as Absent
                            </MenuItem>

                        </Menu>
                    )
                }


            </MROFullScreenRightDrawer >


            {
                action && action === "approve" &&
                <ApproveOverTime
                    onClose={() => {
                        handleClose()
                        getShiftOvertimeDetails()
                    }}
                    handleClose={() => {
                        setAction('')

                    }}
                    approvedData={data}
                    id={currentSelections?.id}
                    overtimeDetails={currentSelections}
                    getListing={getListing}
                    getShiftOvertimeDetails={getShiftOvertimeDetails}
                />
            }
            {
                action && action === "reject" &&
                <RejectOverTime
                    handleClose={() => {
                        setAction('')
                        handleClose()
                        getShiftOvertimeDetails()
                    }}
                    approvedData={data}
                    id={currentSelections?.id}
                    getListing={getListing}
                    getShiftOvertimeDetails={getShiftOvertimeDetails}
                />
            }
            {
                action && action === "editarea" &&
                <EditAreas
                    handleClose={() => setAction("")}
                    onClose={() => {
                        setAction("")
                        setOpenList(false)
                    }}
                    currentSelection={currentSelection}
                    areaData={areaData}
                    getListing={getDayListingDetails}
                />
            }
            {
                action && action === "view_usedhrs" &&
                <UsedHours
                    handleClose={() => setAction("")}
                    onClose={() => {
                        setAction("")
                        setOpenList(false)
                    }}
                    currentSelection={currentSelection}

                    getListing={getDayListingDetails}
                />
            }
            {
                action && action === "editzones" &&
                <EditZones
                    handleClose={() => setAction("")}
                    onClose={() => {
                        setAction("")
                        setOpenList(false)
                    }}
                    currentSelection={currentSelection}
                    zoneData={zoneData}
                    getListing={getDayListingDetails}
                />
            }

            {
                action && action === "markabsent" && <MarkAbsent
                    handleClose={() => setAction("")}
                    onClose={() => {
                        setAction("")
                        setOpenList(false)
                    }}
                    currentSelection={currentSelection}
                    getListing={getDayListingDetails}

                />
            }

        </div >
    )
}

export default OvertimeReview