import requestApi from "../../common/boxp_api_client";




//Shift Patterns

const getShiftPatternListing = (params) => {
    return requestApi({
        url: '/shift_patterns',
        method: 'GET',
        params
    })
}

const getTimeZone = (params) => {
    return requestApi({
        url: '/time_zones',
        method: 'GET',
        params
    })
}


const createShiftPattern = (formData) => {
    return requestApi({
        url: '/shift_patterns',
        method: 'POST',
        data: formData
    })
}

const updateShiftPattern = (formData, id) => {
    return requestApi({
        url: `/shift_patterns/${id}?_method=PUT`,
        method: 'POST',
        data: formData
    })
}
// Shiftpattern - sub shift
const getSubShiftPatternListing = (params) => {
    return requestApi({
        url: '/shift_listing',
        method: 'GET',
        params
    })
}


const createSubShiftPattern = (formData) => {
    return requestApi({
        url: '/add_shift',
        method: 'POST',
        data: formData
    })
}

const updateSubShiftPattern = (formData, id) => {
    return requestApi({
        url: `/edit_shift?_method=PUT&id=${id}`,
        method: 'POST',
        data: formData
    })
}

const fetchShiftTypes = (params) => {
    return requestApi({
        url: '/shift_types',
        method: 'GET',
        params
    })
}

function deleteSubShiftPattern(params) {
    return requestApi({
        url: `/delete_shift`,
        method: 'DELETE',
        params
    })
}
const createCopyToBottom = (formData) => {
    return requestApi({
        url: '/copy_to_bottom',
        method: 'POST',
        data: formData
    })
}

const shiftMovePosition = (formData, id) => {
    return requestApi({
        url: `/move_position?_method=PUT`,
        method: 'POST',
        data: formData
    })
}

const getActiveUsersListing = (params) => {
    return requestApi({
        url: '/active_pattern_users',
        method: 'GET',
        params
    })
}



function fetchUsers(params) {
    return requestApi({
        url: '/fetch_users',
        method: 'GET',
        params: params
    })

}
const createActivePattern = (formData) => {
    return requestApi({
        url: '/create_user_shifts',
        method: 'POST',
        data: formData
    })
}
const updateActivePattern = (formData) => {
    return requestApi({
        url: '/edit_end_date',
        method: 'POST',
        data: formData
    })
}


// User Shifts tab
const getUserShiftListing = (params) => {
    return requestApi({
        url: '/shifts',
        method: 'GET',
        params
    })
}


const createUserShift = (formData) => {
    return requestApi({
        url: '/shifts',
        method: 'POST',
        data: formData
    })
}
const getBaseStation = (params) => {
    return requestApi({
        url: '/base-stations',
        method: "GET",
        params
    });
};

const updateShifZoneArea = (formData, id) => {
    return requestApi({
        url: `/edit_shift_zone_area?_method=PUT`,
        method: 'POST',
        data: formData
    })
}
const updateShifAbsentTraning = (formData, id) => {
    return requestApi({
        url: `/mark_as_absent_training?_method=PUT`,
        method: 'POST',
        data: formData
    })
}

function deleteUserShifts(params) {
    return requestApi({
        url: `/remove_shift?_method=PUT`,
        method: 'DELETE',
        params
    })
}

const updateAllShifZoneArea = (formData, id) => {
    return requestApi({
        url: `/set_all_shift_zone_area?_method=PUT`,
        method: 'POST',
        data: formData
    })
}
function deleteAllUserShifts(formData) {
    return requestApi({
        url: '/remove_all_shifts?_method=PUT',
        method: 'POST',
        data: formData
    })
}
// Leave tab
const getLeaveDetails = (params) => {
    return requestApi({
        url: '/user_leave_listing',
        method: 'GET',
        params
    })
}

const updateUserEntitlement = (formData) => {
    return requestApi({
        url: '/set_user_entitlement?_method=PUT',
        method: 'POST',
        data: formData
    })
}

const updateSetCarryForward = (formData) => {
    return requestApi({
        url: '/set_carry_forward?_method=PUT',
        method: 'POST',
        data: formData
    })
}
//Admin tab

const getDefaultListing = (params) => {
    return requestApi({
        url: '/get_default_settings',
        method: 'GET',
        params
    })
}

const createDefaultSettings = (formData) => {
    return requestApi({
        url: '/add_update_default_settings',
        method: 'POST',
        data: formData
    })
}

const getShiftAreaListing = (params) => {
    return requestApi({
        url: '/get_shift_areas',
        method: 'GET',
        params
    })
}

const createShiftArea = (formData) => {
    return requestApi({
        url: '/create_shift_area',
        method: 'POST',
        data: formData
    })
}
const updateShiftArea = (formData) => {
    return requestApi({
        url: '/update_shift_area?_method=PUT',
        method: 'POST',
        data: formData
    })
}
const getUserGroupListing = (params) => {
    return requestApi({
        url: '/get_user_group_values',
        method: 'GET',
        params
    })
}

const createUserGroup = (formData) => {
    return requestApi({
        url: '/create_user_group_value',
        method: 'POST',
        data: formData
    })
}
const updateUserGroup = (formData) => {
    return requestApi({
        url: '/update_user_group_value?_method=PUT',
        method: 'POST',
        data: formData
    })
}
const fetchUserGroups = (params) => {
    return requestApi({
        url: '/user_group_listing',
        method: 'GET',
        params
    })
}





const getZoneListing = (params) => {
    return requestApi({
        url: '/get_shift_zones',
        method: 'GET',
        params
    })
}

const createZone = (formData) => {
    return requestApi({
        url: '/create_shift_zones',
        method: 'POST',
        data: formData
    })
}
const updateZone = (formData) => {
    return requestApi({
        url: '/update_shift_zones?_method=PUT',
        method: 'POST',
        data: formData
    })
}
const resetShifts = (param) => {
    return requestApi({
        url: '/reset_shift?_method=PUT',
        method: 'POST',
        data: param
    })
}



function deleteUserGroup(params) {
    return requestApi({
        url: `/delete_user_group_value`,
        method: 'GET',
        params
    })
}
const ResourcesServices = {
    getShiftPatternListing,
    createShiftPattern,
    getDefaultListing,
    createDefaultSettings,
    getShiftAreaListing,
    createShiftArea,
    updateShiftArea,
    getUserGroupListing,
    createUserGroup,
    updateUserGroup,
    getZoneListing,
    createZone,
    updateZone,
    fetchUserGroups,
    updateShiftPattern,
    getSubShiftPatternListing,
    createSubShiftPattern,
    deleteUserGroup,
    fetchShiftTypes,
    updateSubShiftPattern,
    createCopyToBottom,
    deleteSubShiftPattern,
    shiftMovePosition,
    getActiveUsersListing,
    fetchUserGroups,
    fetchUsers,
    createActivePattern,
    updateActivePattern,
    getUserShiftListing,
    createUserShift,
    getBaseStation,
    updateShifZoneArea,
    updateShifAbsentTraning,
    deleteUserShifts,
    getLeaveDetails,
    updateUserEntitlement,
    updateSetCarryForward,
    updateAllShifZoneArea,
    deleteAllUserShifts,
    resetShifts,
    getTimeZone

}

export default ResourcesServices