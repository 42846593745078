import { Box, Divider, Grid, MenuItem, Typography, IconButton } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import MaterialTable from "material-table";
import React, {
  useState,
  useEffect,
  useContext,
  useReducer,
  useCallback,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import DataServiceAction from "../../../../actions/data_actions";
import ManageDataDialog from "../../../../components/form_components/manage_data_dialog";
import MROTextField from "../../../../components/form_components/TextField";
import tableIcons from "../../../../components/universal/table_attributes";
import { ToastMessageContext } from "../../../../lib/contexts/message_context";
import DataService from "../../../../lib/services/api";
import dataReducer, {
  INITIAL_DATA_STATE,
} from "../../../../reducers/data_reducer";
import AddNewTool from "./AddNewTool";
import { setError } from "../../../../actions/auth_actions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { WorkOrderServices } from "../../../../lib/services/api/operaitons/lineMaintenance/workOrder/workOrder";
import LineCustomerServiceNew from "lib/services/api/admin/line/line_customers_client";
import { CircularProgress } from "@material-ui/core";
import { MRO_MANAGED_PART, MRO_NON_MANAGED_PART } from "../../../../constants";
import { MRO_QR_DIRECT } from "../../../../constants";
import QuantityCounter from "components/quantity_counter";
import PartsClientServices from "../../../../lib/services/api/stores/tooling/parts_client";
import { OperationService } from "lib/services/api/operaitons/save_operation";
import InputAdornment from '@material-ui/core/InputAdornment';
import CopyParts from 'assets/copyparts.png'
import Visibility from '@material-ui/icons/Visibility';
import { WorkshopContext } from "views/Operations/Workshop/WorkshopContext/WorkshopContext";



let selectedPart = ''

export default function AddNewPartsAndTools(props) {
  const { workOrderId, currentSelection, action, lineCustomerId } = props;

  const [open, setOpen] = useState(false);
  const [state, rDispatch] = useReducer(dataReducer, INITIAL_DATA_STATE);
  const message = useContext(ToastMessageContext);
  const [errors, setErrors] = useState(false);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [mroOwned, setMroOwned] = useState(null);
  const [busy, setBusy] = useState(null);
  const [selectedPartBase, setSelectedPartBase] = useState(null);

  //table refresh
  const { tableRefresh, setTableRefresh } = useContext(WorkshopContext)

  // available qty in the database
  const [existingQty, setExistingQty] = useState(0);

  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    setBusy("loading");
    LineCustomerServiceNew.getLineCustomerDetails({
      line_customer_id: lineCustomerId,
    })
      .then((res) => {
        const {
          line_customer: { part_type },
        } = res;
        setMroOwned(part_type);
      })
      .catch((err) =>
        console.log("something went wrong from part details", err)
      )
      .finally(() => setBusy(null));
  }, []);

  useEffect(() => {
    if (props.createSection) {
      setOpen(true);
    }
  }, [props.createSection]);

  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };

  const cb = (id = "") => {
    props.setCreatedId(id);
  };

  const handleSubmit = useCallback(
    async (formData) => {
      let error = {};
      let _valid = true;
      let partRemovedData = formData.get("part_removed");
      let removalLocation = formData.get('install_location')
      let description_off = formData.get('description')
      const qty = formData.get("quantity");
      formData.delete("part_removed")
      if (partRemovedData === "yes") {
        formData.append("removal_location", removalLocation)
      }
      formData.append("description_off", description_off)
      formData.append(mroOwned === MRO_MANAGED_PART ? "rotable_removed" : "rotatable", partRemovedData === "yes" ? true : false);
      await new Promise((resolve, reject) => {
        resolve(
          (() => {
            for (var pair of formData.entries()) {
              // console.log("key-value =>", pair[0] + ', ' + pair[1]);
              if (
                pair[1] === "" &&
                pair[0] !== "serial_on" &&
                pair[0] !== "removal_reason"
              ) {
                if (pair[0] === "quantity" && mroOwned === MRO_MANAGED_PART) {
                  error[pair[0]] = {
                    valid: pair[1] === "" ? false : true,
                  };
                } else if (pair[0] !== "quantity") {
                  error[pair[0]] = {
                    valid: pair[1] === "" ? false : true,
                  };
                }

                _valid = false;
              }
            }
          })()
        );
      });

      if (
        mroOwned === MRO_MANAGED_PART &&
        qty !== "" &&
        Number(qty) > Number(existingQty)
      ) {
        error = {
          ...error,
          quantity: true,
          qtyMsg: "Please enter quantity within the available quantity.",
        };
        _valid = false;
      }

      setErrors(error);
      if (!_valid) {
        return;
      }
      // console.log("runing...");
      // formData.append("description", partDetails.description)
      if (partRemovedData === "no") {
        formData.append("part_off", "");
        formData.append("serial_off", "");
        formData.append("removal_location", "");
        formData.append("removal_reason", "");
      }

      mroOwned === MRO_MANAGED_PART
        ? formData.append("work_order_id", workOrderId)
        : formData.append("id", workOrderId);

      if (mroOwned === MRO_MANAGED_PART) {
        formData.append("user_id", user?.id);
        formData.append("base_station", selectedPartBase);
        formData.append("reportable_id", workOrderId);
      }
      if (mroOwned === MRO_NON_MANAGED_PART) {
        formData.append("reportable_id", workOrderId);
        formData.append("reportable_type", "WorkOrder");
      }
      formData.append(
        mroOwned === MRO_MANAGED_PART ? "reportable_type" : "type",
        "WorkOrder"
      );

      if (action === "update") {
        setLoading(true);
        currentSelection?.vendor_part_id &&
          formData.append("vendor_part_id", currentSelection?.vendor_part_id);
        mroOwned === MRO_MANAGED_PART &&
          formData.append("id", currentSelection?.id);
        const url =
          mroOwned === MRO_MANAGED_PART ? "editMangedPart" : "vendorPart";
        DataService.updateData(formData, "", url)
          .then((res) => {
            if (res?.success) {
              setLoading(false);
              handleClose();
              props.getList();
              setTimeout(() => {
                setTableRefresh(true)
              }, 2000)

            }
          })
          .catch((error) => {
            setLoading(false);
            // console.log("Error", error)
          }).finally(() => {
            setTableRefresh(false)
          })
      } else {
        setLoading(true);
        // formData.append('id', workOrderId)
        mroOwned === MRO_MANAGED_PART
          ? DataService.updateData(formData, "", "managedPart")
            .then((res) => {
              handleClose();
              // console.log("runing...", props.getList);
              props.getList();
              setTimeout(() => {
                setTableRefresh(true)
              }, 2000)

            })
            .catch((err) => {
              // console.log("from add parts tools", err);
            })
            .finally(() => {
              setLoading(false)
              // setTableRefresh(false)
            })
          : DataServiceAction.createData(
            rDispatch,
            message,
            formData,
            "vendorParts",
            "",
            cb,
            props.getList,
            handleClose,
          ).then((res) => {
            setTimeout(() => {
              setTableRefresh(true)
            }, 2000)

            if (res?.success) {
              setLoading(false);
              // setTableRefresh(false)
            }
          });
      }
    },
    [mroOwned, selectedPartBase, existingQty]
  );
  const createSection = props?.createSection;

  return (
    <>
      {open && (
        <ManageDataDialog
          title={
            createSection === "tool"
              ? <span style={{ fontSize: "20px" }}>Add Tool</span>
              : currentSelection
                ? "Update Parts"
                : "Add Parts"
          }
          noAction={createSection === ("tool" || "tasks")}
          type={currentSelection ? "Update" : "Create"}
          fullWidth
          maxWidth={"md"}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          loading={loading}
        >
          {createSection === "part" ? (
            <AddPart
              value={value}
              setValue={setValue}
              currentSelection={currentSelection}
              workOrderId={workOrderId}
              setErrors={setErrors}
              errors={errors}
              action={action}
              mroOwned={mroOwned}
              busy={busy}
              setSelectedPartBase={setSelectedPartBase}
              setExistingQty={setExistingQty}
              existingQty={existingQty}
            />
          ) : (
            <AddTool
              setCreatedId={props.setCreatedId}
              handleClose={handleClose}
              workOrderId={workOrderId}
            />
          )}
        </ManageDataDialog>
      )}
    </>
  );
}

const AddPart = React.memo((props) => {
  const {
    errors,
    currentSelection,
    value,
    setValue,
    mroOwned,
    busy,
    action,
    setSelectedPartBase,
    setErrors,
    setExistingQty,
    existingQty,
  } = props;
  const classes = useStyles();
  const [quantity, setQuantity] = useState();
  const [batchNumbers, setBatchNumbers] = useState([]);
  const [selectedSecondaryPart, setSelectedSecondaryPart] = useState({});
  const [details, setDetails] = useState(null);
  const [partDetails, setPartDetails] = useState({});
  const [primaryPartData, setPrimaryPartData] = useState([])
  const [primeId, setPrimeId] = useState(null)
  const [selectedSecondaryPartList, setSelectedSecondaryPartList] = useState([])
  const [selectBatch, setSelectBatch] = useState({})
  const [copySelectedPartOff, setCopSelectedpartOff] = useState(currentSelection?.part_off || '')
  const [nonManagedCopyParts, setNonmanagedCopyParts] = useState(currentSelection?.part_on || '')
  const [quantityRemoved, setQuantityRemoved] = useState();

  // fetching available qty from the database
  useEffect(() => {
    if (mroOwned === MRO_MANAGED_PART && action === "update" || "create") {
      const params = {
        batch_number:
          action === "update"
            ? currentSelection?.batch_number
            : selectedSecondaryPart?.batch_number,
      };

      DataService.getData(params, "existingQty").then((res) => {
        const { unique_part_quantity } = res;
        setExistingQty(unique_part_quantity);
      });
    }
  }, [
    mroOwned,
    selectedSecondaryPart,
    action,
    currentSelection,
    setExistingQty,
  ]);

  const handleToggle = (name, value) => {
    if (value) {
      setValue(value);
      // handleChange({ name: name, value: value })
    }
  };

  // const getBatchNumbers = async () => {
  //   const params = {
  //     search_keyword: 1,
  //   };
  //   try {
  //     const { data } = await WorkOrderServices.getBatchNumbers(params);
  //     setBatchNumbers(data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   getBatchNumbers();
  // }, []);

  // console.log("action is=================", action)

  const getDetails = async () => {
    const params = {
      batch_number:
        action === "update"
          ? currentSelection.batch_number
          : selectBatch.batch_number,
    };
    try {
      const { data } = await WorkOrderServices.getBatchNumbers(params);
      // console.log(data);
      if (action != "update") {
        setExistingQty(data?.quantity)
      }
      setDetails(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const { batch_number } = selectedSecondaryPart;

    if (batch_number || currentSelection?.batch_number || selectBatch?.batch_number) {
      getDetails();
    }
  }, [selectedSecondaryPart?.batch_number, currentSelection?.batch_number, selectBatch?.batch_number]);

  useEffect(() => {
    setValue(Boolean(currentSelection?.rotatable) ? "yes" : "no");
    currentSelection && setQuantity(currentSelection.quantity);
    currentSelection && setQuantityRemoved(currentSelection.removed_qty)
    if (
      currentSelection?.part_off &&
      currentSelection?.serial_off &&
      currentSelection?.removal_location
    ) {
      setValue("yes");
    }
    console.table(currentSelection);
  }, [currentSelection]);

  useEffect(() => {
    details && setSelectedPartBase(details?.base);
  }, [details?.base]);


  const handleQuantityChange = (action) => {
    if (action === "inc") {
      if (mroOwned === MRO_MANAGED_PART && quantity >= existingQty) return;
      if (parseFloat(+quantity || 0) >= 0)
        if (parseFloat(+quantity) % 0.5 === 0) setQuantity(+quantity + 1);
        else setQuantity(Math.floor(+quantity || 0) + 1);
    }
    if (action === "dec") {
      if (parseFloat(+quantity) > 0)
        if (parseFloat(+quantity) % 0.5 === 0) setQuantity(+quantity - 1);
      //     else setVal(0)
      // else setVal(0)
    }
  };


  const getPrimePartData = async () => {
    if (primaryPartData) {
      PartsClientServices.getAllPartsDependency({}).then((res) => {
        setPrimaryPartData(res.data);
      });
    }
  }


  useEffect(() => {
    getPrimePartData()
  }, [primeId])

  // console.log("primaryPartData is===============", currentSelection)

  useEffect(() => {
    const params = {
      id: primeId
    }
    if (primeId) {
      PartsClientServices.getAllSecondaryParts(params).then((res) =>
        setSelectedSecondaryPartList(res.data),
        selectedPart = primeId
      );
    }
  }, [primeId]);

  if (busy === "loading") {
    return (
      <Box
        height={400}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb={8}
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  // copy part on value to partoff 


  const handleClickCopyParts = () => {
    // console.log("primarypartData=====", partDetails, nonManagedCopyParts)
    if (mroOwned === MRO_MANAGED_PART) {
      setCopSelectedpartOff(partDetails?.part_number)
    }
    else {
      setCopSelectedpartOff(nonManagedCopyParts)
    }
  }

  return (
    <React.Fragment>
      <Box height={400} width="100%" mb={8}>
        <Grid container direction={"row"} justify="space-around">
          <Grid item xs={5}>
            <Box height="100%" width="100%" px={0}>
              <Typography variant="subtitle2" gutterBottom={12}>
                Installed Part
              </Typography>
              {mroOwned === MRO_MANAGED_PART ? (
                <>
                  <Box width="100%">
                    <Grid item xs={12}>
                      <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        options={primaryPartData || []}
                        getOptionLabel={(option) =>
                          option?.part_number ? option?.part_number : ""
                        }
                        onChange={(event, newValue) => {
                          setPrimeId(newValue?.primary_part_id)
                          setPrimaryPartData(newValue);
                          setPartDetails({
                            part_number: newValue.part_number,
                            description: newValue.description,
                            serial_number: newValue.serial_number
                          })
                        }}
                        inputValue={currentSelection?.part_number}
                        className={"search"}
                        disabled={currentSelection?.part_number && true}
                        renderInput={(params) => (
                          <MROTextField
                            {...params}
                            label={"Part # *"}
                            placeholder="First, search & select part number"
                            name={"part_number"}
                            margin="normal"
                            variant="outlined"
                            id="test"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                            InputLabelProps={{ shrink: true }}
                            className={classes.inputField}
                            color="primary"
                            error={errors?.part_number && !errors?.part_number?.valid}
                            disabled={currentSelection?.part_number && true}
                          />
                        )}
                      />
                      {/* <MROTextField
                      disabled={currentSelection?.part_on && true}
                      autoFocus={errors?.part_on}
                      defaultValue={currentSelection?.part_on}
                      name="part_on"
                      label="Part # On *"
                      error={errors?.part_on && !errors?.part_on?.valid}
                      InputLabelProps={{ shrink: true }}
                    /> */}
                    </Grid>

                    {/* <MROTextField
                    disabled={currentSelection?.batch && true}
                    defaultValue={currentSelection?.batch}
                    name="batch"
                    label="Batch # *"
                    InputLabelProps={{ shrink: true }}
                    error={errors?.batch && !errors?.batch?.valid}
                  /> */}

                    <MROTextField
                      disabled={currentSelection?.batch && true}
                      defaultValue={currentSelection?.batch ?? "select"}
                      name="batch_number"
                      label="Batch #"
                      select
                      InputLabelProps={{ shrink: true }}
                      error={errors?.batch_number && !errors?.batch_number?.valid}
                      onClick={(e) => setSelectBatch({ batch_number: e.target.value })}
                    >
                      <MenuItem value="select">
                        <span style={{ opacity: "40%" }}>Second, select batch number</span>
                      </MenuItem>
                      {selectedSecondaryPartList.length === 0 &&
                        <span style={{ opacity: "40%", display: "flex", justifyContent: "center" }}>No Result Found</span>}
                      <MenuItem>
                      </MenuItem>
                      {selectedSecondaryPartList.length !== 0 && selectedSecondaryPartList.filter(((item) => item.state === "In Stock")).map((uom) => (
                        <MenuItem
                          key={uom.batch_number}
                          value={uom.batch_number}
                        >
                          {uom.batch_number}
                        </MenuItem>
                      ))}
                    </MROTextField>
                    <MROTextField
                      style={{ display: "none" }}
                      value={partDetails.description}
                      // disabled={currentSelection?.description && true}
                      // defaultValue={currentSelection?.description}
                      name="description"
                    // label="Description *"
                    // InputLabelProps={{ shrink: true }}
                    // error={errors?.description && !errors?.description?.valid}
                    />
                    <div className={classes.purchaseInfo2}>
                      <div>
                        <span>Part #</span>
                        <span>{(partDetails?.part_number || currentSelection?.part_on) ?? "-"}</span>
                      </div>
                      <div>
                        <span>Description</span>
                        <span>{(partDetails?.description || currentSelection?.description) ?? "-"}</span>
                      </div>
                      <div>
                        <span>Serial # On</span>
                        <span>{(partDetails?.serial_number || currentSelection?.serial_number) ?? "-"}</span>
                      </div>
                    </div>
                  </Box>
                  {/* <Grid
                    style={{ marginBottom: "0px", marginTop: "20px" }}
                    container
                    spacing={5}
                  >
                    <Grid className={classes.purchaseGrid} item xs={12}>
                      <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        options={batchNumbers ? batchNumbers : []}
                        getOptionLabel={(option) =>
                          option.batch_number ? option.batch_number : ""
                        }
                        onChange={(event, newValue) => {
                          setSelectedSecondaryPart(newValue);
                        }}
                        inputValue={currentSelection?.batch_number}
                        // className={"search"}
                        disabled={action === "update"}
                        renderInput={(params) => (
                          <MROTextField
                            {...params}
                            label={"Batch #"}
                            placeholder="Search part by batch #"
                            name={"batch_number"}
                            margin="normal"
                            variant="outlined"
                            id="test"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                            InputLabelProps={{ shrink: true }}
                            className={classes.inputField}
                            color="primary"
                            disabled={action === "update"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid> */}

                  {/* {details && (
                    <div className={classes.purchaseInfo}>
                      <div>
                        <span>Part#</span>
                        <span>{(details?.part_number) ?? "-"}</span>
                      </div>
                      <div>
                        <span>Description</span>
                        <span>{(details?.description) ?? "-"}</span>
                      </div>
                      <div>
                        <span>Serial # On</span>
                        <span>{details?.serial_number ?? "-"}</span>
                      </div>
                    </div>
                  )} */}
                </>
              ) :
                <>
                  <MROTextField
                    // disabled={currentSelection?.part_on && true}
                    autoFocus={errors?.part_on}
                    defaultValue={currentSelection?.part_on}
                    name="part_on"
                    label="Part # On *"
                    error={errors?.part_on && !errors?.part_on?.valid}
                    InputLabelProps={{ shrink: true }}
                    value={nonManagedCopyParts}
                    onChange={(e) => setNonmanagedCopyParts(e.target.value)}
                    placeholder={'Enter Part Number'}
                  />
                  <MROTextField
                    // style={{ display: "none" }}
                    // value={currentSelection.description}
                    // disabled={currentSelection?.description && true}
                    defaultValue={currentSelection?.description}
                    name="description"
                    label="Description *"
                    InputLabelProps={{ shrink: true }}
                    error={errors?.description && !errors?.description?.valid}
                    placeholder={'Enter Part Description'}
                  />
                  <MROTextField
                    // disabled={currentSelection?.batch && true}
                    defaultValue={currentSelection?.batch}
                    name="batch"
                    label="Batch # *"
                    InputLabelProps={{ shrink: true }}
                    error={errors?.batch && !errors?.batch?.valid}
                    placeholder={'Enter Batch Number'}
                  />
                  <MROTextField
                    defaultValue={currentSelection?.serial_on}
                    // defaultValue={details?.serial_number}
                    name="serial_on"
                    label="Serial #"
                    variant="outlined"
                    value={details?.serial_number}
                    // style={{ opacity: 0 }}
                    InputLabelProps={{ shrink: true }}
                    placeholder={'Enter Serial # as applicable'}
                  />
                </>
              }

              {/* <MROTextField
                defaultValue={currentSelection?.quantity}
                name="quantity"
                label="Quantity *"
                InputLabelProps={{ shrink: true }}
                error={errors?.quantity && !errors?.quantity?.valid}
              /> */}

              <Grid
                container
                // spacing={1}
                alignItems={"center"}
              // justify={"flex-start"}
              >
                <QuantityCounter
                  name="quantity"
                  label={
                    mroOwned === MRO_MANAGED_PART ? "Quantity*" : "Qty"
                  }
                  // placeholder={placeholder}
                  setQty={setQuantity}
                  qty={quantity}
                  autoFocus={errors.quantity}
                  error={errors.quantity}
                  helperText={
                    errors.quantity
                      ? errors.qtyMsg
                        ? errors.qtyMsg
                        : "*please enter Quantity"
                      : mroOwned === MRO_MANAGED_PART
                        ? `Available quantity ${existingQty}`
                        : null
                  }
                  setError={() =>
                    setErrors({ ...errors, quantity: false, qtyMsg: null })
                  }
                // minQty={1}
                />
                {/* <Grid item xs={7}>
                  <MROTextField
                    className={classes.errorRight}
                    name={"quantity"}
                    label={
                      mroOwned === MRO_MANAGED_PART ? "Quantity*" : "Quantity"
                    }
                    InputLabelProps={{ shrink: true }}
                    defaultValue={currentSelection?.quantity || quantity}
                    value={quantity}
                    autoFocus={errors.quantity}
                    error={errors.quantity}
                    helperText={
                      errors.quantity
                        ? errors.qtyMsg
                          ? errors.qtyMsg
                          : "*please enter Quantity"
                        : mroOwned === MRO_MANAGED_PART
                        ? `Available quantity ${existingQty}`
                        : null
                    }
                    autoComplate={false}
                    onChange={(e) => {
                      setErrors({ ...errors, quantity: false, qtyMsg: null });
                      let val = e.target.value;
                      if (isNaN(val)) {
                        val = val.replace(/[^0-9]/g, "");
                      }
                      if (val.split(".").length > 1)
                        val = val.replace(/\.+$/, "");
                      val = val.replace(/^0+/, "");
                      setQuantity(val);
                    }}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  <ButtonGroup>
                    <Button onClick={() => handleQuantityChange("dec")}>
                      <Subtract fontSize="small" />
                    </Button>
                    <Button onClick={() => handleQuantityChange("inc")}>
                      <Add fontSize="small" />
                    </Button>
                  </ButtonGroup>
                </Grid> */}
              </Grid>

              <MROTextField
                defaultValue={currentSelection?.install_location}
                name="install_location"
                label="Install Location *"
                InputLabelProps={{ shrink: true }}
                error={
                  errors?.install_location && !errors?.install_location?.valid
                }
                placeholder="Location"
              />

              {mroOwned === MRO_MANAGED_PART && (
                <MROTextField
                  // defaultValue={currentSelection?.serial_on}
                  defaultValue={details?.serial_number}
                  name="serial_on"
                  label="Serial # On"
                  variant="outlined"
                  value={details?.serial_number}
                  style={{ opacity: 0, visibility: "hidden" }}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            </Box>
          </Grid>

          <Divider orientation="vertical" flexItem />

          <Grid item xs={5}>
            <Box height="100%" width="100%" px={0}>
              <Typography variant="subtitle2" gutterBottom={12}>
                Removed Part
              </Typography>
              <Box mb={1}>
                <Box display="inline-block" mr={2}>
                  <Typography gutterBottom={12}>
                    Record Part Removed *
                  </Typography>
                </Box>
                <input
                  style={{ display: "none" }}
                  name="part_removed"
                  value={value}
                />
                <ToggleButtonGroup
                  value={value}
                  name=""
                  exclusive
                  onChange={(e, val) => handleToggle("rotable", val)}
                  aria-label="yes-or-no"
                >
                  <ToggleButton
                    className={
                      errors.part_removed && !errors?.part_removed?.valid
                        ? classes.error
                        : {}
                    }
                    size={"small"}
                    value="no"
                    aria-label="centered"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    className={
                      errors.part_removed && !errors?.part_removed?.valid
                        ? classes.error
                        : {}
                    }
                    size={"small"}
                    value="yes"
                    aria-label="left aligned"
                  >
                    Yes
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              {value === "yes" && (
                <>
                  <MROTextField
                    name="part_off"
                    error={errors?.part_off && !errors?.part_off?.valid}
                    label="Part # Off *"
                    variant="outlined"
                    value={copySelectedPartOff}
                    defaultValue={currentSelection?.part_off}
                    onChange={(e) => setCopSelectedpartOff(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    placeholder={"Enter Part Number"}
                    InputProps={{

                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="copy-part-on"
                          onClick={handleClickCopyParts}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          <img src={CopyParts} height={'22px'} />
                        </IconButton>
                      </InputAdornment>,

                    }}

                  />
                  <MROTextField
                    name="serial_off"
                    error={errors?.serial_off && !errors?.serial_off?.valid}
                    variant="outlined"
                    label="Serial # Off *"
                    defaultValue={currentSelection?.serial_off}
                    InputLabelProps={{ shrink: true }}
                    placeholder={"Enter Serial Number"}
                  />
                  <Grid
                    container
                    // spacing={1}
                    alignItems={"center"}
                  // justify={"flex-start"}

                  >
                    <QuantityCounter
                      name="removed_quantity"
                      label={"QTY Removed *"}
                      // placeholder={placeholder}
                      setQty={setQuantityRemoved}
                      qty={quantityRemoved}
                      autoFocus={errors.removed_quantity}
                      error={errors.removed_quantity}
                      helperText={
                        errors.quantity_removed
                          ? errors.qtyMsg
                            ? errors.qtyMsg
                            : "*please enter Quantity"
                          // : mroOwned === MRO_MANAGED_PART
                          //   ? `Available quantity ${existingQty}`
                          : null
                      }
                      setError={() =>
                        setErrors({ ...errors, removed_quantity: false, qtyMsg: null })
                      }
                    // minQty={1}
                    />
                  </Grid>


                  {/* {mroOwned === MRO_NON_MANAGED_PART && (<MROTextField
                    name="removal_location"
                    label="Removal Location *"
                    error={
                      errors?.removal_location &&
                      !errors?.removal_location?.valid
                    }
                    variant="outlined"
                    defaultValue={currentSelection?.removal_location}
                    InputLabelProps={{ shrink: true }}
                  />)} */}
                  <MROTextField
                    name={
                      mroOwned === MRO_MANAGED_PART
                        ? "issued_comment"
                        : "removal_reason"
                    }
                    label={mroOwned === MRO_MANAGED_PART ? "U/S Comment *" : "Comment"}
                    defaultValue={
                      mroOwned === MRO_MANAGED_PART
                        ? currentSelection?.issued_comment
                        : currentSelection?.removal_reason
                    }
                    multiline
                    rows={4}
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter a removal reason or other comment as applicable - Max 150 Characters"
                  />
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
});

const AddTool = React.memo((props) => {
  return (
    <React.Fragment>
      <AddNewTool {...props} />
    </React.Fragment>
  );
});

const useStyles = makeStyles((theme) => ({
  error: {
    borderColor: "#ff0056",
    borderWidth: "1px",
    borderStyle: "solid",
    margin: "0px",
  },
  purchaseInfo: {
    background: "#F5F5F5",
    borderRadius: "8px",
    padding: "10px 25px",
    "& div": {
      display: "flex",
      alignItems: "center",
      margin: "10px 0",
      fontSize: "16px",
      "& span:first-child": {
        flex: "0 0 50%",
        color: theme.palette.secondary.main,
      },
      "& span:last-child": {
        color: "#4D4F5C",
      },
    },
  },
  purchaseInfo2: {
    borderRadius: "8px",
    // padding: "10px 25px",
    "& div": {
      display: "flex",
      alignItems: "center",
      margin: "10px 0",
      fontSize: "16px",
      "& span:first-child": {
        flex: "0 0 50%",
        color: theme.palette.primary.main,
      },
      "& span:last-child": {
        color: "#4D4F5C",
      },
    },
  },
  purchaseGrid: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
}));
