import React, { useContext, useEffect, useReducer, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  FormControl,
  Select,
  InputBase,
  MenuItem,
  Box,
  IconButton,
  FormControlLabel,
  Switch,
  useTheme,
} from "@material-ui/core";
import SearchBarWithFilters from "components/search_with_filters";
import useDependencies from "lib/hooks/useDependencies";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import MROTextField from "components/form_components/TextField";
import QuantityCounter from "components/quantity_counter";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import MROButton from "components/buttons";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import useDebounce from "lib/utils/debounce";
import { ToastMessageContext } from "lib/contexts/message_context";
import { Visibility } from "@material-ui/icons";
import WarningIcon from "@material-ui/icons/Warning";
import StockInfo from "views/Stores/Parts/Stocks/stockInfo";
import { ICON_COLOUR } from "lib/constants/style_constants";
import { OperationService } from "lib/services/api/operaitons/save_operation";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { validateFormData } from "lib/utils/helperFunctions";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    "& .MuiOutlinedInput-multiline": {
      minHeight: "89px",
    },

    "& textarea::placeholder": {
      textTransform: "none !important",
    },

    "& form": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
  },
  title: {
    fontSize: "17px",
  },

  StockInfoDrawer: {
    "& > div[aria-hidden='true']": {
      visibility: "hidden !important",
    },
  },
}));

function Search(props) {
  const {
    baseStationId,
    lineCustomerId,
    acReg,
    onClose,
    bases,
    lineCustomers,
    currentSelectedOrder,
    actionDpdOptions,
    getList:parentGetList,
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const message = useContext(ToastMessageContext);

  const user = JSON.parse(sessionStorage.getItem("user"));

  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize, inProgress, data } = tableState;
  const [searchText, setSearchText] = useState("");
  const debouncedVal = useDebounce(searchText);

  const [qty, setQty] = useState();

  const [value, setValue] = useState("no");

  const [currentSelection, setCurrentSelection] = useState({});
  const [action, setAction] = useState(null);

  const [errors, setErrors] = useState({});
  const [busy, setBusy] = useState(null);

  const [filters, setFilters] = useState({
    line_customer_id: null,
    mro_owned: false,
  });

  const [selectedPart, setSelectedPart] = useState({});

  useEffect(() => {
    setFilters((prevState) => ({
      ...prevState,
      base_station_id: baseStationId,
    }));
  }, [baseStationId]);

  const filterChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilters((e) => ({ ...e, [name]: value }));
  };

  const getList = () => {
    const parmas = {
      search_keyword: debouncedVal,
      page: page + 1,
      count_per_page: pageSize,
      ...filters,
    };

    TableAction.getList(dispatch, message, parmas, "requestPart");
  };

  useEffect(() => {
    getList();
  }, [page, debouncedVal, pageSize, filters]);

  // const addPartFields = [
  //   {
  //     label: "Selected Part # *",
  //     placeholder: "No Part Selected",
  //     type: "text",
  //     required: true,
  //     name: "selected_part_number",
  //     disabled: true,
  //     defaultValue: selectedPart.part_number,
  //   },
  //   {
  //     label: "Base Stock QTY",
  //     placeholder: "No Part Selected",
  //     type: "text",
  //     required: false,
  //     name: "stock_qty",
  //     disabled: true,
  //     defaultValue: selectedPart.own_stock,
  //   },
  //   {
  //     label: "Select Action *",
  //     placeholder: "Select",
  //     type: "select",
  //     required: true,
  //     name: "select_action",
  //     disabled: false,
  //   },
  // ];

  // const repairableFields = [
  //   {
  //     label: "Part # Off *",
  //     placeholder: "Enter Part Number",
  //     type: "text",
  //     required: true,
  //     name: "part_off",
  //     disabled: false,
  //   },
  //   {
  //     label: "Serial # Off *",
  //     placeholder: "Enter Serial Number",
  //     type: "text",
  //     required: true,
  //     name: "serial_off",
  //     disabled: false,
  //   },
  //   {
  //     label: "U/S Comment",
  //     placeholder:
  //       "Enter a removal reason or other comment as applicable - Max 150 Characters",
  //     type: "description",
  //     required: false,
  //     name: "us_comment",
  //     disabled: false,
  //   },
  // ];

  const tableOptions = {
    ...options,
    page: page,
    search: false,
    total: totalCount,
    pageSize: pageSize,
    searchText: searchText,
    toolbar: false,
  };

  const handleViewInfo = (rowData) => {
    setAction("view");
    setCurrentSelection(rowData);
  };

  const columns = [
    {
      title: "Part #",
      field: "part_number",
    },
    {
      title: "Description",
      field: "description",
      cellStyle: {
        width: "40%",
        paddingLeft: "20px",
      },
      headerStyle: {
        width: "40%",
        textAlign: "left",
        paddingLeft: "20px",
      },
    },
    {
      title: "Total Stock",
      field: "total_stock",
    },
    {
      title: "Base Stock",
      field: "own_stock",
    },

    {
      title: "Stock Info",
      field: "",
      headerStyle: {
        textAlign: "center",
        width: "6%",
      },
      cellStyle: {
        textAlign: "center",
        width: "6%",
      },
      render: (rowData) => (
        <IconButton onClick={() => handleViewInfo(rowData)}>
          <Visibility fontSize="small" htmlColor={ICON_COLOUR} />
        </IconButton>
      ),
    },
    {
      title: "Select",
      field: "select",
      render: (rowData) => (
        <IconButton onClick={() => setSelectedPart(rowData)}>
          <TouchAppIcon />
        </IconButton>
      ),
    },
  ];

  const handleToggle = (value) => {
    if (value) {
      setValue(value);
    }
  };

  useEffect(() => {
    console.log("renderning..");
    if (qty > selectedPart.own_stock) {
      console.log("inside if check");
      setErrors((prevState) => ({
        ...prevState,
        quantity:
          "Required QTY exceeds Base Stock QTY! - Please enter a deferred defect number for tracking",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        quantity: false,
      }));
    }
    console.log("errors", errors);
  }, [qty, selectedPart.own_stock]);

  const resetErrorHandler = (name) =>
    setErrors((e) => ({ ...e, [name]: false }));

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    let issued_comment = formData.get("reason")
    formData.delete("reason")
    formData.append("issued_comment", issued_comment);
    formData.append("from_base_station_id", currentSelectedOrder.base_station_id);
    formData.append("to_base_station_id", currentSelectedOrder.base_station_id);
    formData.append("user_id", user?.id);
    formData.append("primary_part_id", selectedPart.id);
    formData.append("reportable_type", "WorkshopOrder");
    formData.append("reportable_id", currentSelectedOrder.id);
    formData.append("order_number", currentSelectedOrder?.order_no);
    formData.append("line_customer_id", currentSelectedOrder?.line_customer_id);

    const { valid, error } = validateFormData(formData);

    const required_quantity = formData.get('quantity');
    if(+required_quantity === 0) {
      error['quantity'] = true;
      error['required_quantity_is_zero'] = true
      valid = false
    }

    if (!valid) {
      setErrors(error);
      return;
    }

    setBusy("loading");
    try {
      const response = await MaintenanceServices.createPartRequest(formData);
      console.log("response", response);
      if (response.success) {
        message.showToastMessage({
          message: "Added successfully.",
          variant: "success",
        });
        onClose();
        parentGetList();
      }

      setBusy(null);
    } catch (error) {
      console.log("error", error);
      message.showToastMessage({
        message: "Something went wrong. Try Again",
        variant: "error",
      });
      setBusy("error");
    }
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={8}>
          <Typography color="primary" className={classes.title}>
            Search Part
          </Typography>
          <SearchBarWithFilters
             handleSearch={(value) => {
              TableAction.setPage(dispatch, 0);
              setSearchText(value);
          }}
            searchText={searchText}
          >
            <FormControl className={"filter"}>
              <Select
                name="base_station_id"
                value={filters.base_station_id}
                displayEmpty
                input={<InputBase />}
                onChange={filterChangeHandler}
              >
                <MenuItem value={undefined}>Base (All)</MenuItem>
                {bases.map((e) => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.station}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={"filter"}>
              <Select
                fullWidth
                displayEmpty
                value={filters.id}
                defaultValue={""}
                onChange={(e) =>
                  setFilters((prevState) => ({
                    ...prevState,
                    line_customer_id: e.target.value,
                    mro_owned: false,
                  }))
                }
                input={<InputBase />}
                // disabled={filters.mro_owned}
              >
                <MenuItem value={""}>Owner (All)</MenuItem>
                {lineCustomers?.map((item, i) => (
                  <MenuItem key={i} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              style={{ marginTop: "-7.5px" }}
              control={
                <Switch
                  checked={filters?.mro_owned}
                  onChange={(e) =>
                    setFilters((prevState) => ({
                      ...prevState,
                      mro_owned: e.target.checked,
                      line_customer_id: null,
                    }))
                  }
                  // name="checkedB"
                  color="primary"
                />
              }
              label="MRO Owned Part?"
              labelPlacement="start"
            />
          </SearchBarWithFilters>

          {/* Table */}
          <MaterialTable
            style={{ marginTop: 20 }}
            columns={columns}
            icons={tableIcons}
            title=""
            data={data.data || []}
            isLoading={inProgress}
            options={tableOptions}
            onChangePage={(page) => {
              TableAction.setPage(dispatch, page);
            }}
            onChangeRowsPerPage={(pageSize) => {
              TableAction.setPageSize(dispatch, pageSize);
            }}
            onSearchChange={(search) => {
              TableAction.setSearchText(dispatch, search);
            }}
            totalCount={totalCount}
            page={page}
          />
        </Grid>
        <Grid item xs={4} style={{ paddingLeft: "50px" }}>
          <Typography color="primary" className={classes.title}>
            Selected Part
          </Typography>

          <form noValidate onSubmit={submitHandler}>
            <MROTextField
              name={"part_number"}
              value={selectedPart.part_number}
              disabled
              label={"Select Part"}
              InputLabelProps={{ shrink: true }}
              placeholder={"No part selected"}
              required
            ></MROTextField>
            <MROTextField
              name={"workshop_action_id"}
              label={"Select Action"}
              InputLabelProps={{ shrink: true }}
              placeholder={"Select"}
              required
              select
              onChange={(e) => resetErrorHandler(e.target.name)}
              error={errors.workshop_action_id}
            >
              {actionDpdOptions.map((e) => (
                <MenuItem
                  key={e.workshop_action_id}
                  value={e.workshop_action_id}
                >
                  {e.action_number}
                </MenuItem>
              ))}
            </MROTextField>
            <MROTextField
              style={{ minHeight: "50px" }}
              name={"reason"}
              label={"Comments"}
              multiline="true"
              inputProps={{maxLength:150}}
              InputLabelProps={{ shrink: true }}
              placeholder={
                "Optional alternate part comments for nil stock items – Max 150 characters"
              }
              required
              onChange={(e) => resetErrorHandler(e.target.name)}
              error={errors.reason}
            ></MROTextField>

            <QuantityCounter
              name={"quantity"}
              label={"Required QTY"}
              placeholder="QTY"
              required
              autoFocus={errors["quantity"]}
              error={errors["quantity"] === true}
              helperText={
                errors["quantity"] &&
                typeof errors["quantity"] === "boolean" &&
                (errors["required_quantity_is_zero"]
                  ? "QTY Required Must Be Greater Than 0"
                  : `Please select Quanityt Received`)
              }
              shouldNotAcceptZero={true}
              setError={() =>
                setErrors((prevState) => ({
                  ...prevState,
                  quantity: false,
                }))
              }
              setQty={setQty}
              qty={qty}
            />
            {/* {errors["quantity"] && typeof errors["quantity"] === "string" && (
              <>
                <Box display="flex" alignItems="center">
                  <WarningIcon
                    style={{
                      color: "#FFB300",
                      marginRight: "10px",
                      fontSize: 20,
                    }}
                  />
                  Required QTY exceeds Base Stock QTY! - Please enter a deferred
                  defect number for tracking
                </Box>
                <MROTextField
                  placeholder="Deferral #"
                  label="ADD / Deferral # *"
                  name="defferal_data"
                  autoFocus={errors["defferal_data"]}
                  onChange={(e) => resetErrorHandler(e.target.name)}
                  error={errors.defferal_data}
                  variant="outlined"
                  color="primary"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={currentSelection["defferal_data"]}
                />
              </>
            )} */}

            <Box mt="auto" display="flex" justifyContent="flex-end">
              <MROButton
                style={{ marginRight: "20px" }}
                type="button"
                variant={"contained"}
                onClick={onClose}
              >
                Cancel
              </MROButton>
              <MROButton
                type="submit"
                variant={"contained"}
                style={{
                  backgroundColor:
                    selectedPart.part_number || selectedPart.own_stock
                      ? theme.palette.primary.main
                      : "#FFB300",
                  color: "#fff",
                }}
                loading={busy === "loading"}
              >
                Add
              </MROButton>
            </Box>
          </form>
        </Grid>
      </Grid>
      {action === "view" && currentSelection && (
        <StockInfo
          hideEdit={true}
          showSelectPartBtn
          onSelectPartBtnClick={(partDetails) => {
            setSelectedPart({
              own_stock: currentSelection.own_stock,
              id: currentSelection.id,
              ...partDetails,
            });
            setAction(null);
          }}
          permission={{ write: true }}
          action={action}
          currentSelection={currentSelection}
          handleClose={() => setAction(null)}
          className={classes.StockInfoDrawer}
        />
      )}
    </>
  );
}

export default Search;
