import { FormControl, FormControlLabel, Grid, MenuItem, Select, InputBase, Slider, Typography, IconButton, makeStyles, Accordion, AccordionDetails, AccordionActions, AccordionSummary, Tooltip, Box, Menu, Button, Divider, CircularProgress, useTheme, Popover, Paper, ButtonGroup, alpha, createTheme, ThemeProvider } from '@material-ui/core'
import { Add } from '@material-ui/icons';
import MROButton from 'components/buttons';
import SearchBarWithFilters from 'components/search_with_filters'
import React, { useContext, useState, useRef } from 'react'
import FullScreenIcon from 'assets/display-size.svg'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDownwardIcon from 'assets/Dashboard/arrowdownward.svg'
import ArrowUpwardIcon from 'assets/Dashboard/arrowupward.svg'
import EndCall from 'assets/Dashboard/end-call.svg'
import EndCallGrey from 'assets/Dashboard/end-call -grey.svg'
import CheckedIcon from '@material-ui/icons/CheckOutlined';
import ClearIcon from '@material-ui/icons/ClearOutlined';
import RedCrossIcon from 'assets/Dashboard/remove.svg'
import PlanningIconGrey from "assets/maint/Planning/planningIconGrey.png";
import AddIcon from 'assets/Dashboard/audience.svg'
import AllocateEngineers from './AllocateEngineers';
import CalledOut from './CalledOut';
import CancelEvent from './CancelEvent';
import CreateLMEvents from './CreateLMEvents';
import EditLmEvents from './EditLmEvents';
import CustomPaginationActionsTable from './TablePaginationActions';
import { LineMaintainenceServices } from 'lib/services/api/dashboard/line_maintenance';
import { useEffect } from 'react';
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation'
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import _without from "lodash/without";
import PositiveSwitch from 'components/form_components/switch'
import { ToastMessageContext } from 'lib/contexts/message_context';
import LineCustomerService from 'lib/services/api/admin/line/line_customer_client_new'
import { formatToMins, getOrganisationId, format24HrDateOnly } from 'lib/utils/common_utils';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import _ from 'lodash';
import PlanningLineMaintenance from 'lib/services/api/planning/LineMaintenance/lineEventsandSeries';
import Pagination from '@material-ui/lab/Pagination';
import DetailsPopUp from './DetailsPopUp';
import ActionInformation from 'views/Operations/Workshop/Inspection/ActionInformation/ActionInformation';
import moment from 'moment';
import AmberIcon from 'assets/alert_orange.png'
import LinkIcon from 'assets/FormLinkIcon.svg'
import LinkWorkOrder from 'views/Planning/Line maintenance/LMEvents/LinkWorkOrder';



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '&.MuiAccordionSummary-root': {
            paddingLeft: '6px !important',
            paddingRight: '18px !important'
        }
    },
    firstRow: {
        fontSize: '17px',
        textAlign: 'left'
    },
    secondRowText: {

        fontSize: '11px',
        textAlign: 'left'
    },
    detailtext: {
        paddingTop: '1px',
        fontSize: '13px'

    },
    plannedText: {
        fontSize: '15px',
        textAlign: 'left',

    },
    accordianDetails: {
        paddingBottom: '10px'
    },
    eventText: {
        background: '#ffffff',
        fontSize: '12px',
        marginTop: '-11px',
        width: '94px',
        textAlign: 'center'

    },
    tooltipText: {
        background: 'white', color: '#000000', fontSize: '9px'
    },
    iconLayout: {
        border: '1px solid #707070', minWidth: '108px', marginLeft: '-13px'

    },
    wrapper: {
        // backgroundColor: "#F5F5F5",
        width: 'auto',
        height: '100vh',
        backgroundSize: 'cover',
        padding: theme.spacing(2, 3),
        border: "1px solid transparent",
        margin: "1% 0",
    },
    pictureBox: {
        paddingTop: "60px",
    },
    orgLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: '40px',
        // top: '40px',
        marginBottom: '40px',
        right: '40px',
        '& img': {
            maxWidth: '300px',
            maxHeight: '175px'
        }
    },
    pageButton: {

        width: '51px',
        height: '51px',
        fontSize: '27px'

    },
    select: {
        '&.MuiSelect-root':
        {
            fontSize: '25px',
            paddingTop: "15px"
        },
        // '&.MuiSelect-icon': {
        //     color: " rgba(0, 0, 0, 0.54)",
        //     right: 0,
        //     position: "absolute",
        //     pointerEvents: " none",
        //     top: "1px",
        //     fontSize: " 32px",
        //     left: " 46px",

        // }

    }


}));




function StationBoard(props) {
    const classes = useStyles();
    const theme = useTheme()
    const [expanded, setExpanded] = useState(false);
    let userDetails = JSON.parse(sessionStorage.getItem("authState"));
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const [showText, setShowText] = useState('')
    const [action, setAction] = useState('')
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(10);
    const [eventDetails, setEventDetails] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [customerDetails, setCustomerDetails] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [custButton, setCustButton] = useState(false)
    const [typeButton, setTypeButton] = useState(false)

    const message = useContext(ToastMessageContext)
    const [selectedBase, setSelectedBase] = useState({})
    const [filterData, setFilterData] = useState({ base_station_id: userDetails?.base_station?.id });
    const [liveDate, setLiveDate] = useState('')
    const [loading, setLoading] = useState(false)
    //slider value
    const [bases, setBases] = useState([]);
    const [currentSelection, setCurrentSelection] = useState({})
    //fullscreen
    const [approvalTypes, setApprovalTypes] = useState([])
    const [eventTypes, setEventTypes] = useState([])
    const [slideSelectedValue, setValueSlide] = useState(0)
    const handle = useFullScreenHandle();
    const [fullScreenState, setFullScreenState] = useState(false)
    const containerRef = useRef(null);
    const [mouseX, setMouseX] = useState();
    const [mouseY, setMouseY] = useState();
    const [poperState, setPoperState] = useState(false)
    const [currentCommentsSelections, setCurrentCommentSelections] = useState([]);
    const [type, setType] = useState([
        {
            name: "Call Out",
            value: 1,
            id: 1,
            status: false

        },
        {
            name: "Nightstop",
            value: 2,
            id: 2,
            status: false
        },
        {
            name: "Transit",
            value: 3,
            id: 3,
            status: false
        },
    ])

    const [selectedType, setSelectedType] = useState([]);




    const ITEM_HEIGHT = 78;

    // get current logged in userbase


    const handlepopupclose = () => {
        setAction("")
        setPoperState(false)
    }

    useEffect(() => {
        if (userDetails?.base_station !== undefined) {
            setSelectedBase(userDetails?.base_station?.id)
        }
    }, [])

    const slideValue = [
        {
            value: 0,
            label: '24hrs',
        },
        {
            value: 50,
            label: '48hrs',
        },

        {
            value: 100,
            label: '1-Week',
        },
    ];




    function valuetext(value) {
        if (value === 0) {
            setValueSlide(24)
        }
        if (value === 50) {
            setValueSlide(48)
        }
        if (value === 100) {
            setValueSlide(168)
        }
        return `${value}`;
    }

    function container() {
        // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
        return document.fullscreenElement ?? document.body;
    }



    //Line customer api
    const getCustomerList = async () => {
        await LineCustomerService.getLineCustomers()
            .then((res) => {

                let data = res.line_customers
                if (data.length > 0) {
                    let value = data.map((item) => ({
                        ...item,
                        status: false
                    }))
                    setCustomerDetails(value);
                }
                else {

                    setCustomerDetails(res.line_customers);

                }



            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });

            });
    };
    const getBaseStation = async () => {
        const params = {
            organisation_id: getOrganisationId(),
            order_by: 'station'
        }
        MaintenanceServices.getBaseStation(params)
            .then((res) => {
                if (res) {
                    setBases(res.data);
                }

            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });

            });
    };
    useEffect(() => {
        getCustomerList()
        getBaseStation()
        getApprovalTypes()
        getEventLists()
    }, [])
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setCustButton(!custButton)
    };
    const handleClose = () => {
        setAnchorEl(null);
        setCustButton(!custButton)
    };
    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
        setTypeButton(!typeButton)
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
        setTypeButton(!typeButton)
    };




    const filterCustomerChangeHandler = (e) => {
        const { name, value, checked } = e.target
        // console.log("customer======", value, checked)

        let value1 = customerDetails.map((item) => {

            if (item.id === parseInt(value)) {
                item.status = !item.status
            }
            return item
        })

        setSelectedFilters((prevState) => [...prevState, value])
        if (!checked) {
            setSelectedFilters((current) => _without(current, value));
        }

    }
    const filterTypeChangeHandler = (e) => {
        const { name, value, checked } = e.target

        let value1 = type.map((item) => {

            if (item.id === parseInt(value)) {

                item.status = !item.status
            }
            return item
        })

        // setType(type)
        setSelectedType((prevState) => [...prevState, value])
        if (!checked) {
            setSelectedType((current) => _without(current, value));
        }

    }
    // console.log("zoom=====", window.innerHeight)

    // Api Integration

    const getStationBoardDetails = async () => {
        // setLoading(true)
        const param = {
            page: page + 1,
            limit: rowsPerPage,
            date_range: slideSelectedValue > 0 ? slideSelectedValue : 24,
            organisation_id: getOrganisationId(),
            ...filterData

        }
        selectedFilters.forEach((item, index) => param[`customer[${[index]}]`] = item)
        selectedType.forEach((item, index) => param[`lm_event_type_id[${[index]}]`] = item)
        await LineMaintainenceServices.getStationBoardEventList(param).then((res) => {
            setEventDetails(res.data)
            setTotalCount(res.total_count)
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getStationBoardDetails()
    }, [page, rowsPerPage, slideSelectedValue, filterData, selectedFilters, selectedType])


    const handleFilterChange = (keyItem, value) => {

        setFilterData((prevState) => ({ ...prevState, [keyItem]: value }));
        if (keyItem === "base_station_id") {
            setSelectedBase(value)
        }
    };

    const handlePageChange = (event, value) => {
        console.log("value=====", value)
        setPage(value);
    };
    const getApprovalTypes = () => {

        const params = {
            organisation_id: getOrganisationId(),

        }
        PlanningLineMaintenance.fetchPlannedAircraft(params).then((res) => {
            setApprovalTypes(res.data)
        }).catch((err) => {

        })

    }
    const getEventLists = () => {
        const params = {
            organisation_id: getOrganisationId(),

        }
        PlanningLineMaintenance.fetchEventTypes(params).then((res) => {
            setEventTypes(res.data)
        }).catch((err) => {

        })
    }
    const id = useRef(null);

    const orgLogo = sessionStorage.getItem('logo') || "/mro-pro-logo.png";

    useEffect(() => {
        document.addEventListener('fullscreenchange', exitHandler);
        document.addEventListener('webkitfullscreenchange', exitHandler);
        document.addEventListener('mozfullscreenchange', exitHandler);
        document.addEventListener('MSFullscreenChange', exitHandler);

        function exitHandler() {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                clearInterval(id.current)
                setPage(0)
                // getStationBoardDetails()
                setFullScreenState(false)
                setAnchorEl(null)
                setAnchorEl1(null)
                setCustButton(!custButton)
                setTypeButton(!typeButton)
                setLiveDate('')
            }
        }
    }, [fullScreenState])

    const editFullScreen = () => {
        // document.addEventListener('fullscreenchange', exitHandler);
        // document.addEventListener('webkitfullscreenchange', exitHandler);
        // document.addEventListener('mozfullscreenchange', exitHandler);
        // document.addEventListener('MSFullscreenChange', exitHandler);

        // function exitHandler() {
        //     if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        //         clearInterval(id.current)
        //         setPage(0)
        //         getStationBoardDetails()
        //         setFullScreenState(false)
        //     }
        // }

    }

    const handleInterval = (i) => {
        let inv = 10000;
        if (action === "" || null) {
            inv = 10000
        }
        else {
            inv = 30000
        }
        return new Promise((resolve, reject) => {
            id.current = setInterval(() => resolve(i), inv)
        })
    }

    const handleFunc = async () => {
        // const root = document.getElementById('grid1')
        // root.onclick = () => document.body.webkitRequestFullscreen()
        setFullScreenState(true)
        let i = 1
        while (i != Math.ceil(totalCount / 10)) {
            let result = await handleInterval(i)
            i++
            setPage(result)
            if (
                (result === 1 && totalCount <= 20) ||
                (result === 2 && totalCount <= 30) ||
                (result === 3 && totalCount <= 40) ||
                (result === 4 && totalCount <= 50) ||
                (result === 5 && totalCount <= 60)
            ) {
                i = 0; continue;
            }
        }
    }



    const handleChangePage = (page) => {
        if (fullScreenState) {
            console.log("do nothing")
        } else {
            setPage(page)
        }
    }

    useEffect(() => {
        if (fullScreenState && totalCount <= 10) {
            const intervalCall = setInterval(() => {
                getStationBoardDetails();
            }, 10000);
            return () => {
                // clean up
                clearInterval(intervalCall);
            };
        }
    }, [fullScreenState]);
    useEffect(() => {
        if (fullScreenState) {


            const intervalCall1 = setInterval(() => {
                let today = new Date()
                let format = "HH:mm"
                let today1 = moment.utc(today).format(format)
                setLiveDate(today1);
            }, 10000);

            return () => {
                // clean up
                clearInterval(intervalCall1);
            };
        }

    }, [fullScreenState]);



    const handleLiveTime = () => {

        let today = new Date()
        let format = "HH:mm"
        let today1 = moment.utc(today).format(format)
        setLiveDate(today1)
        return today1 ? today1 : ""

    }





    const handleColor = (event) => {
        let today = new Date()
        let format = "YYYY-MM-DD HH:mm:ss"
        let today1 = moment.utc(today).format(format)

        if (event.lm_event_type_id === 2 && event.act_dep_datetime !== null) {

            let date = moment(event.act_dep_datetime, format).isBefore(today1)
            return date
        }
        else if (event.lm_event_type_id === 2 && event.act_dep_datetime === null) {
            return false
        }
        else if (event.act_dep_datetime !== null) {

            let date = moment(event.act_dep_datetime, format).isBefore(today1)

            return date
        }
        else if (event.est_dep_datetime !== null) {

            let date = moment(event.est_dep_datetime, format).isBefore(today1)
            return date

        }







    }


    const handleNotCalledout = (id) => {

        const formData = new FormData()
        formData.append("event_id", id)
        formData.append("not_called_out", false)
        setLoading(true)
        LineMaintainenceServices.updateNotCallOutStatus(formData).then((res) => {
            if (res.success) {
                message.showToastMessage({
                    message: " Call Out updated successfully !",
                    variant: "success",
                });
                getStationBoardDetails()
            }
        }).catch((res) => {

            message.showToastMessage({
                message: res.errors ? res.errors : "Something went wrong. Try again!",
                variant: "error",
            });
            getStationBoardDetails()
        }).finally(() => {
            setLoading(false)
        })

    }









    return (


        <div>
            {loading && _.isEmpty(eventDetails) ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="700px"
                >
                    <CircularProgress color="primary" size={40} />
                </Box>
            ) : (
                <>
                    <SearchBarWithFilters noSearch>
                        <FormControl size="medium" className={"filter"}>
                            <Select
                                fullWidth
                                displayEmpty
                                onChange={(e) => handleFilterChange("base_station_id", e.target.value)}
                                id="tags-standard"
                                value={selectedBase ? selectedBase : ''}
                                name="Base Station"
                                defaultValue={selectedBase}
                                input={<InputBase />}
                            >
                                <MenuItem value={""}>Base (All)</MenuItem>
                                {bases && bases.map((value) => (
                                    <MenuItem value={value.id} key={value.id}> {value.station} </MenuItem>
                                ))}

                            </Select>
                        </FormControl>

                        <FormControl style={{ marginRight: '10px' }}>
                            <Button variant={"text"} size="small" aria-haspopup="true" onClick={handleClick} endIcon={custButton ? <ArrowDropUp /> : <ArrowDown />} style={{ fontSize: "14px", backgroundColor: anchorEl ? '#efefef' : "" }} >
                                {selectedFilters.length > 0 ? `Customer(${selectedFilters.length})` : 'Customer(All)'}
                            </Button>
                            <Menu
                                id="Customer"
                                anchorEl={anchorEl}
                                keepMounted
                                open={anchorEl}
                                onClose={handleClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                transformOrigin={{ vertical: "top", horizontal: "left" }}
                                style={{ marginTop: '41px' }}
                                TransitionProps={false}
                                PaperProps={{
                                    style: {
                                        maxHeight: ITEM_HEIGHT * 4.2,
                                        width: '39ch',
                                        position: 'fixed',
                                        top: '260px',

                                    },
                                }}
                            >
                                {/* <MenuItem value="" style={{ marginLeft: '7px' }}>Customer(All)</MenuItem> */}
                                {customerDetails?.map((option, index) => {
                                    return <Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={9} style={{
                                                display: "flex", alignItems: "center", fontSize: "14px",

                                            }}>
                                                <label>{option.name}</label>
                                            </Grid>
                                            <Grid item xs={2} style={{ marginLeft: '-23px' }}>
                                                <FormControlLabel

                                                    control={<PositiveSwitch
                                                        checked={option.status}
                                                        value={option.id}
                                                        onChange={(e) => { filterCustomerChangeHandler(e) }}
                                                        name={option.name} />}
                                                />
                                            </Grid>

                                        </Grid>
                                        <Grid><Divider variant="middle" /></Grid>
                                    </Box>
                                })}
                            </Menu>
                        </FormControl>

                        <FormControl style={{ marginRight: '10px' }}>
                            <Button variant={"text"} size="small" aria-haspopup="true" onClick={handleClick1} endIcon={typeButton ? <ArrowDropUp /> : <ArrowDown />} style={{ fontSize: "14px", backgroundColor: anchorEl1 ? '#efefef' : "" }} >
                                {selectedType.length > 0 ? `Type(${selectedType.length})` : 'Type(All)'}
                            </Button>
                            <Menu
                                id="Type"
                                anchorEl={anchorEl1}
                                keepMounted
                                open={anchorEl1}
                                onClose={handleClose1}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                transformOrigin={{ vertical: "top", horizontal: "left" }}
                                style={{ marginTop: '41px' }}
                                TransitionProps={false}
                                PaperProps={{
                                    style: {
                                        maxHeight: ITEM_HEIGHT * 4.2,
                                        width: '39ch',
                                        position: 'fixed',
                                        top: '260px',

                                    },
                                }}
                            >
                                {/* <MenuItem value="" style={{ marginLeft: '7px' }}>Customer(All)</MenuItem> */}
                                {type?.map((option, index) => {
                                    return <Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={9} style={{
                                                display: "flex", alignItems: "center", fontSize: "14px",

                                            }}>
                                                <label>{option.name}</label>
                                            </Grid>
                                            <Grid item xs={2} style={{ marginLeft: '-23px' }}>
                                                <FormControlLabel
                                                    control={<PositiveSwitch
                                                        checked={option.status}
                                                        value={option.id}
                                                        onChange={(e) => { filterTypeChangeHandler(e) }}
                                                        name={option.name} />}
                                                />
                                            </Grid>

                                        </Grid>
                                        <Grid><Divider variant="middle" /></Grid>
                                    </Box>
                                })}
                            </Menu>
                        </FormControl>

                        <FormControl className={"filter"} style={{
                            width: '271px',
                            marginTop: ' -20px', paddingLeft: '20px'
                        }}>
                            <Typography id="discrete-slider-custom" gutterBottom style={{ fontSize: '8px', marginTop: '1px' }}>
                                Future display range
                            </Typography>
                            <Slider
                                defaultValue={slideSelectedValue}
                                getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider-custom"
                                valueLabelDisplay="off"
                                track={false}
                                step={null}
                                marks={slideValue}
                                onChange={(e) => {
                                    console.log("eeee", e.target.value)
                                }}
                            />
                        </FormControl>
                        <FormControl className={"filter"} style={{
                            float: 'right'
                        }}>
                            <IconButton onClick={handle.enter}>
                                {!fullScreenState && <Box display="flex" justifyContent="center" onClick={handleFunc}>
                                    {" "}
                                    <img
                                        src={FullScreenIcon}
                                        alt="Icon"
                                        style={{ height: "29px" }}
                                    />{" "}
                                </Box>}
                            </IconButton>
                        </FormControl>
                        <FormControl className={"filter"} style={{
                            float: 'right'
                        }}>
                            <MROButton variant='contained' color='primary' style={{ height: '30px', width: '5px', minWidth: '0px', borderRadius: '6px', marginTop: '4px' }} onClick={() => setAction('addEvents')}>
                                <Add />
                            </MROButton>

                        </FormControl>

                    </SearchBarWithFilters>
                    {/* Main content */}
                    <FullScreen handle={handle} >

                        <Grid container direction='row' className={fullScreenState && classes.wrapper} id='grid1' ref={containerRef} style={{
                            background: fullScreenState && `transparent linear-gradient(204deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%) 0% 0% no-repeat padding-box`, overflow: fullScreenState && 'scroll'
                        }}>
                            <Grid item xs={fullScreenState ? 11 : 12} style={{ marginLeft: fullScreenState && '8px' }} >


                                {fullScreenState && <Grid container item xs={12} style={{ paddingLeft: '15px', paddingBottom: '15px' }}>

                                    <Grid item xs={5} container alignContent='center' style={{}}>

                                        <Grid style={{ marginTop: "0px", marginRight: "12px" }}  >

                                            <FormControl size="medium" className={"filter"} style={{}}>

                                                <Select
                                                    fullWidth
                                                    displayEmpty
                                                    onChange={(e) => handleFilterChange("base_station_id", e.target.value)}
                                                    id="tags-standard"
                                                    value={selectedBase ? selectedBase : ''}
                                                    name="Base Station"
                                                    defaultValue={selectedBase}
                                                    input={<InputBase style={{ fontSize: '15px' }} inputProps={{
                                                        className: classes.select
                                                    }} />}
                                                    MenuProps={{
                                                        container: container
                                                    }}

                                                >
                                                    <MenuItem value={""}>Base (All)</MenuItem>
                                                    {bases && bases.map((value) => (
                                                        <MenuItem value={value.id} key={value.id}> {value.station} </MenuItem>
                                                    ))}

                                                </Select>

                                            </FormControl></Grid>
                                        <Grid>

                                            <FormControl style={{ marginRight: '10px' }}>
                                                <Button variant={"text"} size="small" aria-haspopup="true" onClick={handleClick} endIcon={custButton ? <ArrowDropUp /> : <ArrowDown />} style={{ fontSize: "22px", backgroundColor: anchorEl ? '#efefef' : "" }} >
                                                    {selectedFilters.length > 0 ? `Customer(${selectedFilters.length})` : 'Customer(All)'}
                                                </Button>
                                                <Menu
                                                    id="Customer"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    container={container}
                                                    open={anchorEl}
                                                    onClose={handleClose}
                                                    getContentAnchorEl={null}
                                                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                                                    style={{ marginTop: '41px' }}
                                                    TransitionProps={false}
                                                    PaperProps={{
                                                        style: {
                                                            maxHeight: ITEM_HEIGHT * 4.2,
                                                            width: '39ch',
                                                            position: 'fixed',
                                                            top: '260px',

                                                        },
                                                    }}
                                                >
                                                    {/* <MenuItem value="" style={{ marginLeft: '7px' }}>Customer(All)</MenuItem> */}
                                                    {customerDetails?.map((option, index) => {
                                                        return <Box>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={1}></Grid>
                                                                <Grid item xs={9} style={{
                                                                    display: "flex", alignItems: "center", fontSize: "14px",

                                                                }}>
                                                                    <label>{option.name}</label>
                                                                </Grid>
                                                                <Grid item xs={2} style={{ marginLeft: '-23px' }}>
                                                                    <FormControlLabel

                                                                        control={<PositiveSwitch
                                                                            checked={option.status}
                                                                            value={option.id}
                                                                            onChange={(e) => { filterCustomerChangeHandler(e) }}
                                                                            name={option.name} />}
                                                                    />
                                                                </Grid>

                                                            </Grid>
                                                            <Grid><Divider variant="middle" /></Grid>
                                                        </Box>
                                                    })}
                                                </Menu>
                                            </FormControl>

                                        </Grid>
                                        <Grid>

                                            <FormControl style={{ marginRight: '10px' }}>
                                                <Button variant={"text"} size="small" aria-haspopup="true" onClick={handleClick1} endIcon={typeButton ? <ArrowDropUp /> : <ArrowDown />} style={{ fontSize: "22px", backgroundColor: anchorEl1 ? '#efefef' : "" }} >
                                                    {selectedType.length > 0 ? `Type(${selectedType.length})` : 'Type(All)'}
                                                </Button>
                                                <Menu
                                                    id="Type"
                                                    anchorEl={anchorEl1}
                                                    keepMounted
                                                    container={container}
                                                    open={anchorEl1}
                                                    onClose={handleClose1}
                                                    getContentAnchorEl={null}
                                                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                                                    style={{ marginTop: '41px' }}
                                                    TransitionProps={false}
                                                    PaperProps={{
                                                        style: {
                                                            maxHeight: ITEM_HEIGHT * 4.2,
                                                            width: '39ch',
                                                            position: 'fixed',
                                                            top: '260px',

                                                        },
                                                    }}
                                                >
                                                    {/* <MenuItem value="" style={{ marginLeft: '7px' }}>Customer(All)</MenuItem> */}
                                                    {type?.map((option, index) => {
                                                        return <Box>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={1}></Grid>
                                                                <Grid item xs={9} style={{
                                                                    display: "flex", alignItems: "center", fontSize: "14px",

                                                                }}>
                                                                    <label>{option.name}</label>
                                                                </Grid>
                                                                <Grid item xs={2} style={{ marginLeft: '-23px' }}>
                                                                    <FormControlLabel
                                                                        control={<PositiveSwitch
                                                                            checked={option.status}
                                                                            value={option.id}
                                                                            onChange={(e) => { filterTypeChangeHandler(e) }}
                                                                            name={option.name} />}
                                                                    />
                                                                </Grid>

                                                            </Grid>
                                                            <Grid><Divider variant="middle" /></Grid>
                                                        </Box>
                                                    })}
                                                </Menu>
                                            </FormControl>


                                        </Grid>
                                        <Grid style={{ marginTop: " 6px", marginLeft: "11px" }}>

                                            <FormControl className={"filter"} style={{
                                                float: 'right'
                                            }}>
                                                <MROButton variant='contained' color='primary' style={{ height: '30px', width: '5px', minWidth: '0px', borderRadius: '6px', marginTop: '4px' }} onClick={() => setAction('addEvents')}>
                                                    <Add />
                                                </MROButton>

                                            </FormControl>
                                        </Grid>


                                    </Grid>
                                    <Grid item xs={5}><a href="">
                                        <img src={orgLogo} style={{
                                            maxWidth: '300px',
                                            maxHeight: '185px'
                                        }} />
                                    </a></Grid>
                                    <Grid item xs={2} container alignContent='center' style={{ justifyContent: 'end' }}  >

                                        <Typography style={{ fontSize: '28px', color: "#fff" }}>
                                            {liveDate ? liveDate : handleLiveTime()}{" "} UTC
                                        </Typography>

                                    </Grid>

                                </Grid>}

                                {/* <Typography>Window height {window.innerHeight} {window.innerWidth}</Typography> */}

                                <Grid style={{ zoom: fullScreenState ? window.innerHeight === 1050 ? "75%" : window.innerHeight === 1024 ? "75%" : window.innerHeight === 1080 ? "80%" : window.innerHeight === 1200 && window.innerWidth === 1600 ? '78%' : window.innerHeight === 1200 ? "90%" : window.innerWidth == 1920 && window.innerHeight === 1440 ? '95%' : window.innerHeight < 900 ? "50%" : window.innerWidth == 1470 && window.innerHeight === 956 ? '85%' : '90%' : '' }}>
                                    {eventDetails.map((event, index) => (
                                        <>

                                            <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} style={{ background: handleColor(event) ? fullScreenState ? "#FCC6C9" : alpha("#FF0202", 0.20) : "" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon style={{ fontSize: '34px' }} />}
                                                    aria-controls="aircraft-content"
                                                    id="aircraft-header"
                                                    classes={{ root: classes.root }}
                                                >
                                                    <Grid container direction='row' xs={12} style={{ flexWrap: 'nowrap' }}>
                                                        <Grid style={{ paddingRight: '5px' }}><img src={ArrowDownwardIcon} height={fullScreenState ? '57px' : '40px'} /></Grid>
                                                        <Grid container direction='column'>
                                                            <Grid className={classes.firstRow} style={{ fontSize: fullScreenState && "28px" }}>{formatToMins(event.arr_datetime)}</Grid>
                                                            <Grid className={classes.secondRowText} style={{ fontSize: fullScreenState && "17px" }}>{format24HrDateOnly(event.arr_datetime)}</Grid>
                                                        </Grid>
                                                        <Grid container direction='column' >
                                                            <Grid className={classes.firstRow} style={{ fontSize: fullScreenState && "28px" }}>{event.base}</Grid>
                                                            <Grid className={classes.secondRowText} style={{ fontSize: fullScreenState && "17px" }}>Airport</Grid>
                                                        </Grid>
                                                        <Grid container direction='column'  >
                                                            <Grid className={classes.firstRow} style={{ fontSize: fullScreenState && "28px" }}>{event.arr_flight_number}</Grid>
                                                            <Grid className={classes.secondRowText} style={{ fontSize: fullScreenState && "17px" }}>{event.airline}</Grid>
                                                        </Grid>
                                                        <Grid container direction='column'  >
                                                            <Grid className={classes.firstRow} style={{ fontSize: fullScreenState && "28px" }}>{event.aircraft || "-"}</Grid>
                                                            <Grid className={classes.secondRowText} style={{ fontSize: fullScreenState && "17px" }}>Aircraft</Grid>
                                                        </Grid>
                                                        <Grid container direction='column' >
                                                            <Grid className={classes.firstRow} style={{ fontSize: fullScreenState && "28px" }}>{event?.ac_reg || "-"}</Grid>
                                                            <Grid className={classes.secondRowText} style={{ fontSize: fullScreenState && "17px" }}>A/C Reg</Grid>
                                                        </Grid>
                                                        {event.flight_status_color ?
                                                            <Grid style={{
                                                                border: event.flight_status_color === "green" ? '4px solid #4FC605' : event.flight_status_color === "amber" ? '4px solid #FFB300' : "4px solid #FF0202", borderRadius: '5px', height: fullScreenState ? "61px" : '45px'
                                                            }}>

                                                            </Grid>
                                                            : ''}

                                                        <Grid container direction='column' style={{ minWidth: '125px', paddingLeft: '11px' }} >
                                                            <Grid className={classes.firstRow} style={{ fontSize: fullScreenState && "28px" }}>{event.flight_status === "est" ? "Est" : event.flight_status === "arr" ? "Arr" : "No Data"}{" "}{event.flight_status_eta}</Grid>
                                                            <Grid className={classes.secondRowText} style={{ fontSize: fullScreenState && "17px" }}>Status {
                                                                event.fr_updated_at ? `@ ` + moment(event.fr_updated_at, "DD/MM/YYYY HH:mm").format("HH:mm") : ''}</Grid>
                                                        </Grid>
                                                        {/* <Grid container direction='column'  >
                                                    <Grid className={classes.firstRow}>{event.gate
                                                        || "-"}</Grid>
                                                    <Grid className={classes.secondRowText}>Gate</Grid>
                                                </Grid> */}
                                                        <Grid container direction='column' >
                                                            <Grid className={classes.firstRow} style={{ fontSize: fullScreenState && "28px" }}>{event.type || "-"}</Grid>
                                                            <Grid className={classes.secondRowText} style={{ fontSize: fullScreenState && "17px" }}>Type</Grid>
                                                        </Grid>
                                                        <Grid container direction='column' style={{ minWidth: '115px' }}>
                                                            <Grid className={classes.firstRow} style={{ fontSize: fullScreenState && "28px" }}>{event.planned_gnd_time === "Nightstop" ? event.planned_gnd_time : parseFloat(event.planned_gnd_time).toFixed(2)}
                                                            </Grid>
                                                            <Grid className={classes.secondRowText} style={{ fontSize: fullScreenState && "17px" }}>Planned Gnd Time</Grid>
                                                        </Grid>
                                                        <Grid container direction='column' >
                                                            <Grid className={classes.firstRow} style={{ fontSize: fullScreenState && "28px" }}> {event?.scope === false ? <ClearIcon style={{ fontSize: fullScreenState ? "31px" : '26px' }} /> : event.scope === true ? <CheckedIcon style={{ fontSize: fullScreenState ? "31px" : '26px' }} /> : event.scope === "amber" ? <img src={AmberIcon} style={{ height: fullScreenState ? "27px" : '20px', marginBottom: '12px', marginLeft: '4px' }} /> : <Grid style={{ paddingBottom: '7px' }}> -</Grid>}</Grid>
                                                            <Grid className={classes.secondRowText} style={{ marginTop: '-7px', fontSize: fullScreenState && "17px" }}>Scope</Grid>
                                                        </Grid>
                                                        <Grid container direction='column'  >
                                                            <Grid className={classes.firstRow} style={{ fontSize: fullScreenState && "28px" }}> {event?.notes === false ? <ClearIcon style={{ fontSize: fullScreenState ? "31px" : '26px' }} /> : event.notes === true ? <CheckedIcon style={{ fontSize: fullScreenState ? "31px" : '26px' }} /> : event.notes === "amber" ? <img src={AmberIcon} style={{ height: fullScreenState ? "27px" : '20px', marginBottom: '12px', marginLeft: '2px' }} /> : <Grid style={{ paddingBottom: '7px' }}> -</Grid>}</Grid>
                                                            <Grid className={classes.secondRowText} style={{ marginTop: '-7px', fontSize: fullScreenState && "17px" }}>Notes</Grid>
                                                        </Grid>
                                                        <Grid container direction='column'  >
                                                            <Grid className={classes.firstRow} style={{ fontSize: fullScreenState && "28px" }}>{event.work_order_number ? <a style={{ textTransform: 'underline' }} onClick={() => {
                                                                setCurrentSelection(event)
                                                                setAction('view')
                                                            }}>{event.work_order_number}</a> : "-"}
                                                            </Grid>
                                                            <Grid className={classes.secondRowText} style={{ fontSize: fullScreenState && "17px" }}>Work Order #</Grid>
                                                        </Grid>
                                                        <Grid container direction='column' style={{ minWidth: '180px' }}>
                                                            <Grid className={classes.firstRow} style={{ fontSize: fullScreenState && "28px" }}>{event?.allocated_engineers.length > 2 ? event?.allocated_engineers.slice(0, 2).map((item, index) => {
                                                                return (
                                                                    <span onClick={(e) => {
                                                                        setAction("view_key");
                                                                        setCurrentCommentSelections(event?.allocated_engineers);
                                                                        setPoperState(!poperState)
                                                                        setMouseX(e.clientX);
                                                                        setMouseY(e.clientY);
                                                                    }}
                                                                    >{item.stamp_number}{index === 0 ? ", " : ""} </span>

                                                                )
                                                            }


                                                            ) : event?.allocated_engineers.length > 0 && event?.allocated_engineers.length <= 2 ? event?.allocated_engineers.map((item, index) => {
                                                                return (
                                                                    <span>{item.stamp_number}{index < event?.allocated_engineers.length - 1 ? ", " : ""}</span>

                                                                )
                                                            }) : "-"}{event?.allocated_engineers.length > 2 ? "..." : ''}

                                                                {action === "view_key" && <Popover id='key' open={Boolean(poperState)}
                                                                    anchorReference={'anchorPosition'}
                                                                    onClose={handlepopupclose}
                                                                    anchorEl={poperState}
                                                                    container={containerRef.current}
                                                                    anchorOrigin={{
                                                                        vertical: "top",
                                                                        horizontal: "left",
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: "top",
                                                                        horizontal: "left",
                                                                    }}
                                                                    anchorPosition={{ top: mouseY, left: mouseX }}
                                                                    PaperProps={{
                                                                        marginLeft: '-11px'
                                                                    }}
                                                                >

                                                                    <Paper style={{ width: 'max-content', height: 'fit-content', padding: '10px' }}>
                                                                        <Typography style={{ fontSize: '13px' }}>{currentCommentsSelections.map((item, index) => {
                                                                            return (
                                                                                <span>{item.stamp_number}{index < currentCommentsSelections.length - 1 ? ", " : ""}</span>

                                                                            )
                                                                        }


                                                                        )}
                                                                        </Typography>


                                                                    </Paper>
                                                                </Popover>}


                                                            </Grid>
                                                            <Grid className={classes.secondRowText} style={{ fontSize: fullScreenState && "17px" }}>Allocated Engineers</Grid>
                                                        </Grid>
                                                        <Grid><img src={ArrowUpwardIcon} height={fullScreenState ? '57px' : '40px'} /></Grid>
                                                        <Grid container direction='column' style={{ paddingLeft: '5px' }}>
                                                            <Grid className={classes.firstRow} style={{ fontSize: fullScreenState && "28px" }}>{event.lm_event_type_id === 2 && event.act_dep_datetime !== null ? formatToMins(event.act_dep_datetime) : event.lm_event_type_id === 2 && event.act_dep_datetime === null ? '-' : event.act_dep_datetime !== null ? formatToMins(event.act_dep_datetime) : event.est_dep_datetime ? formatToMins(event.est_dep_datetime) : '-'}</Grid>
                                                            <Grid className={classes.secondRowText} style={{ fontSize: fullScreenState && "17px" }}>{event.lm_event_type_id === 2 && event.act_dep_datetime !== null ? format24HrDateOnly(event.act_dep_datetime) : event.lm_event_type_id === 2 && event.act_dep_datetime === null ? '-' : event.act_dep_datetime !== null ? format24HrDateOnly(event.act_dep_datetime) : event.est_dep_datetime ? format24HrDateOnly(event.est_dep_datetime) : '-'}</Grid>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container xs={12} >
                                                        <Grid item xs={11} container>
                                                            <Grid item xs={12} container direction='row'>
                                                                <Grid item xs={1} className={classes.accordianDetails}>
                                                                    <Typography className={classes.detailtext} style={{ fontSize: fullScreenState && "19px" }}>Planned Type</Typography>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    <Typography className={classes.plannedText} style={{ fontSize: fullScreenState && "22px" }}>{event?.approval_type_name || "-"}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} container direction='row'>
                                                                <Grid item xs={1} className={classes.accordianDetails}>
                                                                    <Typography className={classes.detailtext} style={{ fontSize: fullScreenState && "19px" }}>Departure Flight #</Typography>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    <Typography className={classes.plannedText} style={{ fontSize: fullScreenState && "22px" }}>{event?.dep_flight_number || "-"}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} container direction='row'>
                                                                <Grid item xs={1} className={classes.accordianDetails}>
                                                                    <Typography className={classes.detailtext} style={{ fontSize: fullScreenState && "19px" }}>Alternate Types</Typography>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    <Typography className={classes.plannedText} style={{ fontSize: fullScreenState && "22px" }}>{event?.planned_aircraft.toString() || "-"}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} container direction='row'>
                                                                <Grid item xs={1} className={classes.accordianDetails}>
                                                                    <Typography className={classes.detailtext} style={{ fontSize: fullScreenState && "19px" }}>Scope</Typography>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    <Typography className={classes.plannedText} style={{ fontSize: fullScreenState && "22px" }}>{event?.scopes.toString() || "-"}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} container direction='row'>
                                                                <Grid item xs={1} className={classes.accordianDetails}>
                                                                    <Typography className={classes.detailtext} style={{ fontSize: fullScreenState && "19px" }}>Resource</Typography>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    <Typography className={classes.plannedText} style={{ fontSize: fullScreenState && "22px" }}>{event.lae_quantity || 0}
                                                                        LAEs, {event.mech_quantity || 0} Mechs, {event.total_man_hours || 0} Planned man-hours</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} container direction='row'>
                                                                <Grid item xs={1} className={classes.accordianDetails}>
                                                                    <Typography className={classes.detailtext} style={{ fontSize: fullScreenState && "19px" }}>Engineers</Typography>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    <Typography className={classes.plannedText} style={{ fontSize: fullScreenState && "22px" }}>{event?.allocated_engineers.length > 0 ? event?.allocated_engineers.map((item, index) => {
                                                                        return (
                                                                            <span>{item.stamp_number}{index < event?.allocated_engineers.length - 1 ? ", " : ""}</span>

                                                                        )
                                                                    }


                                                                    ) : '-'}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} container direction='row'>
                                                                <Grid item xs={1} className={classes.accordianDetails}>
                                                                    <Typography className={classes.detailtext} style={{ fontSize: fullScreenState && "19px" }}>Notes</Typography>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    <Typography className={classes.plannedText} style={{ fontSize: fullScreenState && "22px" }}>{event?.notes_list
                                                                        .toString() || "-"}</Typography>
                                                                </Grid>
                                                            </Grid>

                                                            {/* Events Actionssssss */}

                                                        </Grid>
                                                        <Grid item xs={1} >
                                                            <Grid className={classes.iconLayout} >
                                                                <Grid item xs={12} container style={{ paddingTop: '10px', height: '56px' }}>
                                                                    {/* Add Engineers */}
                                                                    <Grid item xs={6} >
                                                                        <Grid item xs={12} style={{ paddingLeft: '15px' }} container={containerRef.current}>
                                                                            <Tooltip onOpen={() => setShowText('add')} placement="bottom" onClose={() => { setShowText(null) }} open={false}>
                                                                                <IconButton onClick={() => {
                                                                                    setCurrentSelection(event)
                                                                                    setAction('allocate')
                                                                                }}>
                                                                                    <img src={AddIcon} height={'18px'} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                        <Grid item xs={12} style={{ paddingLeft: '5px' }}>
                                                                            {showText === 'add' ? <span className={classes.tooltipText}> Add Engineers</span> : ' '}</Grid>
                                                                    </Grid>
                                                                    {/* No call out events */}
                                                                    {event.lm_event_type_id === 1 && event.work_order_id === null && !event.not_called_out &&

                                                                        <Grid item xs={6}>
                                                                            <Grid item xs={12} style={{ paddingLeft: '5px' }}>
                                                                                <Tooltip onOpen={() => setShowText('nocall')} placement="bottom" onClose={() => { setShowText(null) }} open={false}>
                                                                                    <IconButton onClick={() => {
                                                                                        setCurrentSelection(event)
                                                                                        setAction('nocallout')
                                                                                    }}>
                                                                                        <img src={EndCall} height={'20px'} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                {showText === 'nocall' ? <span className={classes.tooltipText}>No Call Out</span> : ' '}</Grid>
                                                                        </Grid>}
                                                                    {event.lm_event_type_id === 1 && event.not_called_out &&

                                                                        <Grid item xs={6}>
                                                                            <Grid item xs={12} style={{ paddingLeft: '5px' }}>
                                                                                <Tooltip onOpen={() => setShowText('nocall')} placement="bottom" onClose={() => { setShowText(null) }} open={false}>
                                                                                    <IconButton onClick={() => {
                                                                                        handleNotCalledout(event.id)
                                                                                    }}>
                                                                                        <img src={EndCallGrey} height={'20px'} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                {showText === 'nocall' ? <span className={classes.tooltipText}>No Call Out</span> : ' '}</Grid>
                                                                        </Grid>}
                                                                </Grid>
                                                                <Grid item xs={12} container style={{ paddingTop: '10px', height: '62px' }}>

                                                                    {/* Edt planning */}
                                                                    <Grid item xs={6} >
                                                                        <Grid item xs={12} style={{ paddingLeft: '15px' }}>
                                                                            <Tooltip onOpen={() => setShowText('editplanning')} placement="bottom" onClose={() => { setShowText(null) }} open={false}>
                                                                                <IconButton onClick={() => {
                                                                                    if (fullScreenState) {
                                                                                        editFullScreen()
                                                                                    }
                                                                                    setCurrentSelection(event)
                                                                                    setAction('editlmevents')
                                                                                }}>
                                                                                    <img src={PlanningIconGrey} height={'20px'} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                        <Grid item xs={12} style={{ paddingLeft: '5px' }}>
                                                                            {showText === 'editplanning' ? <span className={classes.tooltipText}>Edit Planning</span> : ' '}</Grid>
                                                                    </Grid>
                                                                    {/* Cancel eventssss */}
                                                                    {event.work_order_id === null && <Grid item xs={6}>
                                                                        <Grid item xs={12} style={{ paddingLeft: '5px' }}>
                                                                            <Tooltip onOpen={() => setShowText('cancel')} placement="bottom" onClose={() => { setShowText(null) }} open={false}>
                                                                                <IconButton onClick={() => {
                                                                                    setCurrentSelection(event)
                                                                                    setAction('cancelevent')
                                                                                }}>
                                                                                    <img src={RedCrossIcon} height={'20px'} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            {showText === 'cancel' ? <span className={classes.tooltipText}>Cancel Event</span> : ''}</Grid>
                                                                    </Grid>}

                                                                </Grid>
                                                                {event.work_order_id === null && <Grid item xs={6} style={{ paddingBottom: '10px' }}>
                                                                    <Grid item xs={12} style={{ paddingLeft: '15px' }}>
                                                                        <Tooltip onOpen={() => setShowText('link')} placement="bottom" onClose={() => { setShowText(null) }} open={false}>
                                                                            <IconButton onClick={() => {
                                                                                setCurrentSelection(event)
                                                                                setAction('link')
                                                                            }}>
                                                                                <img src={LinkIcon} height={'20px'} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                    <Grid item xs={12} style={{ paddingLeft: '10px' }}>
                                                                        {showText === 'link' ? <span className={classes.tooltipText}>Link WO#</span> : ''}</Grid>
                                                                </Grid>}
                                                            </Grid>
                                                            <Grid className={classes.eventText}>Event Actions</Grid>
                                                        </Grid>

                                                    </Grid>
                                                </AccordionDetails>

                                            </Accordion >
                                            {/* {(index === 1 && page === 0) && <Typography style={{ borderBottom: '2px solid #767676', marginTop: '10px' }}></Typography>} */}
                                            <br />
                                        </>
                                    ))}
                                </Grid>
                            </Grid>
                            {fullScreenState && <Grid direction='column' xs={0.5} justifyContent='center' style={{ paddingLeft: '46px', marginTop: '101px' }}>
                                <Typography style={{ color: '#fff', paddingBottom: '5px', fontSize: '25px' }}>Page#</Typography>
                                <ButtonGroup orientation="vertical"  >

                                    <Button variant='contained' className={classes.pageButton} style={{ background: page === 0 ? "#4FC605" : '#fff' }} onClick={() => {
                                        setPage(0)
                                    }}>1</Button>
                                    {totalCount > 10 && <Button variant='contained' className={classes.pageButton} style={{ background: page === 1 ? "#4FC605" : '#fff' }} onClick={() => {
                                        setPage(1)
                                    }}>2</Button>}
                                    {totalCount > 20 && <Button className={classes.pageButton} variant='contained' style={{ background: page === 2 ? "#4FC605" : '#fff' }} onClick={() => {
                                        setPage(2)
                                    }}>3</Button>}
                                    {totalCount > 30 && <Button variant='contained' className={classes.pageButton} style={{ background: page === 3 ? "#4FC605" : '#fff' }} onClick={() => {
                                        setPage(3)
                                    }}>4</Button>}
                                    {totalCount > 40 && <Button variant='contained' className={classes.pageButton} style={{ background: page === 4 ? "#4FC605" : '#fff' }} onClick={() => {
                                        setPage(4)
                                    }}>5</Button>}

                                </ButtonGroup>
                            </Grid>}

                        </Grid>



                        {/* <br /> */}

                        {!fullScreenState && <Grid>
                            <CustomPaginationActionsTable rowPerPageOptions={[10, 20, 50, 100]} count={totalCount} label={'Rows:'} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} fullScreenState={fullScreenState} />
                        </Grid>}



                        {action && action === 'view' && <DetailsPopUp handleClose={() => {
                            setAction(null)
                            getStationBoardDetails()
                        }} currentSelection={currentSelection} />}

                        {action && action === 'allocate' && <AllocateEngineers ref={containerRef.ref} handleClose={() => {
                            setAction(null)
                            getStationBoardDetails()

                        }}

                            currentSelection={currentSelection} />}
                        {action && action === 'nocallout' && <CalledOut handleClose={() => {
                            setAction(null)
                            getStationBoardDetails()

                        }}

                            currentSelection={currentSelection} />}
                        {action && action === 'cancelevent' && <CancelEvent handleClose={() => {
                            setAction(null)
                            getStationBoardDetails()
                        }}

                            currentSelection={currentSelection}
                        />}
                        {action && action === 'addEvents' && <CreateLMEvents handleClose={() => {
                            setAction(null)
                            getStationBoardDetails()
                        }} customerDetails={customerDetails} baseDetails={bases} eventDetails={eventTypes} approvalTypes={approvalTypes} />}
                        {action && action === 'editlmevents' && <EditLmEvents handleClose={() => {
                            setAction(null)
                            getStationBoardDetails()

                        }} approvalTypes={approvalTypes} currentSelection={currentSelection} permission={props?.permission} />}
                        {
                            action && action === 'link' && <LinkWorkOrder handleClose={handleClose} eventId={currentSelection.id} onClose={() => {
                                setAction(null)
                                getStationBoardDetails()
                            }} />
                        }
                    </FullScreen>
                </>)

            }
        </div >

    )
}

export default StationBoard