import {
    FormControl,
    IconButton,
    InputBase,
    makeStyles,
    MenuItem,
    Menu,
    Switch,
    Select, FormControlLabel, Grid, Chip, Typography, Divider, Box, CircularProgress, Button, alpha
} from "@material-ui/core";
import StepConnector from '@material-ui/core/StepConnector';
import SearchBarWithFilters from "components/search_with_filters";
import React, { useContext, useEffect, useReducer, useState, useMemo } from "react";
import { ICON_COLOUR } from "lib/constants/style_constants";
import moment from "moment";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { WorkshopDashboardServices } from "lib/services/api/dashboard/workshop";
import TableAction from "actions/table_actions";
import tableReducer, {
    INITIAL_TABLE_STATE,
} from "reducers/table_reducer";
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { ToastMessageContext } from "lib/contexts/message_context";
import PositiveSwitch from "components/form_components/switch";
import { getOrganisationId } from "lib/utils/common_utils";
// Range picker
import { DatePicker } from "antd";
import ProgressBar from "./ProgressBar";
import { useTheme } from "@material-ui/core";
import CommericalTable from "./CommericalTable";
import { Paper } from "@material-ui/core";
import Chart from "../Maintenance/Chart";
import _without from "lodash/without";
import ChartLoader from "../Storage/ChartLoader";
import whiteSettingIcon from "../../../../assets/icons/white-settings.png";
import MROButton from "components/buttons";
const { RangePicker } = DatePicker;
const useStyles = makeStyles((theme) => ({
    root: {
        width: "95%",
        margin: "-1% 2%",
        paddingLeft: '14px',
        '&.MuiStepConnector-root': {
            borderTopWidth: '10px'
        }
    },
    stockBtn: {
        borderRadius: "7px",
        minWidth: "20px",
        height: "30px",
        color: "#fff",
        width: "70px",
    },
    formLabel: {
        marginTop: "-4.5px", marginRight: '14px', marginLeft: "-15px",
    },
    formWrapper: {
        width: "96%",
        margin: "1% 1%",

    },
    wrapper: {
        // overflow:"hidden !important",
        backgroundColor: "#fff",
        padding: '28px 8px 10px 8px',
        border: "1px solid #d5d5d5",
        margin: "1% 0",
        borderRadius: '11px'
    },
    stepper: {
        marginTop: '33px',
        // marginRight: '-14px',
        // marginLeft: '-14px',
        // zIndex: '1000',
        '& .MuiStepConnector-lineHorizontal ': {
            borderTopWidth: '10px',
            width: ' 15px',
            '@media (min-width: 1260px)': {
                width: '13px',
            },
            '@media (min-width: 1270px)': {
                width: '15px',
            },
            '@media (min-width: 1285px)': {
                width: '15px',
            },
            '@media (min-width: 1300px)': {
                width: '18px',
            },

            '@media (min-width: 1350px)': {
                width: '20px',
            },
            '@media (min-width: 1360px)': {
                width: '26px',
            },
            '@media (min-width: 1366px)': {
                width: '24px',
            },
            '@media (min-width: 1430px)': {
                width: '33px',
            },
            '@media (min-width: 1450px)': {
                width: '36px',
            },
            '@media (min-width: 1545px)': {
                width: '42px',
            },
            '@media (min-width: 1634px)': {
                width: '44px',
            },
            '@media (min-width: 1660px)': {
                width: '47px',
            },
            '@media (min-width:  1700px)': {
                width: '49px',
            },

            '@media (min-width: 1750px)': {
                width: '55px',
            },
            '@media (min-width: 1900px)': {
                width: '68px',
            },
        },

    },
    quoteText: {
        marginTop: "11px",
        fontSize: '11px',
        paddingLeft: '4px'
    },
    percentageText: {
        marginTop: ' -112px',
        marginBottom: ' 53px',
        textAlign: ' center',
        fontSize: '22px',
        color: "#4FC605"
    },

    centerLabel: {
        textAlign: ' center',
        marginTop: ' -50px',
        marginBottom: '66px',
        color: '#4D4F5C',
        fontSize: '12px'
    },
    textSecondary: {
        color: theme.palette.secondary.main
    },
    quotePercentageText: {
        marginTop: ' -112px',
        marginBottom: ' 53px',
        textAlign: ' center',
        fontSize: '22px',
        color: theme.palette.secondary.main
    },
    partsPercentage: {
        marginTop: ' -72px',
        // marginBottom: ' 53px',
        textAlign: ' center',
        fontSize: '15px',
        color: "#4FC605"
    },
    partsLabel: {
        textAlign: ' center',
        marginTop: '2px',
        marginBottom: '-2px',
        color: '#4D4F5C',
        fontSize: '10px'
    },
    formControlLabel: {
        marginBottom: 13,
        marginLeft: 0,
        display: "flex",

        "& .MuiSwitch-root": {
            marginLeft: "auto",
        },
    },
    runButton: {
        backgroundColor: "#4FC605", color: "white",
        '&:hover': {
            backgroundColor: "#4FC605",
            opacity: 0.75,
            color: "white"
        }
    }

}));


function Production(props) {
    const classes = useStyles();
    const theme = useTheme()
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const [loading, setLoading] = useState(false);
    const [filterData, setFilterData] = useState({});
    const [adhocPercentage, setAdhocPercentage] = useState(0);
    const [quotesPercentage, setQuotesPercentage] = useState(0);
    const [acceptedPercentage, setAcceptedPercentage] = useState(0);
    const [completedPercentage, setCompletedPercentage] = useState(0);
    const [partsPercentage, setPartsPercentage] = useState(0);
    const [orderOverviewData, setorderOverviewData] = useState({})
    const [customerDetails, setCustomerDetails] = useState([]);
    const message = useContext(ToastMessageContext);
    const [bases, setBases] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [selectedBase, setSelectedBase] = useState([]);
    const [checkedState, setCheckedState] = useState(new Array(bases.length).fill(false));
    const [checkedCustState, setCheckedCustState] = useState(new Array(customerDetails.length).fill(false));
    const [runTable, setRunTable] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [custButton, setCustButton] = useState(false)
    const [baseButton, setBaseButton] = useState(false)
    const [value, setValue] = useState([moment().subtract(1, 'months'), moment()])
    const [action, setAction] = useState('')

    const getOrderOverview = async () => {
        const params = {
            order_type: "Production",
            organisation_id: getOrganisationId(),
            start_date: startDate ? filterData.start_date : moment().subtract(1, 'months').format("YYYY-MM-DD"),
            end_date: endDate ? filterData.end_date : moment().format("YYYY-MM-DD")
        }
        selectedBase.forEach((item, index) => params[`base_filter[${[index]}]`] = item)
        selectedFilters.forEach((item, index) => params[`customer[${[index]}]`] = item)
        setLoading(true)
        await WorkshopDashboardServices.getOrderOverView(params).then((response) => {

            setorderOverviewData(response.data)

        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getCustomerList();
        getBaseStation();
    }, []);

    useEffect(() => {
        getOrderOverview()
    }, [filterData, selectedBase, selectedFilters])

    const handleDateChange = (value) => {
        setStartDate(true)
        setEndDate(true)
        setAction('')
        // setRunTable(false)
        setValue(value)
        if (value) {
            const start_date = moment(value[0]).format("YYYY-MM-DD");
            const end_date = moment(value[1]).format("YYYY-MM-DD");
            let dayDiff = moment(value[0]).diff(value[1], 'days')

            if (Math.abs(dayDiff) > 365) {
                setValue(null)
                setFilterData({})
                message.showToastMessage({
                    message: "Date range exceeds 1-year maximum!",
                    variant: "error",
                });

            }
            else {
                setFilterData((prevState) => ({ ...prevState, start_date, end_date }));
            }

        } else {
            const fData = { ...filterData };
            filterData?.start_date && delete fData.start_date;
            filterData?.end_date && delete fData.end_date;
            setFilterData({ ...fData });
        }
    };

    const getCustomerList = async () => {
        const params = {
            organisation_id: getOrganisationId(),
            order_by: 'name'
        }
        await MaintenanceServices.getCustomerList(params)
            .then((res) => {
                if (res) {
                    setCustomerDetails(res.data);
                }

            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });

            });
    };
    const getBaseStation = () => {
        const params = {
            organisation_id: getOrganisationId(),
            order_by: 'station'
        }
        MaintenanceServices.getBaseStation(params)
            .then((res) => {
                if (res) {
                    setBases(res.data);
                }

            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });

            });
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorBase, setAnchorBase] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setCustButton(!custButton)
    };
    const handleBaseClick = (event) => {
        setAnchorBase(event.currentTarget);
        setBaseButton(!baseButton)
    };
    const handleClose = () => {
        setAnchorEl(null);
        setCustButton(!custButton)

    };
    const filterChangeHandler = (e) => {
        const { name, value, checked } = e.target
        setAction('')
        // setRunTable(false)
        setSelectedBase((prevState) => [...prevState, value])
        if (!checked) {
            setSelectedBase((current) => _without(current, value));
        }

    }
    const filterCustomerChangeHandler = (e) => {
        const { name, value, checked } = e.target
        // console.log("customer======", value, checked)
        // setRunTable(false)
        setAction('')
        setSelectedFilters((prevState) => [...prevState, value])
        if (!checked) {
            setSelectedFilters((current) => _without(current, value));
        }

    }
    //Percentage Calculation
    //Adhoc
    useMemo(() => {
        let totalValue = orderOverviewData?.adhoc_orders + orderOverviewData?.quoted_orders
        let adhocPercentages = (orderOverviewData?.adhoc_orders / totalValue) * 100

        if (totalValue === 0) {
            setAdhocPercentage(totalValue)
        }
        else {
            setAdhocPercentage(Math.floor(adhocPercentages))
        }
    }, [orderOverviewData.adhoc_orders, orderOverviewData.quoted_orders])
    //quote
    useMemo(() => {
        let totalValue = orderOverviewData.quotes_accepted_orders + orderOverviewData.quotes_pending_orders + orderOverviewData.quotes_rejected_orders
        let quotePercentage = (orderOverviewData.quotes_accepted_orders / totalValue) * 100

        if (totalValue === 0) {
            setQuotesPercentage(totalValue)
        }
        else {
            setQuotesPercentage(Math.floor(quotePercentage))
        }
    }, [orderOverviewData.quotes_accepted_orders, orderOverviewData.quotes_pending_orders, orderOverviewData.quotes_rejected_orders])
    // accepted

    useMemo(() => {
        let totalValue = orderOverviewData.acceptance_accepted_orders + orderOverviewData.acceptance_pending_orders + orderOverviewData.acceptance_rejected_orders
        let Percentage = (orderOverviewData.acceptance_accepted_orders / totalValue) * 100

        if (totalValue === 0) {
            setAcceptedPercentage(totalValue)
        }
        else {
            setAcceptedPercentage(Math.floor(Percentage))
        }
    }, [orderOverviewData.acceptance_accepted_orders, orderOverviewData.acceptance_pending_orders, orderOverviewData.acceptance_rejected_orders])

    //Completed

    useMemo(() => {
        let totalValue = orderOverviewData?.completed_on_time + orderOverviewData?.completed_one_to_seven_days + orderOverviewData?.completed_more_than_seven_days
        let Percentage = (orderOverviewData.completed_on_time / totalValue) * 100

        if (totalValue === 0) {
            setCompletedPercentage(totalValue)
        }
        else {
            setCompletedPercentage(Math.floor(Percentage))
        }
    }, [orderOverviewData.completed_on_time, orderOverviewData.completed_one_to_seven_days, orderOverviewData.completed_more_than_seven_days])


    //Parts
    useMemo(() => {
        let totalValue = orderOverviewData?.parts_in_stock + orderOverviewData?.parts_sourced + orderOverviewData?.parts_need_attn
        let Percentage = (orderOverviewData.parts_in_stock / totalValue) * 100
        if (totalValue === 0) {
            setPartsPercentage(totalValue)
        }
        else {
            setPartsPercentage(Math.floor(Percentage))
        }

    }, [orderOverviewData.parts_in_stock, orderOverviewData.parts_sourced, orderOverviewData.parts_need_attn])

    //In work overview
    const calculatePercentage = (value) => {
        let percentage = value / orderOverviewData.orders_in_work * 100
        if (!percentage) {
            return 0
        } else {
            return Math.ceil(percentage)
        }


    }
    const calculateActionPercentage = (value) => {

        let percentage = value / (orderOverviewData.open_actions + orderOverviewData.closed_actions) * 100
        if (!percentage) {
            return 0
        } else {
            return Math.ceil(percentage)
        }
    }
    const ITEM_HEIGHT = 78;

    return (
        <div className={classes.root}>

            <SearchBarWithFilters noSearch padding={'4px 16px'}
            >
                <FormControl style={{ marginLeft: '-5px' }}>
                    <Button variant="text" size="small" aria-haspopup="true" onClick={handleClick} endIcon={custButton ? <ArrowDropUp /> : <ArrowDown />} style={{ fontSize: "14px", backgroundColor: anchorEl ? '#efefef' : "" }}>
                        {selectedFilters.length > 0 ? `Customer(${selectedFilters.length})` : 'Customer(All)'}
                    </Button>
                    <Menu
                        id="Customer"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={(handleClose)}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        style={{ marginTop: '41px' }}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.2,
                                width: '39ch',
                                position: 'fixed',
                                top: '260px',

                            },
                        }}
                    >
                        {/* <MenuItem value="" style={{ marginLeft: '7px' }}>Customer(All)</MenuItem> */}
                        {customerDetails?.customers?.map((option, index) => {
                            return <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={9} style={{
                                        display: "flex", alignItems: "center", fontSize: "14px",

                                    }}>
                                        <label>{option.name}</label>
                                    </Grid>
                                    <Grid item xs={2} style={{ marginLeft: '-23px' }}>
                                        <FormControlLabel

                                            control={<PositiveSwitch
                                                checked={checkedCustState[index]}
                                                value={option.id}
                                                onChange={(e) => { filterCustomerChangeHandler(e) }}
                                                name={option.name} />}
                                        />
                                    </Grid>

                                </Grid>
                                <Grid><Divider variant="middle" /></Grid>
                            </Box>
                        })}
                    </Menu>
                </FormControl>


                <FormControl>
                    <Button variant="text" size="small" aria-haspopup="true" onClick={handleBaseClick} endIcon={baseButton ? <ArrowDropUp /> : <ArrowDown />} style={{ fontSize: "14px", backgroundColor: anchorBase ? '#efefef' : "" }}>
                        {selectedBase.length > 0 ? `Base(${selectedBase.length})` : 'Base(All)'}
                    </Button>
                    <Menu
                        id="Base"
                        anchorEl={anchorBase}
                        keepMounted
                        open={Boolean(anchorBase)}
                        onClose={() => {
                            setAnchorBase(null)
                            setBaseButton(!baseButton)
                        }}
                        // anchorOrigin={{
                        //     vertical: 'bottom',
                        //     horizontal: 'left',
                        // }}
                        style={{ marginTop: '40px' }}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '39ch',
                                position: 'absolute',
                                top: '233px',
                            },
                        }}
                    >

                        {/* &nbsp;&nbsp;&nbsp; */}
                        {/* <MenuItem value={""} >Base(All)</MenuItem> */}
                        {
                            bases?.map((base, index) => {
                                return <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={8} style={{
                                            display: "flex", alignItems: "center", fontSize: "14px",

                                        }}>
                                            <label>{base.station}</label>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControlLabel
                                                style={{ alignSelf: "flex-end" }}
                                                control={<PositiveSwitch
                                                    checked={checkedState[index]}
                                                    value={base.id}
                                                    // onChange={(e) => handleChange(e, option.id)}
                                                    onChange={(e) => { filterChangeHandler(e, index) }}
                                                    name={base.station} />}
                                            // name="base"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid><Divider variant="middle" /></Grid>
                                </Box>
                            }

                            )}
                    </Menu>
                </FormControl>

                <FormControl style={{ float: "right" }}>
                    <RangePicker
                        format="DD/MM/YYYY"
                        onChange={handleDateChange}
                        allowClear={true}
                        // defaultValue={[moment().subtract(1, 'months'), moment()]}
                        value={value}
                    />
                </FormControl>
            </SearchBarWithFilters>
            {/* Order overview section */}

            <div>
                <Typography variant="h6">Orders Overview (Date filtered)</Typography> <br />

                <Grid container xs={12}>

                    {/* Adhoc  */}
                    <Grid xs={3} style={{ paddingRight: "10px" }}>
                        <Paper style={{ width: '100%', height: '280px' }}>


                            <Grid container direction="column">
                                <Grid style={{ padding: '10px' }}>


                                    <Typography gutterBottom={20} variant="subtitle2" color="primary">NEW</Typography>

                                </Grid>
                                {loading ? <ChartLoader /> :
                                    <>
                                        <Grid >
                                            <Chart
                                                value1={orderOverviewData.adhoc_orders}
                                                value2={orderOverviewData.quoted_orders}
                                                color1={theme.palette.secondary.main}
                                                color2={alpha(theme.palette.primary.main, 0.75)}
                                                name="NEW"
                                                labels={["ADHOC", "Quoted"]}
                                                labelName={"ADHOC"}
                                                totalPercentage={adhocPercentage}
                                            />
                                            <div>
                                                <Typography className={classes.quotePercentageText} >{adhocPercentage}{"%"}</Typography>
                                                <Typography className={classes.centerLabel}>ADHOC</Typography>
                                            </div>
                                        </Grid>
                                        <Grid container direction="row" xs={12} >

                                            <Grid xs={6} container direction="row" >
                                                <Grid xs={3}></Grid>
                                                <Grid xs={3} style={{ textAlign: "right" }}>
                                                    <Typography gutterBottom={20} variant="h5" className={classes.textSecondary}
                                                    >{orderOverviewData.adhoc_orders}</Typography>
                                                </Grid>
                                                <Grid xs={6}>
                                                    <Typography gutterBottom={20} variant="body2"
                                                        className={classes.quoteText}>ADHOC</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid xs={6} container direction="row" >
                                                <Grid xs={3} style={{ textAlign: "right" }} >
                                                    <Typography gutterBottom={20} variant="h5"
                                                        style={{ color: theme.palette.secondary.main }}>{orderOverviewData.quoted_orders}</Typography>
                                                </Grid>
                                                <Grid xs={6} >
                                                    <Typography gutterBottom={20} variant="body2"
                                                        className={classes.quoteText}>Quoted</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>}
                            </Grid>

                        </Paper>
                    </Grid>
                    {/* Quotes */}
                    <Grid xs={3} style={{ paddingRight: "10px" }}>
                        <Paper style={{ width: '100%', height: '280px' }}>
                            <Grid container direction="column">
                                <Grid style={{ padding: '10px' }}>


                                    <Typography gutterBottom={20} variant="subtitle2" color="primary">QUOTES</Typography>

                                </Grid>
                                {loading ? <ChartLoader /> : <>
                                    <Grid >
                                        <Chart
                                            // values={quote}
                                            value1={orderOverviewData.quotes_accepted_orders}
                                            value2={orderOverviewData.quotes_pending_orders}
                                            value3={orderOverviewData.quotes_rejected_orders}
                                            labels={["Accepted", "Pending", "Rejected"]}
                                            labelName={"ACCEPTED"}
                                            totalPercentage={quotesPercentage}
                                        />
                                        <div>
                                            <Typography className={classes.percentageText} >{quotesPercentage}{"%"}</Typography>
                                            <Typography className={classes.centerLabel}>ACCEPTED</Typography>
                                        </div>
                                    </Grid>
                                    <Grid container direction="row" xs={12} >

                                        <Grid xs={4} container direction="row" >

                                            <Grid xs={6} style={{ textAlign: "right" }}>
                                                <Typography gutterBottom={20} variant="h5" style={{ color: "#4FC605" }}>{orderOverviewData.quotes_accepted_orders}</Typography>
                                            </Grid>
                                            <Grid xs={5}>
                                                <Typography gutterBottom={20} variant="body2" className={classes.quoteText}>Accepted</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={4} container direction="row" >

                                            <Grid xs={4} >
                                                <Typography gutterBottom={20} variant="h5" style={{ color: "#FFB300", textAlign: "right" }}>{orderOverviewData.quotes_pending_orders}</Typography>
                                            </Grid>
                                            <Grid xs={6} >
                                                <Typography gutterBottom={20} variant="body2"
                                                    className={classes.quoteText}>Pending</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={4} container direction="row" >

                                            <Grid xs={3} >
                                                <Typography gutterBottom={20} variant="h5"
                                                    style={{ color: "#FF0202", textAlign: "right" }}>{orderOverviewData.quotes_rejected_orders}</Typography>
                                            </Grid>
                                            <Grid xs={6} >
                                                <Typography
                                                    gutterBottom={20} variant="body2"
                                                    className={classes.quoteText}>Rejected</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>}
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* ACCEPTANCE */}
                    <Grid xs={3} style={{ paddingRight: "10px" }}>
                        <Paper style={{ width: '100%', height: '280px' }}>
                            <Grid container direction="column">
                                <Grid style={{ padding: '10px' }}>


                                    <Typography gutterBottom={20} variant="subtitle2" color="primary">ACCEPTANCE</Typography>

                                </Grid>
                                {loading ? <ChartLoader /> : <>
                                    <Grid >

                                        <Chart
                                            value1={orderOverviewData.acceptance_accepted_orders}
                                            value2={orderOverviewData.acceptance_pending_orders}
                                            value3={orderOverviewData.acceptance_rejected_orders}
                                            totalPercentage={acceptedPercentage}
                                            labels={["Accepted", "Pending", "Rejected"]}
                                            labelName={"ACCEPTED"}
                                        />
                                        <div>
                                            <Typography className={classes.percentageText} >{acceptedPercentage}{"%"}</Typography>
                                            <Typography className={classes.centerLabel}>ACCEPTED</Typography>
                                        </div>
                                    </Grid>
                                    <Grid container direction="row" xs={12} >

                                        <Grid xs={4} container direction="row" >

                                            <Grid xs={4} style={{ textAlign: "right" }}>
                                                <Typography gutterBottom={20} variant="h5"
                                                    style={{ color: "#4FC605" }}>{orderOverviewData.acceptance_accepted_orders}</Typography>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Typography gutterBottom={20} variant="body2"
                                                    className={classes.quoteText}>Accepted</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={4} container direction="row" >

                                            <Grid xs={5} >
                                                <Typography gutterBottom={20}
                                                    variant="h5"
                                                    style={{ color: "#FFB300", textAlign: "right" }}>{orderOverviewData.acceptance_pending_orders}</Typography>
                                            </Grid>
                                            <Grid xs={6} >
                                                <Typography gutterBottom={20} variant="body2"
                                                    className={classes.quoteText}>Pending</Typography></Grid>
                                        </Grid>
                                        <Grid xs={4} container direction="row" >

                                            <Grid xs={3} >
                                                <Typography gutterBottom={20} variant="h5" style={{ color: "#FF0202", textAlign: "right" }}>{orderOverviewData.acceptance_rejected_orders}</Typography>
                                            </Grid>
                                            <Grid xs={6} >
                                                <Typography gutterBottom={20} variant="body2" className={classes.quoteText}>Rejected</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>}
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* Completed */}
                    <Grid xs={3} >
                        <Paper style={{ width: '100%', height: '280px' }}>
                            <Grid container direction="column">
                                <Grid style={{ padding: '10px' }}>


                                    <Typography gutterBottom={20} variant="subtitle2" color="primary">COMPLETED</Typography>

                                </Grid>
                                {loading ? <ChartLoader /> : <>
                                    <Grid >

                                        <Chart
                                            value1={orderOverviewData.completed_on_time}
                                            value2={orderOverviewData.completed_one_to_seven_days}
                                            value3={orderOverviewData.completed_more_than_seven_days}
                                            labels={["On-Time", "0-7 Days Late", ">7 Days Late"]}
                                            labelName={"ON-TIME"}
                                            totalPercentage={completedPercentage}
                                        />
                                        <div>
                                            <Typography className={classes.percentageText} >{completedPercentage}{"%"}</Typography>
                                            <Typography className={classes.centerLabel}>ON-TIME</Typography>
                                        </div>
                                    </Grid>
                                    <Grid container direction="row" xs={12} >

                                        <Grid xs={4} container direction="row" >

                                            <Grid xs={4} style={{ textAlign: "right" }}>  <Typography gutterBottom={20} variant="h5" style={{ color: "#4FC605" }}>{orderOverviewData.completed_on_time}</Typography></Grid>
                                            <Grid xs={8}> <Typography gutterBottom={20} className={classes.quoteText}>On-Time</Typography></Grid>
                                        </Grid>
                                        <Grid xs={4} container direction="row" >

                                            <Grid xs={2} >
                                                <Typography gutterBottom={20} variant="h5" style={{ color: "#FFB300", textAlign: 'right' }}>{orderOverviewData.completed_one_to_seven_days}</Typography></Grid>
                                            <Grid xs={10} >
                                                <Typography gutterBottom={20} className={classes.quoteText}>0-7 Days Late</Typography></Grid>
                                        </Grid>
                                        <Grid xs={4} container direction="row" >

                                            <Grid xs={2} >  <Typography gutterBottom={20} variant="h5" style={{ color: "#FF0202", textAlign: 'right' }}>{orderOverviewData.completed_more_than_seven_days}</Typography></Grid>
                                            <Grid xs={10} > <Typography gutterBottom={20} className={classes.quoteText}> &gt;7 Days Late</Typography></Grid>
                                        </Grid>
                                    </Grid>
                                </>}
                            </Grid>
                        </Paper>
                    </Grid>

                </Grid >
                <br />

                {/* WorkOverview */}


                <Typography variant="h6">In-Work Progress Overview</Typography>
                <div className={classes.wrapper}>
                    {loading ? <ChartLoader padding={'24px'} /> : <>
                        <Grid container xs={12} style={{ marginTop: '-20px' }}>
                            <Grid item xs={7} container direction="column">
                                <Grid style={{ paddingBottom: '15px', paddingLeft: 5 }}><Typography variant="subtitle2" color="primary" >ORDERS</Typography></Grid>
                                <Grid direction="row" container style={{ borderRight: '3px solid #707070', paddingLeft: '10px' }}>
                                    <Grid xs={1} direction="column" container style={{ marginTop: "10px", paddingRight: '10px' }}>

                                        <Grid><Typography variant="h5" color="primary" style={{ textAlign: 'center' }}>
                                            {orderOverviewData.orders_in_work}</Typography></Grid>
                                        <Grid><Typography style={{ fontSize: "10px", textAlign: 'center' }} >IN-WORK</Typography></Grid></Grid>
                                    <Grid xs={11} container direction="row" >
                                        <Grid container direction="row" xs={2} >
                                            <Grid >
                                                <ProgressBar

                                                    size={70}
                                                    strokeWidth={8}
                                                    percentage={orderOverviewData.orders_induction ? calculatePercentage(orderOverviewData.orders_induction) : 0}
                                                    percentageText={orderOverviewData.orders_induction}
                                                    strokeLinecap={"round"}
                                                    percentageColor={theme.palette.secondary.main}
                                                    color={theme.palette.secondary.main}
                                                    name={"INDUCT"}
                                                />
                                            </Grid>
                                            <Grid xs={1}>  <StepConnector className={classes.stepper} /></Grid>
                                        </Grid>

                                        <Grid container direction="row" xs={2} >
                                            <Grid>
                                                <ProgressBar

                                                    size={70}
                                                    strokeWidth={8}
                                                    percentage={orderOverviewData.orders_planning ? calculatePercentage(orderOverviewData.orders_planning) : 0}
                                                    percentageText={orderOverviewData.orders_planning}
                                                    strokeLinecap={"round"}
                                                    percentageColor={theme.palette.secondary.main}
                                                    color={theme.palette.secondary.main}
                                                    name={"PLAN"}
                                                /></Grid>   <Grid xs={1}>  <StepConnector className={classes.stepper} /></Grid>
                                        </Grid>
                                        <Grid container direction="row" xs={2} >
                                            <Grid >
                                                <ProgressBar

                                                    size={70}
                                                    strokeWidth={8}
                                                    percentage={orderOverviewData.orders_inspection ? calculatePercentage(orderOverviewData.orders_inspection) : 0}
                                                    percentageText={orderOverviewData.orders_inspection}
                                                    strokeLinecap={"round"}
                                                    percentageColor={theme.palette.secondary.main}
                                                    color={theme.palette.secondary.main}
                                                    name={"INSPECT"}
                                                /></Grid>   <Grid xs={1}>  <StepConnector className={classes.stepper} /></Grid>
                                        </Grid>

                                        <Grid container direction="row" xs={2} >
                                            <Grid>
                                                <ProgressBar

                                                    size={70}
                                                    strokeWidth={8}
                                                    percentage={orderOverviewData.orders_commercial ? calculatePercentage(orderOverviewData.orders_commercial) : 0}
                                                    percentageText={orderOverviewData.orders_commercial}
                                                    strokeLinecap={"round"}
                                                    percentageColor={theme.palette.secondary.main}
                                                    color={theme.palette.secondary.main}
                                                    name={"COMM"}
                                                /></Grid>   <Grid xs={1}>  <StepConnector className={classes.stepper} /></Grid>
                                        </Grid>

                                        <Grid container direction="row" xs={2} >
                                            <Grid >
                                                <ProgressBar

                                                    size={70}
                                                    strokeWidth={8}
                                                    percentage={orderOverviewData.orders_maintenance ? calculatePercentage(orderOverviewData.orders_maintenance) : 0}
                                                    percentageText={orderOverviewData.orders_maintenance}
                                                    strokeLinecap={"round"}
                                                    percentageColor={theme.palette.secondary.main}
                                                    color={theme.palette.secondary.main}
                                                    name={"PROD"}
                                                /></Grid>  <Grid xs={1}>  <StepConnector className={classes.stepper} /></Grid>
                                        </Grid>

                                        <Grid container direction="row" xs={2} >
                                            <Grid >
                                                <ProgressBar

                                                    size={70}
                                                    strokeWidth={8}
                                                    percentage={orderOverviewData.orders_crs ? calculatePercentage(orderOverviewData.orders_crs) : 0}
                                                    percentageText={orderOverviewData.orders_crs}
                                                    strokeLinecap={"round"}
                                                    percentageColor={theme.palette.secondary.main}
                                                    color={theme.palette.secondary.main}
                                                    name={"CRS"}
                                                />
                                            </Grid>
                                        </Grid>



                                    </Grid>




                                </Grid>
                            </Grid>
                            <Grid item xs={2} container direction="column" >
                                <Grid style={{ paddingBottom: '15px' }}><Typography variant="subtitle2" color="primary" style={{ textAlign: 'center', paddingRight: '19px' }}>ACTIONS</Typography></Grid>
                                <Grid container direction="row" style={{ borderRight: '3px solid #707070', paddingLeft: '20px' }}>
                                    <Grid xs={4} direction="column" container >
                                        <Grid>
                                            <Typography variant="h4" color="primary" style={{ textAlign: 'center' }}>{orderOverviewData.open_actions}</Typography></Grid>
                                        <Grid><Typography style={{ fontSize: "10px", textAlign: 'center' }}>ON OPEN ORDERS</Typography></Grid></Grid>
                                    <Grid xs={6} style={{ paddingLeft: '13px' }}><Grid>
                                        <ProgressBar

                                            size={75}
                                            strokeWidth={8}
                                            percentage={orderOverviewData.closed_actions ? calculateActionPercentage(orderOverviewData.closed_actions) : 0}
                                            strokeLinecap={"round"}
                                            // percentageColor={theme.palette.secondary.main}
                                            color={" #4FC605 "}
                                            name={"COMPLETE"}
                                        />

                                    </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={3} container direction="column" >
                                <Grid style={{ paddingBottom: '15px' }}><Typography variant="subtitle2" color='primary' style={{ textAlign: 'center', paddingRight: '47px' }}
                                >PARTS</Typography></Grid>
                                <Grid container direction="row" style={{ paddingLeft: '22px' }}>
                                    <Grid xs={2} direction="column" container >
                                        <Grid>
                                            <Typography variant="h4" color='primary' style={{ textAlign: 'center' }}>{orderOverviewData.parts_open_requests}</Typography>
                                        </Grid>
                                        <Grid>
                                            <Typography style={{ fontSize: "10px", textAlign: 'center' }}>OPEN REQUESTS</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid xs={5} style={{ marginTop: "-12px" }}>
                                        <Chart
                                            value1={orderOverviewData?.parts_in_stock}
                                            value2={orderOverviewData?.parts_sourced}
                                            value3={orderOverviewData?.parts_need_attn}
                                            name={'Parts'}
                                            height={'140px'}
                                            labels={["In-Stock", "Sourced", "Unknown"]}
                                            labelName={"IN-STOCK"}
                                            totalPercentage={partsPercentage}
                                        />
                                        <div>
                                            <Typography className={classes.partsPercentage} >{partsPercentage}{"%"}</Typography>
                                            <Typography className={classes.partsLabel}>IN-STOCK</Typography>
                                        </div>
                                    </Grid>



                                    <Grid xs={5} container direction="column">
                                        <Grid container direction="row" style={{ marginTop: '-18px' }}>

                                            <Grid item xs={6}><Typography variant="h5" style={{ color: "#4FC605", textAlign: "center" }}>{orderOverviewData.parts_in_stock}</Typography></Grid>

                                            <Grid item xs={6}><Typography variant="body2" style={{ textAlign: "left", paddingTop: '7px' }}>In-Stock</Typography></Grid>


                                        </Grid>
                                        <Grid container direction="row">

                                            <Grid item xs={6}><Typography variant="h5" style={{ color: "#FFB300", textAlign: "center" }}>{orderOverviewData.parts_sourced}</Typography></Grid>

                                            <Grid item xs={6}><Typography variant="body2" style={{ textAlign: "left", paddingTop: '7px' }}>Sourced</Typography></Grid>


                                        </Grid>
                                        <Grid container direction="row">

                                            <Grid item xs={6}><Typography variant="h5" style={{ color: "#FF0202", textAlign: "center", }}>{orderOverviewData.parts_need_attn}</Typography></Grid>

                                            <Grid item xs={6}><Typography variant="body2" style={{ textAlign: "left", paddingTop: '7px' }}>Need Attn</Typography></Grid>


                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>}
                </div>


                {/* <CommericalTop /> */}
                <Grid container xs={12}>
                    <Grid item xs={8}>
                        <Typography variant="h6"> In-Work Commercial Overview</Typography></Grid>
                    {/* <Grid item xs={4} style={{ textAlignLast: ' end', paddingRight: '10px' }}>
                        <MROButton startIcon={<img
                            width="25px"
                            height="25px"
                            src={whiteSettingIcon}
                            alt="icon"
                        />}
                            variant="contained"
                            className={classes.runButton}
                            onClick={() => {
                                setAction('run_report')
                                setRunTable(!runTable)
                                setTimeout(() => {
                                    window.scrollTo(0, 1000)
                                }, 1000)
                            }}
                        >
                            Run Report
                        </MROButton>
                    </Grid> */}
                </Grid>
                <br />

                <Grid container xs={12} spacing={1} justify="space-between" >
                    <Grid xs={12} >
                        <CommericalTable table={"top"} name={"TOP"} bases={bases} customerDetails={customerDetails} permission={props.permission} filterData={filterData} startDate={startDate} endDate={endDate} selectedBase={selectedBase} selectedFilters={selectedFilters} runTable={runTable} />
                    </Grid>
                    {/* <Grid xs={6}>
                        {runTable && <CommericalTable table={"bottom"} name={"BOTTOM"} bases={bases} customerDetails={customerDetails} permission={props.permission} filterData={filterData} startDate={startDate} endDate={endDate} selectedBase={selectedBase} selectedFilters={selectedFilters} />}

                    </Grid> */}


                </Grid>

            </div>

        </div >
    )
}

export default Production