import { Toolbar } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { AppBar } from "@material-ui/core";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import { Alert } from "@material-ui/lab";
import { Box } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import TabIndex from "./Tabs/TabIndex";
import { WorkshopContext } from "views/Operations/Workshop/WorkshopContext/WorkshopContext";
import { ToastMessageContext } from "lib/contexts/message_context";
import { useContext } from "react";
const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  wrapper: {
    margin: "3%",
  },
  checksAppBar: {
    backgroundColor: "lightgray",
    color: theme.palette.secondary.main,
    boxShadow: "0px 3px 6px gray",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  alert: {
    display: "flex",
    justifyContent: "center",
  },
}));

function SelectedOrderDetails(props) {
  const classes = useStyles();
  const { handleClose, currentPlanningDetails, currentSelectedDetails, workshop_id, getWorkshopOrderDetails, getMaintenanceOrderList, planningTabDetails, planningId, planningWholeDetails, type, copyTab } = props;
  const { setCopyPlanningTab, setflag, setServiceStatus, setcopyTabStatus, setcopyPartTabStatus, setcopyServiceTabStatus, setCopyEquipmentTabStatus, setEquipmentStatus, setCopyCalendarStatus } = useContext(WorkshopContext)
  const message = useContext(ToastMessageContext)

  const GetInfoCard = (props) => {
    return (
      <Box display="flex">
        <Box flex={50} className={classes.partKey}>
          <Typography style={{ padding: "5px" }} color="primary">
            {props?.keyName}
          </Typography>
        </Box>
        <Box flex={50} className={classes.partValue}>
          {props?.value ?? "-"}
        </Box>
      </Box>
    );
  };
  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              onClick={() => {
                handleClose()
                getMaintenanceOrderList()
                setCopyPlanningTab(false)
                setcopyTabStatus([])
                setcopyPartTabStatus([])
                setcopyServiceTabStatus([])
                setServiceStatus(false)
                setflag(false)
                setCopyEquipmentTabStatus([])
                setEquipmentStatus(false)
                setCopyCalendarStatus(false)
              }}
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="subtitle2">Selected Order Details</Typography>
          </Toolbar>
        </AppBar>

        <Grid className={classes.wrapper}>
          <Grid container spacing={2} style={{ marginBottom: 10 }}>
            <Grid item xs={6} >
              <Typography color="primary">From</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="primary">To</Typography></Grid></Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} style={{ width: "100%" }}>


              <Grid item xs={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  bgcolor="#F5F5F5"
                  className={classes.partCard}
                >
                  <Box flexGrow={1}>
                    <div style={{ width: "100%" }}>
                      <GetInfoCard keyName={"Order #"} value={currentSelectedDetails.order_no || currentSelectedDetails?.order_number} />
                      <GetInfoCard keyName={"Description"} value={currentSelectedDetails.description || currentSelectedDetails?.item_description
                      } />
                      <GetInfoCard keyName={"Part #"} value={currentSelectedDetails.part_number} />
                    </div>
                  </Box>

                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  bgcolor="#F5F5F5"
                  className={classes.partCard}
                >
                  {/* To Field */}
                  <Box flexGrow={2}>
                    <div style={{ width: "100%" }}>
                      <GetInfoCard keyName={"Order #"} value={currentPlanningDetails?.order_no || currentPlanningDetails?.order_number} />
                      <GetInfoCard keyName={"Description"} value={currentPlanningDetails.description || currentPlanningDetails?.item_description} />
                      <GetInfoCard keyName={"Part #"} value={currentPlanningDetails.part_number} />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Typography color="primary" style={{ margin: "20px 0px 10px 0px" }}>
            Copy Data
          </Typography>
          <Box mt={3}>
            <Alert className={classes.alert} style={{ backgroundColor: "#FFB6C1" }} icon={false}>
              <span style={{ color: "red" }}>Caution!</span>  Take care when selecting copy! Data copied to the “To” Order cannot be reversed / undone here. If you copy an item by mistake it must be removed, or edited in the “To’ Order itself”
            </Alert>
          </Box>
        </Grid>
        <TabIndex
          currentPlanningDetails={currentPlanningDetails}
          currentClosedDetails={currentSelectedDetails}
          workshop_id={workshop_id}
          getWorkshopOrderDetails={getWorkshopOrderDetails}
          planningTabDetails={planningTabDetails}
          planningId={planningId}
          planningWholeDetails={planningWholeDetails}
          type={type}
          copyTab={copyTab}
        />
      </MROFullScreenRightDrawer>
    </div>
  );
}

export default SelectedOrderDetails;
