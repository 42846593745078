import {
    AppBar,
    IconButton,
    Toolbar,
    Typography,
    Grid,
    Box,
    FormControlLabel,
    Paper,
    MenuItem,

} from "@material-ui/core";

import React, { useContext, useEffect, useMemo, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CommercialServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/commercial";
import { ToastMessageContext } from "lib/contexts/message_context";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import MROTextField from "components/form_components/TextField"
import RescheduleIcon from "@material-ui/icons/History";
import LinearProgressBar from 'components/linear_progress_bar';
import NumericIncrement from "components/numeric_increment_job_clockings";
import CopyIcon from "assets/CopyIcon.svg"
import MROButton from "components/buttons";
import { Search } from "@material-ui/icons";
import PositiveSwitch from "components/form_components/switch";
import MessagePopup from "components/universal/messagePopup";
import CreateApprovaCaution from "./CreateApprovaCaution";
import { WorkshopContext } from "../WorkshopContext/WorkshopContext";
import { validateFormData } from "lib/utils/helperFunctions";
import EstimationHistory from "./EstimationHistory";
import DateField from 'views/Accounts/Workshop/Maintenance/ManageInvoice/DateField';
import moment from "moment";
import PartPricing from "./LookupPricing/PartPricing";



const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: "3%",
    },
    appBar: {
        backgroundColor: "#fff",
        color: "#000000CC",
        boxShadow: "0px 3px 6px #0000001A",
        fontSize: '14px'
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    disableField: {
        pointerEvents: "none",
        backgroundColor: "#F5F5F5",
    },
    title: {
        fontSize: '16px',
        color: theme.palette.primary.main
    },
    title1: {
        fontSize: '14px',

    },
    detail: {
        backgroundColor: "#fff",
        padding: "1%",
    },
    spacing: {
        paddingTop: '15px', paddingBottom: '10px'
    },

    consolidatedTxt: {
        display: "flex", margin: '-23px 0px 20px 1px'

    },
    text: {
        fontSize: "12px"

    },
    button: {
        background: theme.palette.primary.main,
        width: "36px",
        height: " 36px",
        borderRadius: "5px"
    }

}));



export default function Accumulative(props) {


    const classes = useStyles();
    const {
        handleClose,
        jobData,
        estData,
        approvedPositionnData,
        currentSelectedOrder,
        currentPosition,
        getApprovedPosition
    } = props;


    const [action, setAction] = useState(null);
    const [currentSelection, setCurrentSelection] = useState({});
    const [states, setStates] = useState({ man_hours: '' });
    const [errors, setErrors] = useState({});
    const [receipType, setReceiptType] = useState('')
    const { setApprovalStateCommercial, approvalStateCommercial } = useContext(WorkshopContext);
    const [input, setInput] = useState({
        tat_days: "",
        labour_value: "",
        parts_value: "",
        service_value: "",
        equipment_value: "",
        aog_fee: "",
    });
    const [sum, setSum] = useState(null);
    const [revisedtotal, setRevisedTotal] = useState(null)
    const [confirmationPopup, setConfirmationPopup] = useState(false)
    const [acceptAll, setAcceptAll] = useState(false)
    const [popupValues, setPopUpValues] = useState({})
    const [messagePopup, setMessagePopup] = useState(false)
    const [formvalues, setFormvalues] = useState({})
    const [busy, setBusy] = useState(null);
    const message = useContext(ToastMessageContext);
    const [tatDays, setTatDays] = useState("")
    const [etd, setETD] = useState(null)
    const [standardId, setStandardId] = useState(null)
    const [reportData, setReportData] = useState([

        {
            item: "Display Cost to Date",
            details: "Show “Costs to Date” section in the report?",
            status: false,
            name: "costs_to_date",
            id: 1
        }, {
            item: "Show Cost Breakdown",
            details: "Shows the Cost breakdown section with total costs per Action",
            status: false,
            name: "cost_breakdown",
            id: 2
        }, {
            item: "Detailed Costs",
            details: "Show additional breakdown of Labour, Parts, Service and Equipment Costs in ALL Cost sections of the Commercial Report?",
            status: false,
            name: "cost_breakdown_detail",
            id: 3
        }, {
            item: "Detailed CSV",
            details: "Send detailed “Cost Breakdown Report CSV” with the report?",
            status: false,
            name: "costs_csv",
            id: 4
        }, {
            item: "Workshop Report",
            details: "Send Workshop Report with to date Actions and Stage progress?",
            status: false,
            name: "workshop_report",
            id: 5
        }, {
            item: "Do NOT send Report",
            details: "Do not send anything to the customer in relation to this approval?",
            status: false,
            name: "do_not_send",
            id: 6

        },



    ])




    useMemo(
        () =>
            setSum(
                +input.labour_value +
                +input.parts_value +
                +input.service_value +
                +input.equipment_value +
                +input.aog_fee
            ),
        [input]
    );
    useMemo(
        () =>
            setRevisedTotal(
                +input.labour_value +
                +input.parts_value +
                +input.service_value +
                +input.equipment_value
            ),
        [input]
    );



    const handleCopyButtonClick = () => {

        setInput({
            tat_days: +currentPosition?.tat || "0",
            labour_value: +currentPosition?.labour || "0",
            parts_value: +currentPosition?.parts || "0",
            service_value: +currentPosition?.services || "0",
            equipment_value: +currentPosition?.equipment || "0",
            aog_fee: +currentPosition?.aog_fee || "0",
        });


    }

    const resetErrorsHandler = (name) => {
        setErrors((e) => ({ ...e, [name]: false }));
    };

    const handleInput = function (e) {
        const { value } = e.target;
        if (value.match(/\./g)) {
            const [, decimal] = value.split(".");

            // restrict value to only 2 decimal places
            if (decimal?.length > 2) {
                // do nothing
                return;
            }
        }
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const tableOptions1 = {
        ...options,
        paging: false,
        search: false,
        paging: false,
        rowStyle: { height: 42 },
    };
    const handleReport = (rowData) => {

        let reportStatus = reportData.map((item) => {
            if (item.id === rowData.id) {
                item.status = !rowData?.status
            }
            return item
        })
        setReportData(reportStatus)
    }


    const estColumns = [
        {
            title: "Type",
            field: "type",

            headerStyle: {
                textAlign: "left",
                paddingLeft: "22px",
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: "22px",
            },

        },
        {
            title: "Position",
            field: "position",

        },
        {
            title: "Date",
            field: "date",
        },
        {
            title: "History",
            field: "",
            width: "20%",

            headerStyle: {
                textAlign: "left",

            },
            cellStyle: {
                width: "20%",
            }, render: (rowData) => {
                if (rowData?.type === "Approved") {
                    return ""
                }
                else if (rowData?.type === "Revised") {
                    return (
                        <IconButton onClick={() => {
                            setAction("est_history")
                            setCurrentSelection(rowData)
                        }}>
                            <RescheduleIcon />
                        </IconButton>

                    )
                }

            }
        },
        {
            title: "Inducted / Start",
            field: "inducted_start",


        }, {
            title: "TAT",
            field: "tat_days",


        }, {
            title: "ETD",
            field: "etd",

        }, {
            title: "Progress",
            field: "progress",
            render: (rowData) =>
                <div style={{ position: 'absolute', marginTop: '-10px', marginLeft: '25px' }}>
                    <LinearProgressBar
                        variant='determinate'
                        value={Number(rowData.progress)}
                        width='70px'
                        barHeight='20px'
                        bgColor={(rowData.progress > 0 && rowData.progress < 75) ? '#4FC605' : (rowData.progress >= 75 && rowData.progress < 100) ? '#FFB300' : rowData.progress >= 100 ? '#FF0202' : '#fff'}
                        color={rowData.progress >= 100 ? "#FFFFFF" : "#4D4F5C"}
                    />
                </div>

        },

    ]

    const reportColumn = [
        {
            title: "Item",
            field: "item",
            headerStyle: {
                textAlign: "left",
                paddingLeft: '15px'
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: '15px'
            },


        },
        {
            title: "Detail",
            field: "details",
            width: "60%",

            headerStyle: {
                textAlign: "left",
                width: "60%",
            },
            cellStyle: {
                textAlign: "left",
                width: "60%",
            },
        },
        {
            title: "Action",
            field: "",
            render: (rowData) => <div>
                <FormControlLabel
                    control={
                        <PositiveSwitch
                            checked={rowData?.status}
                            onChange={(e) => {
                                handleReport(rowData)
                            }}
                        />
                    }
                    label=""
                    labelPlacement=""
                // name={"show_all_issued_items"}
                />

            </div>

        },

    ]

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        let c_date = formData.get("calculated_etd")

        let optionalFields = ["send_report_to_customer", "receipt_charge", "receipt_type"]
        let format = "DD/MM/YYYY"
        let today = moment()
        let valid = true;
        let error = {};

        for (const pair of formData.entries()) {
            const key = pair[0];
            const value = pair[1];

            // console.log("value is===================", optionalFields)

            if (value === "select" || value === "Search") {
                error[key] = true;
                valid = false;
            }

            if (value === "" && !optionalFields.includes(key)) {
                error[key] = true;
                valid = false;
            }
        }


        if (moment(c_date, format).isBefore(moment(today, format))) {
            valid = false;
            error = {
                ...error,
                calculated_etd: "ETD cannot be less than today!"

            };
        }

        if (!valid) {
            setBusy(null);
            setErrors(error);
            return;
        }
        setBusy("loading");
        if (approvedPositionnData?.etd !== null) {
            formData.delete("calculated_etd")
            formData.append("calculated_etd", moment(etd, "DD/MM/YYYY").format("YYYY-MM-DD"))
        }
        // formData.delete("tat_days")
        formData.delete("labour_value")
        formData.delete("parts_value")
        formData.delete("service_value")
        formData.delete("equipment_value")
        formData.delete("aog_fee")
        formData.delete("total")
        // formData.append("tat_days", input.tat_days)
        formData.append("labour_value", input.labour_value || "")
        formData.append("parts_value", input.parts_value || "")
        formData.append("service_value", input.service_value || "")
        formData.append("equipment_value", input.equipment_value || "")
        formData.append("aog_fee", input.aog_fee || "")
        formData.append("total", parseFloat(revisedtotal).toFixed(2) || "")
        formData.append("workshop_order_id", currentSelectedOrder?.id);
        // formData.delete("send_report_to_customer");
        // formData.append("send_report_to_customer", report);
        reportData.forEach((item) => formData.append(`${item.name}`, item.status))
        // formData.append("commercial_type", 0)
        formData.append("approval_type", 0)
        if (standardId !== null) {
            formData.append("workshop_standard_price_id", standardId)
        }


        setPopUpValues({
            tat_days: tatDays,
            labour_value: input.labour_value,
            parts_value: input.parts_value,
            service_value: input.service_value,
            equipment_value: input.equipment_value,
            aog_fee: input.aog_fee,
            total: sum
        })

        if (valid) {

            setConfirmationPopup(true)
            setFormvalues(formData)

        }



    };


    const handlePopUp = async (e) => {
        setMessagePopup(true)

        await CommercialServices.createNewApproval(formvalues)
            .then((res) => {
                if (res) {
                    handleClose(res.success);
                    setApprovalStateCommercial(res.success);
                    getApprovedPosition();
                    message.showToastMessage({
                        message: "created successfully!!",
                        variant: "success",
                    });
                }
            })
            .catch(() => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!!",
                    variant: "error",
                });
            })
            .finally(() => {
                setBusy(null);
                setMessagePopup(false)
            });

    }

    useEffect(() => {
        if (estData.length > 0 && estData !== undefined) {
            let apTAT = estData[0].tat_days ? estData[0].tat_days : 0
            let reTAT = estData[1].tat_days ? estData[1].tat_days : 0
            if (apTAT > reTAT) {
                setTatDays(apTAT)
            }
            else if (reTAT > apTAT) {
                setTatDays(reTAT)
            }
            else {
                setTatDays(approvedPositionnData?.tat)
            }
            // console.log("appppp======", apTAT, reTAT)

        }
        else {
            setTatDays(approvedPositionnData?.tat)
        }
    }, [estData, approvedPositionnData])


    useEffect(() => {
        if (approvedPositionnData !== null) {
            // setTatDays(approvedPositionnData?.tat)
            setStandardId(approvedPositionnData?.standard_id)
        }
    }, [approvedPositionnData])

    useEffect(() => {
        if (approvedPositionnData?.inducted_start !== null) {
            let tat = tatDays ? tatDays : 0
            let date = approvedPositionnData?.inducted_start ? moment(approvedPositionnData?.inducted_start, "YYYY-MM-DD HH:mm:ss").add(tat, "days").format("DD/MM/YYYY") : null
            setETD(date)
        }
    }, [approvedPositionnData, tatDays])



    return (
        <div>
            <form onSubmit={handleSubmit}>

                <div style={{ margin: "3%" }} >
                    <div className={classes.consolidatedTxt} >
                        <InfoOutlinedIcon />
                        &nbsp;&nbsp;
                        <Typography variant="subtitle1" className={classes.text} >
                            Use Manual method for jobs being calculated based on the Current Position
                        </Typography>
                    </div>
                    <Typography variant="subtitle2" className={classes.title1}>Estimated Departure</Typography>
                    <div className={classes.spacing}>
                        Current Approved and Revised ETDs
                    </div>
                    <Box className={classes.spacing}>
                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 4px #00000033",
                                //   marginTop: "10px",
                            }}
                            isLoading={''}
                            icons={tableIcons}
                            title={""}
                            columns={estColumns}
                            data={estData || []}
                            options={tableOptions1}
                            components={{
                                Toolbar: () => null,
                            }}
                            localization={{
                                toolbar: {},
                                body: {
                                    emptyDataSourceMessage: "No History found",
                                    filterRow: {
                                        filterTooltip: "Filter",
                                    },
                                },
                            }}
                        />
                    </Box>
                    <div className={classes.spacing}>
                        Update Approved ETD as required
                    </div>


                    <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        justify={"flex-start"}
                        xs={12}
                    >
                        <Grid item>
                            <MROTextField
                                name={"tat_days"}
                                label={"TAT (Days)*"}
                                defaultValue={approvedPositionnData?.tat}
                                value={tatDays}
                                type="text"
                                width="120px"
                                placeholder={"0.00"}
                                error={errors.tat_days}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                    setTatDays(e.target.value)
                                    handleInput(e);
                                    setErrors({ ...errors, calculated_etd: false })
                                }}
                                InputLabelProps={{ shrink: true }}
                            ></MROTextField>
                        </Grid>
                        {approvedPositionnData?.etd === null ? <Grid item>

                            <Grid className={classes.purchaseGrid} item xs={12}>
                                <DateField
                                    required
                                    name="calculated_etd"
                                    label={"Calculated ETD *"}
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.calculated_etd}
                                    helperText={errors.calculated_etd ? errors.calculated_etd : !errors.calculated_etd ? null : "*please enter a ETA"}
                                    onChange={(e) => { setErrors({ ...errors, calculated_etd: false }); }}

                                />
                            </Grid>

                        </Grid> :
                            <Grid item>
                                <MROTextField
                                    name={"calculated_etd"}
                                    label={"Calculated ETD *"}
                                    defaultValue={approvedPositionnData?.etd}
                                    value={etd}
                                    type="text"
                                    width="123px"
                                    placeholder={""}
                                    error={errors.calculated_etd}
                                    onChange={(e) => {
                                        resetErrorsHandler(e.target.name);
                                        // handleInput(e);
                                        setErrors({ ...errors, calculated_etd: false })
                                    }}
                                    helperText={errors.calculated_etd ? errors.calculated_etd : !errors.calculated_etd ? null : "*please enter a ETA"}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        className: classes.disableField,
                                    }}
                                ></MROTextField>
                            </Grid>}




                    </Grid>
                    <div className={classes.spacing}>
                        <Typography variant="subtitle2" className={classes.title1}>Shipping</Typography>

                        <Grid
                            container
                            spacing={2}
                            alignItems={"center"}
                            justify={"flex-start"}
                            xs={12}
                        >
                            <Grid item xs={3}>
                                <MROTextField
                                    name={"receipt_type"}
                                    label={"Receipt Type "}
                                    defaultValue={currentSelectedOrder?.receipt_type}
                                    select
                                    placeholder={"Receipt type"}
                                    InputProps={{
                                        className: classes.disableField,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                >
                                    <MenuItem value={"customer_delivery"}>Customer Delivery</MenuItem>
                                    <MenuItem value={"mro_collect"}>MRO Collect</MenuItem>
                                    <MenuItem value={"mro_arranged_third_party_agent"}>MRO Arranged 3rd Party Agent</MenuItem>
                                </MROTextField>
                            </Grid>
                            <Grid item xs={3}>
                                <MROTextField
                                    name={"receipt_charge"}
                                    label={"Receipt Charge"}
                                    defaultValue={currentSelectedOrder?.receipt_charge ? parseFloat(currentSelectedOrder?.receipt_charge).toFixed(2) : 0}
                                    type="text"
                                    placeholder={"Receipt Charge"}
                                    InputProps={{
                                        className: classes.disableField,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                ></MROTextField>
                            </Grid>

                            <Grid item xs={3}>
                                <MROTextField
                                    name={"return_shipping_type"}
                                    label={"Return Shipping Type *"}
                                    select
                                    placeholder={"Select"}
                                    error={errors.return_shipping_type}
                                    onChange={(e) => {
                                        resetErrorsHandler(e.target.name);
                                        setReceiptType(e.target.value)
                                    }}
                                    // defaultValue={"Select"}
                                    InputLabelProps={{ shrink: true }}
                                >

                                    <MenuItem value={"Select"} disabled className={classes.disableField}>Select</MenuItem>
                                    <MenuItem value={"exclude_return_shipping"}>Excludes Return Shipping</MenuItem>
                                    <MenuItem value={"customer_collect"}>Customer Collect</MenuItem>
                                    <MenuItem value={'mro_delivery'}>MRO Delivery</MenuItem>
                                    <MenuItem value={'mro_arranged_third_party_agent'}>MRO Arranged 3rd Party Agent</MenuItem>

                                </MROTextField>
                            </Grid>

                            {(receipType === "mro_delivery" || receipType === "mro_arranged_third_party_agent") && <Grid item xs={3}>
                                <NumericIncrement
                                    name="return_charge"
                                    label="Return Charge *"
                                    error={errors.return_charge}
                                    value={states.man_hours}
                                    placeholder={"Return charge "}
                                    // onChange={handleChange}
                                    noIncreament
                                    state={states}
                                    setState={setStates}
                                    inputProps={{ min: 0, step: 0.25 }}
                                    InputLabelProps={{ shrink: true }}
                                    setError={() => resetErrorsHandler("return_charge")}
                                    helperText={
                                        errors["return_charge"] &&
                                        `Please enter return charge`
                                    }
                                />

                            </Grid>}


                        </Grid>

                    </div>
                    {/* commercial section tab 0 */}
                    <div className={classes.spacing}>
                        <Typography variant="subtitle2" className={classes.title1} >Commercial Values</Typography>
                        <div style={{ paddingTop: '10px' }}>
                            Quick assist functions
                        </div>
                        <Grid container style={{ paddingTop: '10px' }} >
                            <Grid>
                                <IconButton className={classes.button} disableRipple onClick={() => {
                                    handleCopyButtonClick()
                                }}>
                                    <img src={CopyIcon} height={'18px'} />
                                </IconButton>
                            </Grid>
                            <Grid> <Typography style={{ padding: " 7px", fontSize: "14px" }}>Copy From CURRENT POSITION</Typography></Grid>

                        </Grid>
                        <Grid container style={{ paddingTop: '10px' }}  >
                            <Grid>
                                <IconButton className={classes.button} disableRipple onClick={() => {
                                    setAction("lookup")
                                }}>
                                    <Search htmlColor="#fff" fontSize="small" style={{ fontSize: '25px' }} />
                                </IconButton>
                            </Grid>
                            <Grid><Typography style={{ padding: " 7px", fontSize: "14px" }}>Lookup from Standard Pricing or Job History</Typography> </Grid>

                        </Grid>

                        <div style={{ paddingTop: '10px' }}>
                            Workshop Order TOTAL value in the Customer’s currency
                        </div>
                    </div>

                    <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        justify={"flex-start"}
                        xs={12}
                        className={classes.spacing}
                    >
                        <Grid item >
                            <MROTextField
                                label={"Order QTY"}
                                defaultValue={currentSelectedOrder?.quantity}
                                type="text"
                                width="120px"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    className: classes.disableField,
                                }}
                            ></MROTextField>
                        </Grid>
                        <Grid item >
                            <MROTextField
                                name={"labour_value"}
                                label={"Labour *"}
                                value={input.labour_value}
                                type="text"
                                width="120px"
                                placeholder={"0.00"}
                                // error={errors.tat_days}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                    handleInput(e);
                                }}
                                InputLabelProps={{ shrink: true }}
                            ></MROTextField>
                        </Grid>

                        <Grid item >
                            <MROTextField
                                name={"parts_value"}
                                label={"Parts *"}
                                value={input.parts_value}
                                type="text"
                                width="120px"
                                placeholder={"0.00"}
                                error={errors.parts_value}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                    handleInput(e);
                                }}
                                InputLabelProps={{ shrink: true }}
                            ></MROTextField>
                        </Grid>
                        <Grid item >
                            <MROTextField
                                name={"service_value"}
                                width="120px"
                                label={"Services *"}
                                value={input.service_value}
                                type="text"
                                placeholder={"0.00"}
                                error={errors.service_value}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                    handleInput(e);
                                }}
                                InputLabelProps={{ shrink: true }}
                            ></MROTextField>
                        </Grid>
                        <Grid item >
                            <MROTextField
                                name={"equipment_value"}
                                label={"Equipment *"}
                                width="120px"
                                value={input.equipment_value}
                                placeholder={"0.00"}
                                type="text"
                                error={errors.equipment_value}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                    handleInput(e);
                                }}
                                InputLabelProps={{ shrink: true }}
                            ></MROTextField>
                        </Grid>
                        <Grid item >
                            <MROTextField
                                name={"aog_fee"}
                                label={"AOG fee *"}
                                value={input.aog_fee}
                                placeholder={"0.00"}
                                type="text"
                                width="120px"
                                error={errors.aog_fee}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                    handleInput(e);
                                }}
                                InputLabelProps={{ shrink: true }}
                            ></MROTextField>
                        </Grid>

                        <Grid item >
                            <MROTextField
                                name={"total"}
                                label={"Total *"}
                                width="120px"
                                error={errors.total}
                                value={parseFloat(sum).toFixed(2)}
                                placeholder={"0.00"}
                                defaultValue={0.0}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    className: classes.disableField,
                                }}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                }}

                            ></MROTextField>
                        </Grid>
                        <Grid item >
                            <MROTextField
                                label={"Currency"}
                                defaultValue={currentPosition?.currency}
                                type="text"
                                width="120px"
                                placeholder={"USD"}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    className: classes.disableField,
                                }}
                            ></MROTextField>
                        </Grid>




                    </Grid>
                    <div className={classes.spacing}>
                        <div className={classes.consolidatedTxt}>
                            <InfoOutlinedIcon />
                            &nbsp;&nbsp;
                            <Typography variant="subtitle1" className={classes.text} >
                                The above total will show in the Commercial Summary section of the report, however, any sum amendments will NOT be reflected in the Cost Breakdown (and CSV) which come directly actual current values
                            </Typography>
                        </div>
                    </div>

                    {/* comment section tab 0 */}
                    <Typography variant="subtitle2" className={classes.title1}>Commercial Report Comments</Typography>

                    <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        justify={"flex-start"}
                        xs={12}
                        className={classes.spacing}
                    >

                        <Grid item xs={12}>
                            <MROTextField
                                name={"comments"}
                                label={"Comments *"}
                                type="text"
                                placeholder={"Add comments you wish to appear on your commercial report summary - Max 250 Characters"}
                                error={errors.comments}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                }}
                                multiline
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 250 }}
                                InputProps={{
                                    style: {
                                        height: 'max-content',
                                        width: "100%",

                                    },
                                }}
                            ></MROTextField>
                        </Grid>





                    </Grid>
                    {/* report section tab 0 */}
                    <div className={classes.spacing}>
                        <Typography variant="subtitle2" className={classes.title1}>Commercial Report Options</Typography>
                    </div>
                    <Box className={classes.spacing}>
                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 4px #00000033",
                                //   marginTop: "10px",
                            }}
                            isLoading={''}
                            icons={tableIcons}
                            title={""}
                            columns={reportColumn}
                            data={reportData || []}
                            options={tableOptions1}
                            components={{
                                Toolbar: () => null,
                            }}
                            localization={{
                                toolbar: {},
                                body: {
                                    emptyDataSourceMessage: "No History found",
                                    filterRow: {
                                        filterTooltip: "Filter",
                                    },
                                },
                            }}
                        />
                    </Box>
                </div>






                <div style={{ marginLeft: "0px" }} className={"action-buttons"}>
                    <MROButton
                        // loading={busy === "loading"}
                        variant={"contained"}
                        color={"primary"}
                        type="submit"
                    >
                        Complete
                    </MROButton>
                </div>

            </form>



            {confirmationPopup && <CreateApprovaCaution handleClose={() => {
                setConfirmationPopup(false)

            }}

                onClose={() => {
                    setConfirmationPopup(false)
                    setAcceptAll(false)
                }}
                open={confirmationPopup}
                popupValues={popupValues}
                setAcceptAll={setAcceptAll}
                acceptAll={acceptAll}

                handleSubmit={handlePopUp}
            />}
            {messagePopup &&
                <MessagePopup
                    handleClose={() => setMessagePopup(false)}
                    open={messagePopup}
                    topTitle={"The Commercial Reports for the job are being prepared"}
                    bottomTitle={"Please be patient, this will take a few moments"}
                />}
            {action === "est_history" && (
                <EstimationHistory
                    currentSelection={currentSelection}
                    handleClose={() => setAction(null)}

                />
            )}
            {action === "lookup" && (
                <PartPricing handleClose={() => setAction(null)} currentWorkshopDetails={{ quantity: currentSelectedOrder?.quantity, part_number: currentSelectedOrder?.part_number, description: currentSelectedOrder?.description, salesRate: approvedPositionnData?.sales_rate }}
                    setInput={setInput} input={input} name={"commercial"} setTatDays={setTatDays} setStandardId={setStandardId}
                />

            )}
        </div>
    )
}
