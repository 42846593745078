import React, { useEffect, useState } from 'react';
import MROTheme, { organisationDetails } from "./theme/main_theme";
import { BrowserRouter as Router } from 'react-router-dom';
import BrowserAppRoutes from "./views/Routes";
import { ThemeProvider } from '@material-ui/styles';
import ToastMessageProvider from "./lib/contexts/message_context";
import { CustomPreloader } from "react-preloaders";
import './App.css';
import WorkshopContextProvider from 'views/Operations/Workshop/WorkshopContext/WorkshopContext';
import PlannedWorkOrderProvider from 'views/Planning/Line maintenance/Planned WO/PlannedWorkOrderContext';



function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // alert("Auth" + queryString)
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userRole = user ? user.role : null;
    setUser(userRole)
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <Router onUpdate={() => document.getElementById('main_content').scrollTo(10, 10)}>
      {
        loading && <CustomPreloader background={organisationDetails && organisationDetails.primary_color_code ? organisationDetails.primary_color_code : "#145999"} color={"#fff"}>
          <img width={"200px"} src={"/loading_icon.gif"} alt={"loading..."} />
        </CustomPreloader>}

      <ThemeProvider theme={MROTheme}>
        <ToastMessageProvider>
          <WorkshopContextProvider>
            <PlannedWorkOrderProvider>
              <BrowserAppRoutes />
            </PlannedWorkOrderProvider>
          </WorkshopContextProvider>
        </ToastMessageProvider>
      </ThemeProvider>

    </Router>
  );
}

export default App;
