import React, { useState, useReducer, useEffect, useContext } from 'react'
import MaterialTable from 'material-table';
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import ViewIcon from "@material-ui/icons/Visibility";
import CopyFileIcon from "assets/CopyFiles.svg"
import GreenCopyIcon from "assets/GreenCopyIcon.svg"
import { ICON_COLOUR } from 'lib/constants/style_constants';
import { IconButton, Box } from '@material-ui/core';
import { ToastMessageContext } from 'lib/contexts/message_context';
import { WorkshopContext } from 'views/Operations/Workshop/WorkshopContext/WorkshopContext';
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation';



function Parts(props) {

    const { currentActionData, currentWorkshopDetails, currentClosedDetails, currentActionId, orderType } = props
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const { totalCount, inProgress, data } = tableState;
    const [issued, setIssued] = useState(true);
    const message = useContext(ToastMessageContext)
    const { copyParts, setCopyParts, copyInspection, copiedJobs } = useContext(WorkshopContext)
    const [pageSize, setPageSize] = useState(5);
    const [page, setPage] = useState(0);
    const [totalCountT, setTotalCountT] = useState(0)
    const [tData, setTData] = useState([])
    const [loading, setLoading] = useState(false)

    const getList = () => {
        const params = {
            reportable_type: "WorkshopOrder",
            reportable_id: currentActionData.workshop_order_id,
            page: 1 + page,
            count_per_page: pageSize,
            to_issued: issued,
            workshop_action_id: currentActionData.workshop_action_id
            // ...filters
        };

        TableAction.getList(dispatch, message, params, "workshopRequestPart");
    };

    useEffect(() => {
        if (orderType === "Maintenance" || orderType === "Production") {
            getList();
        }
    }, [page, pageSize, issued]);




    const getTemplateListing = () => {
        setLoading(true)
        const params = {
            workshop_order_id: currentActionData?.workshop_order_id,
            workshop_action_id: currentActionData.workshop_action_id,
            page: 1 + page,
            limit: pageSize,

        };

        MaintenanceServices.getTemlatePartsListing(params).then((res) => {
            setTData(res.data)
            setTotalCountT(res.total_count)
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        if (orderType === "Maintenance Template" || orderType === "Production Template") {
            getTemplateListing()
        }
    }, [page, pageSize])



    ///copy parts


    const handleSubmit = async (rowData) => {
        copyParts.push(rowData.id)
        const formData = new FormData()
        let userDetails = JSON.parse(sessionStorage.getItem("user"));
        if (orderType === "Maintenance" || orderType === "Production") {
            formData.append("workshop_action_id", currentActionId)
            formData.append("issued_comment", rowData.us_comment);
            formData.append("from_base_station_id", currentWorkshopDetails?.base_station_id);
            formData.append("to_base_station_id", currentWorkshopDetails?.base_station_id);
            formData.append("quantity", rowData.required_quantity)
            formData.append("user_id", userDetails?.id);
            formData.append("primary_part_id", rowData?.prime_part_id);
            formData.append("reportable_type", "WorkshopOrder");
            formData.append("reportable_id", currentWorkshopDetails?.id);
            formData.append("order_number", currentWorkshopDetails?.order_no);
            formData.append("line_customer_id", currentWorkshopDetails?.line_customer_id);
            formData.append("part_number", rowData.part_number)
        }
        else if (orderType === "Maintenance Template" || orderType === "Production Template") {
            formData.append("workshop_action_id", currentActionId)
            formData.append("issued_comment", "");
            formData.append("from_base_station_id", currentWorkshopDetails?.base_station_id);
            formData.append("to_base_station_id", currentWorkshopDetails?.base_station_id);
            formData.append("quantity", rowData.quantity)
            formData.append("user_id", userDetails?.id);
            formData.append("primary_part_id", rowData?.primary_part_id);
            formData.append("reportable_type", "WorkshopOrder");
            formData.append("reportable_id", currentWorkshopDetails?.id);
            formData.append("order_number", currentWorkshopDetails?.order_no);
            formData.append("line_customer_id", currentWorkshopDetails?.line_customer_id);
            formData.append("part_number", rowData.part_number)
        }

        await MaintenanceServices.createPartRequest(formData).then((res) => {
            if (res.success) {

                message.showToastMessage({
                    message: "Copied successfully ",
                    variant: "success",
                });
            }
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            });
        })
    }








    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        toolbar: false,
        rowStyle: { height: 42 },
    };
    const tableOptions1 = {
        ...options,
        page: page,
        total: totalCountT,
        pageSize: pageSize,
        search: false,
        toolbar: false,
        rowStyle: { height: 42 },
    };




    const columns = [

        {
            title: "Part #",
            field: "part_number",
            headerStyle: {
                textAlign: "left",
                paddingLeft: "20px"
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: "20px"
            },
        },
        {
            title: "Description",
            field: "description",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",
                width: "70%",
            },
        },
        {
            title: "Quantity",
            field: "required_quantity",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",
            },
        },
        {
            title: "Copy",
            headerStyle: {
                textAlign: "center",
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (rowData) => (
                <div>
                    {copiedJobs.find((item) => item === currentActionData.workshop_action_id) && <IconButton
                        onClick={() => {

                            handleSubmit(rowData);

                        }}
                        disabled={copyParts.find((item) => item === rowData.id)}
                    >
                        {copyParts.find((item) => item === rowData.id) ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: copyParts.find((item) => item === rowData.id) ? "none" : "", }} /> :

                            <img src={CopyFileIcon} height="25px" />
                        }
                    </IconButton>}
                </div>
            ),
        },
    ];



    const templateColumns = [
        {
            title: "Action #",
            field: "action_number",
            headerStyle: {
                textAlign: "left",
                paddingLeft: "20px"
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: "20px"
            },
        },
        {
            title: "Part #",
            field: "part_number",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",
            },
        },
        {
            title: "Description",
            field: "description",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",
                width: "70%",
            },
        },
        {
            title: "Quantity",
            field: "quantity",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",
            },
        },
        {
            title: "Copy",
            headerStyle: {
                textAlign: "center",
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (rowData) => (
                <div>
                    {copiedJobs.find((item) => item === currentActionData.workshop_action_id) && <IconButton
                        onClick={() => {

                            handleSubmit(rowData);

                        }}
                        disabled={copyParts.find((item) => item === rowData.id)}
                    >
                        {copyParts.find((item) => item === rowData.id) ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: copyParts.find((item) => item === rowData.id) ? "none" : "", }} /> :

                            <img src={CopyFileIcon} height="25px" />
                        }
                    </IconButton>}
                </div>
            ),
        },
    ];

    return (
        <div>
            <MaterialTable
                style={{
                    boxShadow: "0px 1px 3px #00000033",
                    marginTop: "10px",
                }}
                icons={tableIcons}
                title={""}
                columns={orderType === "Maintenance" || orderType === "Production" ? columns : orderType === "Maintenance Template" || orderType === "Production Template" ? templateColumns : columns}
                data={orderType === "Maintenance" || orderType === "Production" ? data.data : orderType === "Maintenance Template" || orderType === "Production Template" ? tData : []}
                isLoading={inProgress || loading}
                options={orderType === "Maintenance" || orderType === "Production" ? tableOptions : orderType === "Maintenance Template" || orderType === "Production Template" ? tableOptions1 : tableOptions}
                onChangePage={(page) => {
                    setPage(page);
                }}
                onChangeRowsPerPage={(pageSize) => {
                    setPageSize(pageSize);
                }}

                totalCount={orderType === "Maintenance" || orderType === "Production" ? totalCount : totalCountT}
                page={page}
            /></div>
    )
}

export default React.memo(Parts)