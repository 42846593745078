
import React, { useContext, useState, useEffect } from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { Dialog, DialogContent, DialogTitle, FormControlLabel, MenuItem } from "@material-ui/core";
import MROButton from 'components/buttons';
import { Typography } from "@material-ui/core";
import { ToastMessageContext } from "lib/contexts/message_context";
import DateField from 'components/form_components/DateField';
import MROTextField from "components/form_components/TextField";
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation';
import { WorkshopContext } from 'views/Operations/Workshop/WorkshopContext/WorkshopContext';
import { getOrganisationId } from 'lib/utils/common_utils';

const useStyles = makeStyles((theme) => ({
    formWrapper: {
        background: "#fff",
        width: "100%",
    },
    title: {
        display: "flex",
        justifyContent: "left",
        "& h2": {
            //   color: theme.palette.primary.main,
            fontSize: "40px",
            fontWeight: 40,
        },
    },
    subTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "20px",
        color: "#4D4F5C",
    },
    card: {
        padding: "0 25px",
    },
    actionButtons: {
        paddingBottom: "33px",
        textAlign: "end",
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },
    purchaseGrid: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        height: "85px",
    },
    purchaseStock: {
        marginBottom: "20px",
        maxWidth: "300px",
        marginTop: '10px'
    },
    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "22%",
        },
    }
}));


function AdditionalData(props) {
    const classes = useStyles();
    const { handleClose, currentClosedDetails, currentPlanningDetails, selectedRow, copyTab } = props
    const [actionDpdOptions, setActionDpdOptions] = useState([]);
    // console.log("data======", selectedRow)
    const message = useContext(ToastMessageContext)
    const { flag, setflag } = useContext(WorkshopContext)
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false)

    const getActions = async () => {
        try {
            const { data } = await MaintenanceServices.getActionsDpdOptions({
                id: currentPlanningDetails.id,
            });
            setActionDpdOptions(data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getActions();
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        let userDetails = JSON.parse(sessionStorage.getItem("user"));
        let valid = true;
        let errors = {
            workshop_action_id: false,

        };
        if (formData && formData.get("workshop_action_id")?.length === 0) {
            errors.workshop_action_id = true;
            valid = false;
        }
        if (!valid) {

            setError(errors);
            return;
        }
        if (copyTab === "normal") {
            setLoading(true)
            formData.append("issued_comment", "");
            formData.append("from_base_station_id", currentClosedDetails?.base_station_id);
            formData.append("to_base_station_id", currentPlanningDetails?.base_station_id);
            formData.append("quantity", selectedRow.quantity)
            formData.append("user_id", userDetails?.id);
            formData.append("primary_part_id", selectedRow?.primary_part_id);
            formData.append("reportable_type", "WorkshopOrder");
            formData.append("reportable_id", currentPlanningDetails?.id);
            formData.append("order_number", currentPlanningDetails?.order_no);
            formData.append("line_customer_id", currentPlanningDetails?.line_customer_id);
            formData.append("part_number", selectedRow.part_number)
            await MaintenanceServices.createPartRequest(formData).then((res) => {
                if (res.success) {
                    setflag(true)
                    handleClose()
                    message.showToastMessage({
                        message: "Copied successfully ",
                        variant: "success",
                    });
                }
            }).catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong",
                    variant: "error",
                });
            }).finally(() => {
                setLoading(false)
            })
        }
        else if (copyTab === "template") {
            setLoading(true)

            formData.append("quantity", selectedRow.quantity)
            formData.append("primary_part_id", selectedRow?.primary_part_id);
            formData.append("workshop_order_id", currentPlanningDetails?.id);
            formData.append("organisation_id", getOrganisationId());
            await MaintenanceServices.addPlanningTemplateParts(formData).then((res) => {
                if (res.success) {
                    setflag(true)
                    handleClose()
                    message.showToastMessage({
                        message: "Copied successfully ",
                        variant: "success",
                    });
                }
            }).catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong",
                    variant: "error",
                });
            }).finally(() => {
                setLoading(false)
            })
        }
    }


    return (
        <div>
            <Dialog
                maxWidth={"md"}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="base-station-manage"
            >
                <DialogTitle>
                    <Typography
                        color="primary"
                        className={classes.title}
                    >
                        Additional Data Required
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.card}>
                    <div className={classes.formWrapper}>
                        <form noValidate onSubmit={handleSubmit}>
                            <Grid
                                className={classes.purchaseStock}
                                container
                                spacing={1}
                                // alignItems={"center"}
                                justify={"flex-start"}
                            >

                                <Grid className={classes.purchaseGrid} item xs={12}>
                                    <Grid item xs={12}>
                                        <MROTextField
                                            required
                                            name={"workshop_action_id"}
                                            label={"Select Action"}
                                            select
                                            error={error.workshop_action_id}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={
                                                error.workshop_action_id ? "*please select action" : null
                                            }
                                            onChange={(e) => {
                                                setError({ ...error, workshop_action_id: false });
                                            }}

                                        >
                                            <MenuItem value={"select"}> <span style={{ opacity: "40%" }}>Select</span></MenuItem>
                                            {actionDpdOptions && actionDpdOptions?.map((list) => (
                                                <MenuItem key={list.workshop_action_id
                                                } value={list.workshop_action_id
                                                }>{list.action_number}</MenuItem>
                                            ))}
                                        </MROTextField>
                                    </Grid>


                                </Grid>
                            </Grid>
                            <div className={classes.actionButtons}>
                                <MROButton
                                    //   disabled={loading}
                                    type="button"
                                    variant={"contained"}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </MROButton>
                                <MROButton
                                    //   disabled={loading}
                                    type="submit"
                                    variant={"contained"}
                                    color="primary"
                                    loading={loading}
                                >
                                    Complete
                                </MROButton>
                            </div>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default AdditionalData