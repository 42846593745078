import MaterialTable from "material-table";
import React, { useReducer, useEffect, useContext } from "react";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Box, IconButton } from "@material-ui/core";
import ViewIcon from "@material-ui/icons/Visibility";
import { ICON_COLOUR } from "lib/constants/style_constants";
import alertIcon from 'assets/icons/Alert_icon.png'
import { useState } from "react";
import AdditionalFieldPopUp from "./AdditionalFieldPopUp";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { ToastMessageContext } from "lib/contexts/message_context";
import CopyFileIcon from "assets/CopyFiles.svg"
import GreenCopyIcon from "assets/GreenCopyIcon.svg"
import { WorkshopContext } from "views/Operations/Workshop/WorkshopContext/WorkshopContext";
import Stages from "./Stages";
import TechFilesPopup from "./TechFilesPopup";

const useStyles = makeStyles(() => ({}));

function InspectionActions(props) {
  // const classes = useStyles();
  const { currentClosedDetails, currentPlanningDetails } = props
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const message = useContext(ToastMessageContext)
  const { copyTabStatus, copyCalendarStatus } = useContext(WorkshopContext)
  const { page, totalCount, pageSize } = tableState;
  const [action, setAction] = useState(false)
  const [actionDetails, setActionDetails] = useState(false)
  const [actionData, setActionData] = useState([])
  const [files, setFiles] = useState([])
  const [currentSelection, setCurrentSelection] = useState({})
  const [currentSelectedRow, setCurrentSelectedRow] = useState({})
  const [isTechFiles, setIsTechFiles] = useState(false)

  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    toolbar: false
  };
  const getActionList = async () => {
    setActionDetails(true)
    const params = {
      workshop_order_id: currentClosedDetails.id,
      page: page + 1,
      limit: pageSize,
    }
    await MaintenanceServices.copyPreviousGetActionList(params).then((response) => {
      setActionData(response.data)
      TableAction.setTotalCount(dispatch, response.total_count);
    }).catch((err) => {
      message.showToastMessage({
        message: "Something went wrong. Try again",
        variant: "error",
      })
    }).finally(() => {
      setActionDetails(false)
    })
  }

  useEffect(() => {
    getActionList()
  }, [currentClosedDetails.id, page, pageSize, totalCount])


  const handleSubmit = async (rowData) => {
    const formData = new FormData()

    formData.append("workshop_order_id", currentPlanningDetails?.id)
    formData.append("workshop_action_id", rowData.workshop_action_id)
    formData.append("action_type", rowData.action_type)
    formData.append("template_copy", true)
    let userDetails = JSON.parse(sessionStorage.getItem("user"));
    formData.append("raised_by", userDetails?.id);
    setFiles(rowData.maintenance_file)
    files.forEach((file) => formData.set("maintenance_file[]", file))

    await MaintenanceServices.copyPreviousCreateAction(formData).then((response) => {
      if (response.success) {
        copyTabStatus.push(rowData.workshop_action_id)
        message.showToastMessage({
          message: "Copied successfully ",
          variant: "success",
        });
      }

    }).catch((error) => {
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
    })
  }



  const columns = [
    {
      title: "Action #",
      field: "action_number",
      headerStyle: {
        textAlign: "left",
        paddingLeft: "20px"
      },
      cellStyle: {
        textAlign: "left",
        paddingLeft: "20px"
      },
    },
    {
      title: "Title",
      field: "title",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "50%",
      },
    },
    {
      title: "Est Man-Hrs",
      field: "estimate_man_hours",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
    },

    {
      title: "Stages",
      field: "stage_count",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <div>
          <a style={{ textDecoration: "underline" }} onClick={() => {
            setAction("view_stages")
            setCurrentSelection(rowData)
          }}>{rowData.stage_count}</a>
        </div>
      )
    },
    {
      title: "Cal Freq",
      field: "calendar_frequency",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => rowData.calendar_frequency ? rowData.calendar_frequency : "-"
    },
    {
      title: "Tech Data",
      // field: "maintenance_file",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <div>
          <IconButton>
            <ViewIcon fontSize="small" style={{ color: ICON_COLOUR }} onClick={() => {
              setIsTechFiles(true)
              setAction("view_files")
              setCurrentSelectedRow(rowData)
            }}
            />
          </IconButton>

        </div>

      )


    },
    {
      title: "Action",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        rowData.action_type === 4 ? <div>
          <IconButton
            onClick={() => {
              setAction("View")
              setCurrentSelectedRow(rowData)
              copyTabStatus.push(rowData.workshop_action_id)
            }}
            disabled={copyCalendarStatus && copyTabStatus.find((item) => item === rowData.workshop_action_id)}
          >

            {copyCalendarStatus && copyTabStatus.find((item) => item === rowData.workshop_action_id) ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: copyCalendarStatus && copyTabStatus.find((item) => item === rowData.workshop_action_id) ? "none" : "", }} /> :

              <img src={CopyFileIcon} height="25px" />
            }

          </IconButton>
        </div> :
          <div>
            <IconButton
              onClick={() => {
                handleSubmit(rowData)
              }}
              disabled={copyTabStatus.find((item) => item === rowData.workshop_action_id)}
            >
              {copyTabStatus.find((item) => item === rowData.workshop_action_id) ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: copyTabStatus.filter((item) => item === rowData.workshop_action_id) ? "none" : "", }} /> :

                <img src={CopyFileIcon} height="25px" />
              }

            </IconButton>
          </div >
      ),
    },
  ];




  return (
    <div>
      <MaterialTable
        style={{
          boxShadow: "0px 1px 3px #00000033",
          marginTop: "10px",
        }}
        icons={tableIcons}
        title={""}
        columns={columns}
        data={actionData || []}
        isLoading={actionDetails}
        options={tableOptions}
        onChangePage={(page) => {
          TableAction.setPage(dispatch, page);
        }}
        onChangeRowsPerPage={(pageSize) => {
          TableAction.setPageSize(dispatch, pageSize);
        }}
        totalCount={totalCount}
        page={page}
      />
      <div style={{ margin: ' 14px 0px 20px 3px' }}>
        <img src={alertIcon} height="15px" /> <span style={{ padding: '6px' }}> Tech Data will be copied. Check for correct revision and update as necessary in the 'To' Order after copying</span>
      </div>
      {action && action === "View" &&
        <AdditionalFieldPopUp
          handleClose={() => setAction(null)}
          actionData={actionData}
          currentSelectedRow={currentSelectedRow}
          currentPlanningDetails={currentPlanningDetails} />}

      {
        action === 'view_stages' && (
          <Stages
            handleClose={() => setAction(null)}
            currentSelection={currentSelection}

          />

        )

      }
      {
        action === 'view_files' && (
          <TechFilesPopup
            handleClose={() => setAction(null)}
            currentSelectedRow={currentSelectedRow}
            open={isTechFiles}
          />
        )
      }

    </div>

  );
}

export default InspectionActions;
