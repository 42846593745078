
import requestApi from "../common/boxp_api_client";

const getPerformance = (params) => {
    return requestApi({
        url: "/performance",
        method: "GET",
        params,
    })
}

const getPerformanceListing = (params) => {
    return requestApi({
        url: "/performance_listing",
        method: "GET",
        params,
    })
}

const getMaintHoursDetails = (params) => {
    return requestApi({
        url: "/maint_hours",
        method: "GET",
        params,
    })


}

const getActivityDetails = (params) => {
    return requestApi({
        url: "/line_activity",
        method: "GET",
        params,
    })


}

const getDownloadCSVMaintHrs = (params) => {
    return requestApi({
        url: "/download_csv_file",
        method: "GET",
        params,
    })

}
const getPopUpDetails = (params) => {
    return requestApi({
        url: "/dashboard_popup",
        method: "GET",
        params,
    })

}
const updateVerifyState = (data) => {
    return requestApi({
        url: `/update_verify_status?_method=PUT`,
        method: "POST",
        data,
    });
};
const getStationBoardEventList = (params) => {
    return requestApi({
        url: "/lm_events_list",
        method: "GET",
        params,
    })

}
const updateNotCallOutStatus = (data) => {
    return requestApi({
        url: `/update_not_called_out_status?_method=PUT`,
        method: "POST",
        data,
    });
};
const updateCancelEvent = (data) => {
    return requestApi({
        url: `/cancel_event?_method=PUT`,
        method: "POST",
        data,
    });
};
const updateAllocatedEngineers = (data) => {
    return requestApi({
        url: `/update_allocated_engineers`,
        method: "POST",
        data,
    });
};
const CreateLineEvents = (formData) => {
    return requestApi({
        url: '/create_event_station_board',
        method: 'POST',
        data: formData
    })
}
export const LineMaintainenceServices = {

    getPerformance,
    getPerformanceListing,
    getMaintHoursDetails,
    getDownloadCSVMaintHrs,
    getActivityDetails,
    getPopUpDetails,
    updateVerifyState,
    getStationBoardEventList,
    updateNotCallOutStatus,
    CreateLineEvents,
    updateCancelEvent,
    updateAllocatedEngineers
}