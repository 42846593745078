import { alpha, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const ProgressBar = ({ size, strokeWidth, percentage, color, percentageColor, strokeLinecap, name, percentageText }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(percentage);
  }, [percentage]);
  const theme = useTheme()
  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;

  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <circle
        fill="none"
        stroke={name === "COMPLETE" ? "#FFB300" : alpha(theme.palette.primary.main, 0.75)}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      {percentage !== 0 &&
        <circle
          fill="none"
          stroke={color}
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
          strokeDasharray={[dash, circumference - dash]}
          strokeLinecap={`${strokeLinecap}`}
          style={{ transition: "all 0.5s" }}
        />}

      <text
        fill={name === "COMPLETE" ? "#4FC605" : percentageColor}
        fontSize="16px"
        x="50%"
        y="50%"
        // dy="22px"
        textAnchor="middle"
      >
        {name === "COMPLETE" ? `${percentage}%` : `${percentageText}`}
      </text>
      <text
        fill={percentageColor}
        fontSize="8px"
        x="50%"
        y="50%"
        dy="20px"
        textAnchor="middle"
      >
        {name}
      </text>
    </svg>
  );
};

export default ProgressBar;
